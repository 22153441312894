import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

//import { useTranslation } from "react-i18next";
//import { useTranslation } from "react-i18next";

//--material-ui
import Grid from '@mui/material/Grid2';
import Card from '../../../../general/@components/general/MainCard'
import Avatar from "@mui/material/Avatar";
// import Typography from '@mui/material/Typography';

//--styles
import abrhilIcon from "../../../../../assets/images/abrhil_logo.webp"
import timbraIcon from "../../../../../assets/images/timbra_logo.webp"
import "./styles.scss"

//--utilities
import { formatWorkerName, getSaludate   } from './Utils/utilities';
import { hasValue } from '../../../../general/@components/general/GeneralUtilities';
import { size, capitalize } from 'lodash';
import { currentUserInfo } from '../../../../general/@components/requests/user-store';

//--components
import NavMessage from './NavMessage';
import SocialNetwork from '../../../../general/public/Login/components/Social';
import { scaleOpacityAnimation } from '../../../../general/@components/form/Permission/Utils';
import { motion, AnimatePresence } from "framer-motion";
// import ScrollView from '../../../general/ScrollView';


function Index() {
  // const { t } = useTranslation();
  const [name, setName] = useState("");
  const [elements, setElements] = useState([]);
  const userInfo = currentUserInfo();
  const photo = userInfo?.user_info?.worker?.photo?.medium_square_crop;

  const { active_windows } = useSelector(state => state.navigate);
  const { stamp } = useSelector(state => state.app);
  const versionNumber = process.env?.REACT_APP_VERSION_TAG ?? process.env?.REACT_APP_ABR_MODE ?? "0.0.0";

  useEffect(() => {
    if (size(userInfo)) {
      const value = getName();
      setName(value)
    }
  }, [userInfo]);

  useEffect(() => {
    setElements(getSaludate());
    setName("")
  }, []);

  const getName = () => {
    let name = "";
    if (userInfo?.user_info?.worker?.name) {
      const worker = userInfo?.user_info?.worker
      return formatWorkerName(worker);
    } else {
      name = userInfo?.user_info?.external_name?.split(' ')
      return name ?? userInfo?.user_info?.username.split(' ')
    }
  }

  if (size(active_windows)) {
    return null;
  }

  const version = stamp ? 'CFDI 4.0 Nómina 1.2' : `Versión: ${versionNumber}`;
  const classStamp = stamp ? 'right-column-stamp' : 'right-column';
  const colorBorder = stamp ? '#FD0002' : '#0B92E0';
  return (
    <Card className="welcome-card">

      {/*<div className="blur-Bg"></div>*/}

      <Grid container sx={{ height: "100%" }}>


        <div className="welcome-container">


          <div className="welcome-columns left-column">
            <div className="avatar">
              <Avatar sx={{ width: 174, height: 174, border: "3px solid " + colorBorder }} src={photo} />
            </div>

            <div className="welcome-user-container noselect">
              <div className="saludate" id="saludate">
                <p className="slide-up">
                  {elements}
                </p>
              </div>
              <div className="welcome-user-name">
                {hasValue(name) &&
                  <h1>
                  <div className="animation">
                    <span className="first-welcome">{capitalize(name[0])}</span>
                    <span className="oh-welcome">
                      <span className="second-welcome">{capitalize(name[1])}</span>
                    </span>
                  </div>
                </h1>}
              </div>
            </div>
          </div>


           <div className={`welcome-columns ${classStamp}`}>
           {/*<ScrollView
                arrows={false}
                endless={true}
                style={{width: 512, height: 318}}>
              </ScrollView> */}
              <AnimatePresence mode="wait">
                <motion.img
                  key={stamp ? 'welcome-logo-stamp' : 'welcome-logo'}
                  src={ stamp ? timbraIcon : abrhilIcon}
                  alt={"welcome logo"}
                  initial={{
                    ...scaleOpacityAnimation.initial,
                    rotate: 90,
                  }}
                  animate={{
                    ...scaleOpacityAnimation.animate,
                    rotate: 0,
                  }}
                  exit={{
                    ...scaleOpacityAnimation.exit,
                    rotate: 90,
                  }}
                  transition={{
                    ...scaleOpacityAnimation.transition,
                    duration: 0.5,
                    delay: 0.3,
                  }}
                />
              </AnimatePresence>

          </div>


        </div>

        <div className="welcome-footer-wrap">
          <div className="welcome-nav-message">
            <NavMessage width={"32px"} height={"32px"} />
          </div>
          <div className="welcome-social-network">
            {stamp ? null : <SocialNetwork showTitle={false} width={"50px"} height={"50px"} />}

            <span>{version}</span>
          </div>

        </div>
      </Grid>

    </Card>

  )
}

export default Index