/* libraries */
import PropTypes from "prop-types";
import React from 'react';
/* material ui */
import Tooltip from '@mui/material/Tooltip';
/* kendo ui */
import { valueOrOption } from '../general/GeneralUtilities';
import Zoom from '@mui/material/Zoom';

/**
	tooltip custumizado para la aplicación
	@object @param props : Es un tooltip basado en el de Material ui asi que recibe todas las propiedades descritas en la documentación
**/
const CustomTooltip = ({
	className = "",
	placement = "top",
	children,
	...props
}) => {
	const { disabled, type: propsType } = valueOrOption(children?.props, {});
	const type = valueOrOption(propsType, children?.type);

	if (!children) {
		return null;
	}

	return (
		<Tooltip
			arrow disableInteractive
			slots={{ transition: Zoom }}
			slotProps={{
				popper: { position: 'absolute' }
			}}
			{...props}
			classes={{ popper: className }}
			placement={valueOrOption(placement, "top")}
		>
			{
				disabled && !["span", "td"].includes(type)
					? <span className='abrhil-disabled-span-tooltip' >{children}</span>
					: children
			}
		</Tooltip>
	);
};

CustomTooltip.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	placement: PropTypes.string
};

export default CustomTooltip;