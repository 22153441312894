// @flow
import React from "react";
import { useTranslation } from "react-i18next";
import FormDialog from "../modal/dialog/FormDialog";
import BasicForm from "./Container";
// import type { dialogFormProps } from "./ComponentsPropTypes";
/**
 * Controlador de modal con formulario para el sistema
 * @param {dialogFormProps} params
 * @returns {DialogFormComponent}
 * @example
 * const [open,setOpen] = useState(false)
 * <DialogForm
 * 	title={"rol"}
 * 	open={open}
 * 	isEdit={false}
 * 	onClose={() => setOpen(false)}
 * 	handleSubmit={handleSubmit}
 * 	onSubmit={onSubmit}
 * 	defaultValues={defaultData}
 * 	warningFields={warningFields}
 * 	deppWarnings={deppWarnings}
 * 	fieldsValues={fieldsValues}
 * 	fieldsLabels={fieldsLabels}
 * 	onSubmitError={onSubmitError}
 * 	{...others}
 * >
 * 	 {children}
 * </DialogForm>
*/
const DialogForm = ({
	title,
	simpleTitle = null,
	open = false,
	isEdit = false,
	onClose,
	maxWidth = "lg",
	includeBaseCard = false,
	children = any,
	...others
}/* : dialogFormProps */) => {

	const { t } = useTranslation();

	return (
		<FormDialog
			{...others}
			title={simpleTitle ?? `${t(isEdit ? "edit" : "add")} ${t(title)}`}
			open={open}
			handleClose={onClose}
			maxWidth={maxWidth}
		>
			<BasicForm onCancel={onClose} includeBaseCard={includeBaseCard} {...others}>
				{children}
			</BasicForm>
		</FormDialog>
	);
};

export default DialogForm;