import React, {
	useEffect,
	useState,
} from 'react';

import { Transition } from '../../core/general/@components/modal/dialog/DialogSkeleton/Transition';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { Card } from "@progress/kendo-react-layout";

import monitor from "../../assets/images/monitor.png";
import cloud from "../../assets/images/cloud.png";

const NoConnection = () => {

	const { t } = useTranslation();
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		// Update network status
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		// Listen to the online status
		window.addEventListener('online', handleStatusChange);

		// Listen to the offline status
		window.addEventListener('offline', handleStatusChange);

		// Specify how to clean up after this effect for performance improvment
		return () => {
			window.removeEventListener('online', handleStatusChange);
			window.removeEventListener('offline', handleStatusChange);
		};
	}, [isOnline]);

	return (
		<Dialog
			open={!isOnline}
			slots={{ transition: Transition }}
			className="no-internet"
			maxWidth={"lg"}
		>
			<Card className="card">
				<Grid container spacing={2} direction="column">
					<Grid >
						<div className={"text"}>
							<p className={"title"}>
								{t("page-without-conection.title")}
							</p>
							<p>
								{t("page-without-conection.text1")}
							</p>
							<p>
								{t("page-without-conection.text2")}
							</p>
						</div>
					</Grid>

					<Grid>
						<Grid
							container
							direction="row"
							sx={{ justifyContent: 'center', alignItems: 'center' }}
							spacing={2}
							columns={{ xs: 14 }}
						>
							<Grid size={5} >
								<img src={monitor} alt="monitor" />
							</Grid>
							<Grid size={4} >
								<div className="load-icon">
									<span></span>
									<span></span>
									<span></span>
								</div>
							</Grid>
							<Grid size={5} >
								<img src={cloud} alt="server" />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</Dialog>
	);
	// 	<Backdrop
	// 		sx={{
	// 			color: '#fff',
	// 			zIndex: (theme) => theme.zIndex.drawer + 1
	// 		}}
	// 		open={!isOnline}
	// 		className={"no-internet"}
	// 	>

	// 		<Zoom in={true} style={{ transitionDelay: "100ms" }} timeout={300}>
	// 			<div>
	// 				<Card className="card">
	// 					<Grid container spacing={2} direction="column">
	// 						<Grid item >
	// 							<div className={"text"}>
	// 								<p className={"title"}>
	// 									{t("page-without-conection.title")}
	// 								</p>
	// 								<p>
	// 									{t("page-without-conection.text1")}
	// 								</p>
	// 								<p>
	// 									{t("page-without-conection.text2")}
	// 								</p>
	// 							</div>
	// 						</Grid>

	// 						<Grid item>
	// 							<Grid
	// 								container
	// 								direction="row"
	// 								justifyContent="center"
	// 								alignItems="center"
	// 								spacing={2}
	// 								columns={{ xs: 14 }}
	// 							>
	// 								<Grid item xs={5} >
	// 									<img src={monitor} alt="monitor" />
	// 								</Grid>
	// 								<Grid item xs={4} >
	// 									<div className="load-icon">
	// 										<span></span>
	// 										<span></span>
	// 										<span></span>
	// 									</div>
	// 								</Grid>
	// 								<Grid item xs={5} >
	// 									<img src={server} alt="server" />
	// 								</Grid>
	// 							</Grid>
	// 						</Grid>
	// 					</Grid>
	// 				</Card>
	// 			</div>
	// 		</Zoom>
	// 	</Backdrop>
	// );
};

export default NoConnection;