

const imssIcons = {
	bimonthlyvariables: {
		allowedTag: "Bimonthly-Variables",
		Icon: "./BimonthlyVariables.svg",
	},
	compare: {
		allowedTag: "Compare",
		Icon: "./Compare.svg",
	},
	emaeba: {
		allowedTag: "Ema-Eba",
		Icon: "./EMA_EBA.svg",
	},
	idseaudit: {
		allowedTag: "Idse-Audit",
		Icon: "./IDSEAudit.svg",
	},
	idseconfirmation: {
		allowedTag: "Idse-Confirmation",
		Icon: "./IDSEConfirmation.svg",
	},
	idsetransfer: {
		allowedTag: "Idse-Transfer",
		Icon: "./IDSETransfer.svg",
	},
	settlement: {
		allowedTag: "Settlement",
		Icon: "./Settlement.svg",
	},
	workriskhistory: {
		allowedTag: "Work-Risk-History",
		Icon: "./WorkRiskHistory.svg",
	},
	family: {
		allowedTag: "Family",
		Icon: "./Family.svg",
	},
};

export default imssIcons;