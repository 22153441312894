
const systemItems = [
    {
        id: 'user-and-roles@users',
        type: 'item',
        icon: 'users',
        componentElm: 'modules/system/principal/User',
    },
    {
        id: 'modules@users-list',
        type: 'item',
        componentElm: 'modules/system/principal/User',
        extraProps: { type: 'attendance' }
    },
    {
        id: 'user-and-roles@roles-permissions',
        type: 'item',
        icon: 'roles-and-permissions',
        componentElm: 'modules/system/principal/Roles'
    },
    //* BITACORA *//
    {
        id: 'modules@audit',
        type: 'item',
        icon: 'audit',
        componentElm: 'modules/system/principal/WorkerLog',
    },
    //* Welcome *//
    {
        id: 'welcome',
        type: 'item',
        index: true, // Indica que está a nivel raíz del menú
        icon: 'home',
        componentElm: 'modules/system/principal/Welcome',
    },
    {
        id: 'modules@workgroups',
        type: 'item',
        icon: 'workgroups',
        componentElm: 'modules/system/workgroup/Workgroup',
    },
    //* REPORTES *//
    {
        id: "main-reports",
        type: "item",
        index: true, // Indica que está a nivel raíz del menú
        icon: 'reports',
        componentElm: "modules/system/report/Reports",
    },
    // REPORTES DE NOMINA
    {
        id: 'modules@modules-payroll-reports',
        type: 'item',
        componentElm: 'modules/system/report/Reports',
        extraProps: { tag: "payroll" }
    },
    // REPORTES IMSS
    {
        id: "modules@imss-report-group",
        type: 'item',
        componentElm: 'modules/system/report/Reports',
        extraProps: { tag: "imss" }
    },
    // REPORTES BITACORA
    {
        id: "modules@system-reports-group",
        type: 'item',
        componentElm: 'modules/system/report/Reports',
        extraProps: { tag: "audit" }
    },
    // REPORTES TIMBRASOFT
    {
        id: 'modules@timbrasoft-reports',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true
        //componentElm: '../../../../modules/Reports',
        //extraProps: { tag:"payroll" }
    },
    // REPORTS DE CAPACITACION
    {
        id: "modules@modules-training-reports",
        type: "item",
        componentElm: 'modules/system/report/Reports',
        extraProps: { tag: "training" }
    },
    // REPORTES DE RH
    {
        id: "modules@modules-human-resources-reports-group",
        type: 'item',
        componentElm: 'modules/system/report/Reports',
        extraProps: { tag: "culture" }
    },
    // REPORTES DE ASISTENCIA Y MOPERS
    {
        id: "modules@modules-attendance-list-reports-group",
        type: 'item',
        componentElm: 'modules/system/report/Reports',
        extraProps: { tag: "mopers" }
    },
    {
        id: 'abrhil@business',
        type: 'item',
        icon: "enterprise",
        componentElm: 'modules/system/company/Company',
        sub_routes: [
            {
                index: true, // Indica que será el componente representante
                componentElm: 'modules/system/company/Company',
            },
        ]
    },

];

export default systemItems;