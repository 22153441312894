import React, { Fragment } from "react";
import Grid from "@mui/material/Grid2";
import useAdvanceFilters from "../useAdvanceFilters";
import { useTranslation } from "react-i18next";
import SubTitle from "../../../layout/Subtitle";
import { filterProps, getItemKey } from "../ConstantsAndUtilities/utilities";
import StructureFields from "../../../form/Structure";
import MuiAlert from "../../../general/MuiAlert";
import DateRange from "./DateRange";
import FormInput from "../../../form/Field";
import { hasValue, valueOrOption } from "../../../general/GeneralUtilities";

function InputsRender() {

    const { t } = useTranslation();

    const {
        control,
        fields,
        setValue,
        getValues,
    } = useAdvanceFilters();

    const filtersKeys = Object.keys(fields || {});

    return (
        <Grid id="advance-filters-form-container" container rowSpacing={1} columnSpacing={2}>
            {filtersKeys.map((keyName, groupIndex) => (
                <Fragment key={getItemKey(groupIndex)}  >
                    <SubTitle title={t(keyName)} asV2 asDivider className='mb-1' />
                    {valueOrOption(fields[keyName], [])
                        .filter(item => hasValue(item))
                        .map((item, fieldIndex) => {
                            const key = getItemKey(groupIndex, fieldIndex);
                            if (item[0] === "custom-element") {
                                const Filter = item[1];
                                return <Filter key={key} id={key} control={control} setValue={setValue} getValues={getValues} {...item?.[2]} />;
                            }

                            if (item[0] === "structure-filters") {
                                return (
                                    <StructureFields
                                        key={key}
                                        id={key}
                                        Container={Grid}
                                        containerProps={{ size: { xs: 12, md: 6, lg: 6, xl: 6 } }}
                                        {...item[1]}
                                        control={control}
                                        setValue={setValue}
                                    />
                                );
                            }
                            const props = filterProps(item) ?? {};
                            const fieldInput = props?.fieldInput ?? "textfield";
                            if (fieldInput === "info") {
                                return (
                                    <MuiAlert key={key} id={key} asV2 size={props?.containerProps?.size}>
                                        {t(props.label)}
                                    </MuiAlert>
                                );
                            }
                            if (fieldInput === "daterange") {
                                return (
                                    <DateRange key={key} {...props} id={key} control={control} />
                                );
                            }
                            return <FormInput {...props} key={key} id={key} control={control} label={t(props.label)} />;
                        })}
                </Fragment>
            ))}
        </Grid>
    );
};

export default InputsRender;