import { lazy } from "react";
import { each, filter, size } from "lodash";
import { hasValue, valueOrOption } from "../general/GeneralUtilities";
import menuElements from "./tree";
import icons from "../../../../assets/scss/custom-icons/icons";
import { iconsDictionary } from "./tree/icons";
import { t } from "i18next";
import { isAbrhil } from "../general/auth";

const abrhil = isAbrhil();

const defaultWindow = {
    NoPermision: lazy(() => import('../Pages/NoPermission')),
    UnderConstruction: lazy(() => import('../Pages/UnderConstruction')),
    PageNotFound: lazy(() => import('../Pages/PageNotFound')),
};

export const buildMenu = (items) => {
    let structuredMenu = [];
    each(items, function (item) { structuredMenu.push(item); });
    return structuredMenu;
};

/*
    Funcion que recorre cada una de las rutas dadas de alta en el sistema,
    compara los permisos dados y tambien las confiuraciones por paquete
    si alguno de estos no coincide regresa el elemento a visualizar sin permisos
*/
export const itemsPermisions = (allItems, configuration) => {

    const buildItem = (element) => {
        let item = { ...element };
        if (item?.sub_menu) {
            item.children = size(item.children) ? item.children : item.sub_menu;
            delete item.sub_menu;
        }

        item.title = valueOrOption(item.name, item.title);
        if (item?.path && item?.path !== 'no-path') {
            item.path = item.path.split("__").join("/");
            let pathSplit = item.path.split('/');
            item.key = `${pathSplit[0]}@${item.key}`;
            item.type = item.type ? item.type : 'item';
        } else if (item?.children) {
            item.id = item.key;
            item.icon = !icons[item?.icon ?? item.key]
                ? iconsDictionary[item.key] : item?.icon ?? item.key;
            item.type = "collapse";
            if (item?.path === 'no-path' || !size(item?.perms)) {
                delete item.perms;
            }
        }

        const nativeItem = menuElements.find(menuItem => menuItem.id === item.key);

        if (nativeItem) {
            item = { ...item, ...nativeItem };
        }

        if (!hasValue(item?.id) && item.type === 'item') {
            item.id = valueOrOption(item?.key, item?.path);
        }

        if (!item?.path && item?.index && item?.id) {
            item.path = item.id;
        }

        if (item?.children) {
            item.children = item.children.map(el => buildItem(el));
            if (!size(item.children)) { delete item.children; };
        }
        return item;
    };


    const verifyPackage = (package_permissions) => {
        let item = configuration;
        if (!package_permissions.includes(".")) {
            return item?.[package_permissions]?.value ?? false;
        }

        each(package_permissions.split("."), sub_permission => {
            item = item?.[sub_permission];
        });

        return item?.value ?? false;
    };

    const packageValidation = ({ showInMenu, package_permissions }) => {
        if (!showInMenu || !package_permissions) { return true; }
        return !size(filter(package_permissions, permissions => !verifyPackage(permissions)));
    };

    const findPerm = (itemPerms, perm) => itemPerms?.find(element => element?.action?.toLowerCase()?.includes(perm));

    const getItemProps = (element) => {
        const item = { ...element };
        const itemPermissions = item?.perms;
        const readPermissions = findPerm(itemPermissions, 'read');

        if (size(itemPermissions)) {
            if (item.type === 'item' && item?.children) {
                item.children = item.children.map((child) => {
                    if (child?.id) {
                        child.key = child.id;
                    }
                    if (!size(child?.perms)) {
                        child.perms = [...itemPermissions];
                    }
                    if (child?.children) {
                        child.children = getItemProps(child);
                    }
                    return child;
                });
            }
            if (item?.sub_routes) {
                item.sub_routes = item.sub_routes.map((route) => {
                    if (route?.id) {
                        route.key = route.id;
                        route.parentPath = item?.path;
                    }
                    if (!size(route?.perms)) {
                        route.perms = [...itemPermissions];
                    }
                    if (route?.sub_routes) {
                        route.sub_routes = getItemProps(route);
                    }
                    return route;
                });
            }
        }

        if (item?.package_permissions) {
            item.with_package_perms = packageValidation(item);

            if (item?.path.includes("additional") && !item.with_package_perms) {
                item.is_accesible = false;
            }
            item.showInMenu = item.with_package_perms || item.is_accesible === false;

            item.element = item.with_package_perms ? item.element : defaultWindow.NoPermision;
        }

        if (item?.inConstruction) {
            item.showInMenu = item?.showInMenu ?? true;
            item.element = item?.showInMenu ? defaultWindow.UnderConstruction : defaultWindow.PageNotFound;
        }

        const hide = (!readPermissions?.has_perm && size(itemPermissions) && !itemPermissions?.some(el => el.has_perm)) || (!!item.validateItem && !abrhil) || item.show_in_menu === false;

        if (hide) {
            item.element = defaultWindow.NoPermision;
            item.showInMenu = false;
        }

        if (!hasValue(item.element) && item.type === 'item') {
            item.element = defaultWindow.UnderConstruction;
        }

        return item;
    };

    const permised = (items, parent) => {
        return items.map(item => {
            item = buildItem(item);
            item.title = t(item.title);
            item.exact = item.exact ?? true;
            item.showInMenu = item.showInMenu ?? item.show_in_menu ?? item?.index ?? item?.type !== 'sub-route';
            item.element = item.inConstruction ? defaultWindow.UnderConstruction : item.componentElm;

            item = getItemProps(item);

            if (item.type !== "group") {
                item.bread = [...(parent.bread || []), item.title];
            }

            if (size(item?.children)) {
                item.children = permised(item.children, item);
                item.showInMenu = item?.type !== 'item' ? !!size(item.children.filter(child => child.showInMenu)) : item?.showInMenu;
            }

            if (size(item?.sub_routes)) {
                item.sub_routes = permised(item?.sub_routes, item);
                item.showInMenu = !!size(item?.sub_routes || item?.children);
            }

            return item;
        });
    };

    return permised(allItems, { bread: [] });
};