

const kioskIcons = {
	accesscontrol: {
		allowedTag: "Access-Control",
		Icon: "./AsignEstructure.svg",
	},
	asignestructure: {
		allowedTag: "Asigne-Structure",
		Icon: "./AccessControl.svg",
	},
	permissions: {
		allowedTag: "Permissions",
		Icon: "./Permisos.svg",
	},
	users: {
		allowedTag: "Users",
		Icon: "./Usuarios.svg",
	},
};

export default kioskIcons;