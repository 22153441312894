import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import ContentSkeleton from "./skeletons/Content";
import "./_styles.scss";

const Content = lazy(() => import("./components/Content"));

function GenericLabel({
    label,
    value,
    labelIcon: LabelIcon,
    valueIcon,
    tooltip,
    weight = '400',
    blueValue = true,
    useKardexSalary = false,
    useSalaryPerm = true,
    primaryLabel = false,
    wrapLabel = false,
    wrapValue = false,
    className = "",
    withValue = true,
    disableTooltip = false,
    fontSize = "clamp(12px, 0.7dvw, 14px)",
    align = "horizontal",
    style = {},
    labelStyle = {},
}) {

    return (
        <Suspense fallback={<ContentSkeleton fontSize={fontSize} />}>
            <Content
                label={label}
                value={value}
                labelIcon={LabelIcon}
                valueIcon={valueIcon}
                tooltip={tooltip}
                weight={weight}
                blueValue={blueValue}
                useKardexSalary={useKardexSalary}
                useSalaryPerm={useSalaryPerm}
                primaryLabel={primaryLabel}
                wrapLabel={wrapLabel}
                wrapValue={wrapValue}
                className={className}
                withValue={withValue}
                disableTooltip={disableTooltip}
                fontSize={fontSize}
                align={align}
                style={style}
                labelStyle={labelStyle}
            />
        </Suspense>
    );
};

export default GenericLabel;

GenericLabel.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    value: PropTypes.any,
    weight: PropTypes.string,
    blueValue: PropTypes.bool,
    wrapLabel: PropTypes.bool,
    wrapValue: PropTypes.bool,
    primaryLabel: PropTypes.bool,
    useSalaryPerm: PropTypes.bool,
    useKardexSalary: PropTypes.bool,
    align: PropTypes.string,
    valueIcon: PropTypes.any,
    labelIcon: PropTypes.any,
    style: PropTypes.object,
    withValue: PropTypes.bool,
    disableTooltip: PropTypes.bool,
    className: PropTypes.string,
    fontSize: PropTypes.string,
    tooltip: PropTypes.any,
    labelStyle: PropTypes.object,
};