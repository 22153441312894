import React, { lazy, memo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import SocketMessages from './SocketMessages';
import { valueOrOption } from '../general/GeneralUtilities';
import useSocketsLocked from './utilities/useSockedLocked';
import Default from "./Default";

const Calculating = lazy(() => import('./Calculating'));
const Scanning = lazy(() => import('./Scanning'));

const item = {
	calculating: Calculating,
	scanning: Scanning,
	default: Default,
};

const Locked = () => {

	const {
		lockedWindow,
		type,
	} = useSelector(state => state.locked) ?? {};

	const { openLock, messages, } = useSocketsLocked();

	if (openLock) {
		return (
			<Dialog open={true} className={`loaderContainer calculating`} maxWidth={'lg'}>
				<SocketMessages {...messages} />
			</Dialog>
		);
	}

	if (!lockedWindow) return;

	const loaderType = valueOrOption(type, "default");

	const Element = item[loaderType];

	return (
		<Dialog open={lockedWindow} className={`loaderContainer ${loaderType}`} maxWidth={'lg'}>
			<Suspense fallback={<Default />}>
				<Element />
			</Suspense>
		</Dialog>
	);
};

export default memo(Locked);