import { lazy } from "react";
import { importCache } from "./importsCache";

const DynamicImport = (modules, path) => {
    if (!path) return null;
    try {
        const foundModule = modules[path];
        if (!foundModule) return;
        if (importCache.has(path)) {
            return importCache.get(path);
        }
        const lazyImport = lazy(() => foundModule());
        importCache.set(path, lazyImport);
        return lazyImport;
    } catch (error) {
        console.error(error);
    }
};

export default DynamicImport;