import React from 'react';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import MUIButton from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { omit } from 'lodash';

const loaderStyles = {
	position: 'absolute',
	margin: 'auto',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	width: '14px',
	height: '14px',
};

const def = {
	borderRadius: '12px',
	color: 'var(--styledBlue)',
	fontSize: 'var(--base, 14px)',
	fontFamily: 'var(--root-font-family, "lato")',
	height: 'fit-content',
	letterSpacing: '0px',
	paddingInline: '12px',
	paddingBlock: '5px',
	opacity: '1',
	textTransform: 'none',
	lineHeight: "1",
	background: 'linear-gradient(var(--backgroundText), var(--backgroundText)) padding-box,linear-gradient(90deg, var(--vividCelurean), var(--buttonGradientBlue)) border-box',
	border: '1px solid transparent',
	'&:hover': {
		background: 'linear-gradient(transparent, transparent) padding-box,linear-gradient(90deg, var(--vividCelurean), var(--buttonGradientBlue)) border-box',
		color: 'var(--styledButtonText)',
		filter: 'brightness(90%)',
		'.MuiCircularProgress-root': {
			color: 'var(--styledButtonText)',
		}
	},
	':disabled': {
		opacity: '0.5',
		background: 'linear-gradient(transparent, transparent) padding-box,linear-gradient(90deg, var(--vividCelurean), var(--buttonGradientBlue)) border-box',
		color: 'var(--styledButtonText)',
		cursor: "not-allowed"
	},
	'.MuiCircularProgress-root': {
		color: 'var(--styledBlue)',
	}
};

const OutlinedButton = styled(MUIButton)(def);

const ContainedButton = styled(MUIButton)({
	...def,
	background: 'linear-gradient(transparent, transparent) padding-box,linear-gradient(90deg, var(--vividCelurean), var(--buttonGradientBlue)) border-box',
	color: 'var(--styledButtonText)',
	transition: 'all 400ms',
	'&:hover': {
		filter: 'brightness(90%)'
	},
	'.MuiCircularProgress-root': {
		color: 'var(--styledButtonText)',
	}
});

const TextButton = styled(MUIButton)({
	...def,
	background: 'none',
	color: 'var(--styledBlue)',
	'&:hover': {
		color: 'var(--styledBlueHover)',
		background: "var(--styledBlueOpacity)",
		'.MuiCircularProgress-root': {
			color: 'var(--styledBlueHover)',
		}
	},
	':disabled': {
		opacity: '0.5',
		color: 'var(--styledBlue)',
	},
});

const Button = ({
	design,
	children,
	color,
	type,
	onClick,
	loading,
	innerRef,
	className = "",
	hidden = false,
	...others
}) => {

	const { t } = useTranslation();
	const StyledButton = {
		'contained': ContainedButton,
		'text': TextButton,
		'flat': TextButton,
	}[design] ?? OutlinedButton;

	if (hidden) {
		return null;
	}

	if (!color && [t('close'), t('cancel')].includes(children)) {
		color = "error";
	}

	if (["text", "flat"].includes(design)) {
		className = `flat-button ${className}`;
	}

	return (
		<StyledButton
			{...omit(others, ["handleSubmit", "useDefaultErrorMsg"])}
			color={color}
			className={`${className} ${loading ? "is-loading" : ""}`}
			onClick={!loading ? onClick : null}
			type={!loading ? type : 'button'}
			ref={innerRef}
		>
			{children}
			{loading && <CircularProgress style={loaderStyles} thickness={5} />}
		</StyledButton>
	);
};

export default Button;