import PropTypes from "prop-types"
import React from "react";
import { motion } from "framer-motion";
import { t } from "i18next";
import Grid from "@mui/material/Grid2";
import Header from "./Header";
import Menu from "./Menu";

const Aditionals = ({
    items = []
}) => {

    return (
        <>
            <Grid container spacing={2} >
                <Grid size={12} className="mega-menu-header" >
                    <Header withCloseButton={false} customTitle={t("additionals")} />
                </Grid>
            </Grid>
            <motion.div className='childrens-container aditionals' >
                <Menu items={items} />
            </motion.div>
        </ >
    );
};

Aditionals.propTypes = {
    items: PropTypes.array
}

export default Aditionals;