import { config, API, configMedia } from "./config";
import { uniq, isString } from "lodash";
import axios from "axios";
import { currentTenant } from "../../general/@components/requests/validate-tenant";
import { isAbrhil } from "../../general/@components/general/auth";
import { apisWithGroup } from "./with_group";
import { hasValue, valueOrOption } from '../@components/general/GeneralUtilities';
import { current_datetime } from '../../general/@components/Inputs/Dates/utilities';
import { currentGroup } from "../../general/@components/requests/user-store";

export const needUserRole = (name) => {
	name = valueOrOption(name, "not-valid-url")
	const match = name.match(/(.*?)(?=\/\d*|$)/);

	const apiName = match ? match[1] : name;
	return (
		apisWithGroup.includes(apiName) ||
		(apiName.includes("calculate") && !apiName.includes("mopers-absenteeisms")) ||
		apiName.includes("payroll")
	);
};

/**
 * Función que nos sirve para contruir las rutas hacia el servidor que se requiere
 * @param {string} name - Es la URL base a la cual se quiere llegar
 * @param {boolean} include_tenant - Indica si la url va tener incluido el parametro del tenant o no
 * @returns {string}
 */
export const getUrl = (route, include_tenant = false) => {
	const apiSplit = (route ?? "").split("?");
	let apiParams = apiSplit[1] ?? "";
	const base = `${API}/${apiSplit[0]}/`;
	let params = apiParams.split("&");

	if (include_tenant && isAbrhil()) {
		params.push(`tenant=${currentTenant()}`);
	}

	if (needUserRole(route)) {
		params.push(`current_user_group=${currentGroup()}`);
	}

	params = uniq(params).filter(item => hasValue(item));
	if (params.length > 0) {
		apiParams = `?${params.join("&")}`;
	}

	return `${base}${apiParams}`
		.replace(/\/{0,3}\?{1,4}&{0,3}/, '/?')
		.replace('/&', '&');
};

/**
* Función que nos sirve para codificar los parametros que se requieren pasar por la url
* @param {object} params - Son los parametros que se pasarán por la url
 */
export const encodeURIParams = (params) => {
	let query = Object.entries(params)
		.map(([key, value]) => {
			if (Array.isArray(value)) {
				return `${key}=${value.join(",")}`;
			}
			return `${key}=${encodeURIComponent(value)}`;
		});

	if (isAbrhil()) {
		query.push(`tenant=${currentTenant()}`);
	}

	query = uniq(query).filter(item => hasValue(item));
	if (query.length > 0) {
		return `?${query.join("&")}`;
	}

	return "";
};

/**
 * Función que nos sirve  para conrolar de manera general todos los request del sistema
 * @param {object} props -  las propiedades a evaluar
 * @param {object} props.params - Son todos los parametros que se deben incluir en el request
 * @param {?boolean} props.include_tenant - Indica si vamos a incluir el valor del tenant actual, por default es True
 * @param {?boolean} props.include_config - Inidica se vamos a parsear los parametros por el servicio config general, por default False
 * @param {?boolean} props.include_role - Indica si vamos a incluir el valor del rol actual, por default es False
 * @returns {object}
 */

export const ParseParams = ({
	params = {},
	include_tenant = true,
	include_role = false,
	include_config = false
}) => {

	const finalparams = {
		rq_tzone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		rq_date: current_datetime(),
		...params,
		...(include_tenant && isAbrhil() ? { tenant: currentTenant() } : {}),
		...(include_role ? { current_user_group: currentGroup() } : {})
	};

	return include_config ? config(finalparams) : finalparams;
};


/**
 * Función general del sisema para contruir todos los metodos axios de manera eficaz y sin repetir código
 * @param {string} name - Es la URL base del servicio que se va a contruir
 * @param {boolean} include_tenant - Inidica si en los metodos que se van a construir se va inlcluir eltenant actal,por default True
*/
export function ServerAPI(route, include_tenant = true, include_current_role = false) {

	const include_config = true;
	const include_role = include_current_role || needUserRole(route);
	const performResponse = response => response?.data;

	const getRoute = ({ route_extension, id, use_tenant = true }) => {
		let newRoute = route;
		if (hasValue(route_extension) && isString(route_extension)) {
			newRoute += `/${route_extension}`;
		}
		if (hasValue(id)) {
			newRoute += `/${id}`;
		}

		return getUrl(newRoute, (include_tenant && use_tenant));
	};

	const get = (params, route_extension) => {
		const payload = ParseParams({ params, include_config, include_tenant, include_role });
		return axios.get(getRoute({ use_tenant: false, route_extension }), payload).then(performResponse);
	};

	const getOne = (id, params, route_extension) => {
		const payload = ParseParams({ params, include_config, include_tenant });
		return axios.get(getRoute({ use_tenant: false, route_extension, id }), payload).then(performResponse);
	};

	const post = (params, route_extension) => {
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.post(getRoute({ route_extension }), payload, config()).then(performResponse);
	};

	const postMedia = (params, route_extension) => {
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.post(getRoute({ route_extension }), payload, configMedia()).then(performResponse);
	};

	const put = (id, params, route_extension) => {
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.put(getRoute({ route_extension, id }), payload, config()).then(performResponse);
	};

	const putConcatName = (params) => {
		const url = getUrl(`${route}`, include_tenant);
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.put(url, payload, config()).then(performResponse);
	};

	const putMedia = (id, params, route_extension) => {
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.put(getRoute({ route_extension, id }), payload, configMedia()).then(performResponse);
	};

	const putUrl = (id, params, body) => {
		const request = encodeURIParams(params);
		const url = getUrl(`${route}/${id}/${request}`);
		return axios.put(url, body, config()).then(performResponse);
	};

	const postUrl = (params, body) => {
		const request = encodeURIParams(params);
		const url = getUrl(`${route}/${request}`);
		return axios.post(url, body, config()).then(performResponse);
	};

	const patch = (id, params, route_extension) => {
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.patch(getRoute({ route_extension, id }), payload, config()).then(performResponse);
	};

	const patchUrl = (id, params, body) => {
		const request = encodeURIParams(params);
		const url = getUrl(`${route}/${id}/${request}`);
		return axios.patch(url, body, config()).then(performResponse);
	};

	const patchMedia = (id, params, route_extension) => {
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.patch(getRoute({ route_extension, id }), payload, configMedia()).then(performResponse);
	};

	const patchConcatName = (params) => {
		const url = getUrl(`${route}`, include_tenant);
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.patch(url, payload, config()).then(performResponse);
	};

	const multi = (params) => {
		const url = getUrl(`${route}/multi`, include_tenant);
		const payload = ParseParams({ params, include_tenant, include_role });
		return axios.put(url, payload, config()).then(performResponse);
	};

	const destroy = (id, params, route_extension) => {
		const payload = ParseParams({ params, include_tenant, include_config, include_role });
		return axios.delete(getRoute({ route_extension, id }), payload).then(performResponse);
	};

	return {
		get,
		post,
		postMedia,
		patchUrl,
		patchMedia,
		put,
		putConcatName,
		putMedia,
		putUrl,
		postUrl,
		destroy,
		multi,
		getOne,
		patch,
		patchConcatName,
	};
}
