
const staffingIcons = {
	branchmap: {
		allowedTag: "requisitionIcons",
		Icon: "./Branch_Map.svg",
	},
    label: {
		allowedTag: "label",
		Icon: "./Label.svg",
	},
	staffwarning: {
		allowedTag: "staffwarning",
		Icon: "./Staff_Warning.svg",
	},
};

export default staffingIcons;