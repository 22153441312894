
const kpiItems = [
	//* KPIS *//
	{
		id: 'kpis',
		type: 'item',
		index: true, // Indica que está a nivel raíz del menú
		icon: 'kpis',
		componentElm: "modules/kpis/Dashboard",
	},
];

export default kpiItems;