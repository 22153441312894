

const TimbrasoftIcons = {
	advancefiltersstamp: {
		allowedTag: "Advance-Filters-Stamp",
		Icon: "./AdvanceFiltersStamp.svg",
	},
	stamp: {
		allowedTag: "Stamp",
		Icon: "./Stamp.svg",
	},
	stampmenu: {
		allowedTag: "stamp-menu",
		Icon: "./StampMenu.svg",
	},
	mail: {
		allowedTag: "Mail",
		Icon: "./Mail.svg",
	},
	cancelstamp: {
		allowedTag: "Cancel-stamp",
		Icon: "./CancelStamp.svg",
	},
	editmail: {
		allowedTag: "Edit-mail",
		Icon: "./EditMail.svg",
	},
	download: {
		allowedTag: "Download",
		Icon: "./Download.svg",
	},
	stamperror: {
		allowedTag: "Stamp-error",
		Icon: "./Error.svg",
	},
	cancelerror: {
		allowedTag: "Cancel-error",
		Icon: "./cancelerror.svg",
	},
	notificated: {
		allowedTag: "Notificated",
		Icon: "./Notificated.svg",
	},
	stampok: {
		allowedTag: "Stamp-ok",
		Icon: "./Ok.svg",
	},
	cancel: {
		allowedTag: "Cancel",
		Icon: "./Cancel.svg",
	},
	opennewtab: {
		allowedTag: "Open-new-tab",
		Icon: "./OpenNewTab.svg",
	},
	upload: {
		allowedTag: "Upload",
		Icon: "./Upload.svg",
	},
	bobeda: {
		allowedTag: "Bobeda",
		Icon: "./Bobeda.svg",
	},
	confronta: {
		allowedTag: "Confronta",
		Icon: "./Confronta.svg",
	},
	homestamp: {
		allowedTag: "HomeStamp",
		Icon: "./Home.svg",
	},
	moreoptionstamp: {
		allowedTag: "More-Options-Stamp",
		Icon: "./MoreOptions.svg",
	},
	stamped: {
		allowedTag: "Stamped",
		Icon: "./Timbrado.svg",
	},
	validationcp: {
		allowedTag: "Validationcp",
		Icon: "./Validation.svg",
	},
	certificate: {
		allowedTag: "Certificate",
		Icon: "./Certificate.svg",
	},
	conceptsat: {
		allowedTag: "Conceptsat",
		Icon: "./Conceptsat.svg",
	},
	contractingregime: {
		allowedTag: "Contractingregime",
		Icon: "./Contractingregime.svg",
	},
	origin: {
		allowedTag: "Origin",
		Icon: "./Origin.svg",
	},
	periodicitysat: {
		allowedTag: "Periodicity-sat",
		Icon: "./Periodicity.svg",
	},
	relation: {
		allowedTag: "Relation",
		Icon: "./Relation.svg",
	},
	reportsat: {
		allowedTag: "Reports-sat",
		Icon: "./Reports.svg",
	},
	typecontractsat: {
		allowedTag: "Typecontract-sat",
		Icon: "./Typecontractsat.svg",
	},
	configurationstamp: {
		allowedTag: "Configurationstamp",
		Icon: "./Configurationstamp.svg",
	},
};

export default TimbrasoftIcons;
