import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//--project imports
import { hideOptioNotificationDialog, showNotificationError } from '../../../../store/actions';
import { useTranslation } from "react-i18next";
import FormDialog from '../modal/dialog/FormDialog';
import DialogContent from '../../styled/DialogContent';
import { hasValue, valueOrOption } from '../general/GeneralUtilities';
// skeletons
import ActionsSkeleton from "./skeletons/Actions";
import ContentSkeleton from "./skeletons/Content";

const Actions = lazy(() => import("./Actions"));
const Content = lazy(() => import("./Content"));


const OptionNotification = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		show,
		maxWidth,
		title,
		defaultSelection,
		submitText,
		onConfirm,
		onCancel,
	} = useSelector(state => state.notification.optionNotification) ?? {};

	const [selected, setSelected] = useState(null);

	useEffect(() => {
		if (!show) {
			setSelected(null);
			return;
		}

		if (hasValue(defaultSelection)) {
			setSelected(defaultSelection);
		}
		//eslint-disable-next-line
	}, [show]);


	const handleClose = () => {
		dispatch(hideOptioNotificationDialog());
		if (typeof onCancel === "function") {
			onCancel();
		}
	};

	const onAccept = () => {
		if (!hasValue(selected)) {
			return dispatch(showNotificationError({
				maxWidth: "sm",
				message: t("selecte-option-is-required")
			}));
		}

		dispatch(hideOptioNotificationDialog());
		if (typeof onConfirm === "function") {
			onConfirm(selected);
		}
	};

	return (
		<FormDialog
			open={show}
			title={valueOrOption(title)}
			maxWidth={valueOrOption(maxWidth, "md")}
			handleClose={handleClose}
			fullWidth
		>
			<DialogContent className={""}>
				<Suspense fallback={<ContentSkeleton />}>
					<Content
						selected={selected}
						setSelected={setSelected}
					/>
				</Suspense>
			</DialogContent>
			<Suspense fallback={<ActionsSkeleton />}>
				<Actions
					handleClose={handleClose}
					selected={selected}
					submitText={submitText}
					onAccept={onAccept}
				/>
			</Suspense>
		</FormDialog>
	);
};

export default OptionNotification;
