import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Grid from '@mui/material/Grid2';
import Header from './Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Menu from './Menu';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import MenuTabs from './Tabs';
import useAbrhilNavigation from '../../../contextsAndControllers/useAbrhilNavigation';
import { isSelected, withLayout } from '../../../utilities';
import { ItemIcon } from '../Search/NavItem';
import Footer from './Footer';
import CustomTooltip from '../../../../Templates/CustomTooltip';
import { hasValue } from '../../../../general/GeneralUtilities';
import Aditionals from './Aditionals';
import { transition3 } from '../../../../form/Permission/Utils';

const MegaMenu = ({ item = {} }) => {

	const { title, bread, icon, children = [] } = item;

	const {
		showed,
		breadcrumbs,
	} = useAbrhilNavigation();

	const {
		// tab,
		// search,
		forceOpen,
		// debouncedSearch,
		forceHide,
		isSubmenu
	} = useMegaMenu();

	const scrollRef = useRef(null);
	const contentRef = useRef(null);
	const showMenu = showed?.key === item?.key;

	const aditionalsModule = children?.find(element => element?.key === "additional-modules-group")?.children;
	const withAditionals = hasValue(aditionalsModule) && !isSubmenu;


	useLayoutEffect(() => {
		if (scrollRef?.current) {
			setTimeout(() => {
				scrollRef.current?.updateScroll();
			}, [200]);
		}
	}, [showMenu]);

	const handleEsc = (e) => {
		const code = e?.keyCode?.toString();
		if (parseInt(code) === 27) {
			forceHide();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleEsc);
		return () => {
			document.removeEventListener("keydown", handleEsc);
		};
		//eslint-disable-next-line
	}, [showMenu]);

	return (
		<>
			{showMenu && (
				<div
					onClick={forceHide}
					style={{
						background: "transparent",
						position: "fixed",
						width: "100%",
						height: "100%",
						zIndex: -1,
						top: 0,
						left: 0,
					}}
				/>
			)}
			<span
				className={`sidebar-item ${showMenu ? "isOpened" : ""} ${isSelected(bread, breadcrumbs)}`}
				ref={contentRef}
			>
				<CustomTooltip title={title} className="menu-item-tooltip" placement="left">
					<span className='side-menu-item-icon' onClick={() => (showed && item?.id === showed?.id) ? forceHide() : forceOpen(item)}>
						<ItemIcon icon={icon} />
					</span>
				</CustomTooltip>
				<AnimatePresence
					mode="wait"
					propagate
				>
					{showMenu &&
						<motion.div
							key="megamenu"
							animate={visible}
							initial={hidden}
							exit={hidden}
							transition={transition3}
							className="megadrop"
						>
							<motion.div className='megadrop-inner-container' layout >
								<Grid container spacing={1} {...withLayout}>
									<Grid size={12} className="mega-menu-header" >
										<Header />
									</Grid>
									<Grid size={12} className="mega-menu-header" >
										<MenuTabs />
									</Grid>
								</Grid>

								<motion.div
									className={`childrens-container ${withAditionals ? 'with-aditionals' : ''}`}
								>
									<PerfectScrollbar ref={scrollRef}>
										<Menu withDivider={!withAditionals} />
										{withAditionals &&
											<Aditionals
											items={aditionalsModule}
											/>
										}
									</PerfectScrollbar>
								</motion.div>
								<Footer withAditionals={false} />
							</motion.div>
						</motion.div>
					}
				</AnimatePresence>
			</span>
		</>
	);
};

MegaMenu.propTypes = {
	item: PropTypes.object
}

export default MegaMenu;

const visible = {
	opacity: 1,
	display: "block",
	x: 0,
};
const hidden = {
	opacity: 0,
	display: "none",
	x: -900,
};