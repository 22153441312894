import React, { lazy, Suspense } from "react";
import LazySkeleton from "./LazySkeleton";

const Buttons = lazy(() => import("./Buttons"));
const MsgBody = lazy(() => import("./MsgBody"));
const Skeleton = lazy(() => import("./Skeleton"));

function StatusSkeleton(props) {
    return (
        <Suspense fallback={<LazySkeleton />}>
            <Skeleton {...props}>
                <MsgBody {...props} />
                <Buttons {...props} />
            </Skeleton>
        </Suspense>
    );
};

export default StatusSkeleton;