import React from "react";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "@mui/material";
import { gridSkeletonProps } from "./constants";

function ContentSkeleton() {

    return (
        <Grid container spacing={2}>
            <Grid size={12}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid size={12}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid size={12}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
        </Grid>
    );
};

export default ContentSkeleton;