import {
    faFolderGear,
} from "@fortawesome/pro-light-svg-icons";

const abrilItems = [
    {
        id: 'abrhil@corporate',
        type: 'item',
        validateItem: true,
        icon: "corporate",
        componentElm: "modules/abrhil/corporate/Corporate",
    },
    {
        id: 'abrhil@migrations',
        type: 'item',
        validateItem: true,
        icon: "migration",
        componentElm: 'modules/abrhil/migration/Migrations',
    },
    {
        id: 'abrhil@pay-frequency',
        type: 'item',
        componentElm: 'modules/abrhil/payroll/Periodicity'
    },
    {
        id: 'abrhil@schematics',
        type: 'item',
        componentElm: 'modules/abrhil/payroll/Schematics'
    },
    {
        id: 'abrhil@payment-types',
        type: 'item',
        componentElm: 'modules/abrhil/payroll/PaymentTypes'
    },
    {
        id: 'abrhil@federal-entities',
        type: 'item',
        componentElm: 'modules/abrhil/general/FederalEntities',
    },
    {
        id: 'abrhil@contract-catalog',
        type: 'item',
        componentElm: 'modules/abrhil/general/ContractCatalog',
    },
    {
        id: 'abrhil@migratory-status',
        type: 'item',
        componentElm: 'modules/abrhil/general/MigratoryState',
    },
    {
        id: 'abrhil@blood-type',
        type: 'item',
        componentElm: 'modules/abrhil/general/Bloodtype'
    },
    {
        id: 'abrhil@onboarding',
        type: 'item',
        validateItem: true,
        icon: faFolderGear,
        componentElm: 'modules/abrhil/general/OnBoardingAdmin',
    },
    {
        id: 'abrhil@banks',
        type: 'item',
        componentElm: 'modules/abrhil/bank/Banks',
    },
    {
        id: 'abrhil@layouts',
        type: 'item',
        componentElm: 'modules/abrhil/bank/Layouts',
    },
    {
        id: 'abrhil@voucher-banks',
        type: 'item',
        package_permissions: ["payroll_package", "payroll_package.period_payment_voucher"],
        componentElm: 'modules/abrhil/voucher/VoucherBanks',
    },
    {
        id: 'abrhil@voucher-layouts',
        type: 'item',
        package_permissions: ["payroll_package", "payroll_package.period_payment_voucher"],
        componentElm: 'modules/abrhil/voucher/VoucherLayouts',
    },
    {
        id: 'abrhil@imss-class',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: 'modules/abrhil/employer-registry/IMSSClass',
    },
    {
        id: 'abrhil@imss-division',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: 'modules/abrhil/employer-registry/IMSSDivision',
    },
    {
        id: 'abrhil@imss-fraction',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: 'modules/abrhil/employer-registry/IMSSFraction',
    },
    {
        id: 'abrhil@imss-group',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: 'modules/abrhil/employer-registry/IMSSGroup',
    },
    {
        id: 'abrhil@type-of-risk',
        type: 'item',
        componentElm: 'modules/abrhil/inability/TypeOfRisk',
    },
    {
        id: 'abrhil@inability-classification',
        type: 'item',
        componentElm: 'modules/abrhil/inability/InabilityClassification',
    },
    {
        id: 'abrhil@inability-covid',
        type: 'item',
        componentElm: 'modules/abrhil/inability/Covid',
    },
    {
        id: 'abrhil@inability-control',
        type: 'item',
        componentElm: 'modules/abrhil/inability/InabilityControl',
    },
    {
        id: 'abrhil@insurance-branch',
        type: 'item',
        componentElm: 'modules/abrhil/inability/InsuranceBranch',
    },
    {
        id: 'abrhil@sequel-consequence',
        type: 'item',
        componentElm: 'modules/abrhil/inability/SequelConsequence',
    },
    {
        id: 'abrhil@moper-catalog',
        type: 'item',
        componentElm: 'modules/abrhil/attendance/Mopers',
    },
    {
        id: 'abrhil@absenteeism-list',
        type: 'item',
        componentElm: 'modules/abrhil/attendance/Absenteeism',
    },
    {
        id: 'abrhil@degree-documents',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/abrhil/training/DegreeDocuments',
    },
    {
        id: "modules@degree-documents-training-mirror",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/abrhil/training/DegreeDocuments',
    },
    {
        id: 'abrhil@device',
        type: 'item',
        icon: 'clock',
        componentElm: 'modules/abrhil/access-control/Device',
    },
    {
        id: 'abrhil@type-sat-regime-list',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: 'modules/abrhil/timbrasoft/RegimeType',
    },
    {
        id: 'abrhil@pay-frequency-sat',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: 'modules/abrhil/timbrasoft/PeriodicitySAT',
    },
    {
        id: 'abrhil@resource-origin',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: 'modules/abrhil/timbrasoft/ResourceOrigin',
    },
    {
        id: 'abrhil@contracting-regime',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: 'modules/abrhil/timbrasoft/ContractingRegime',
    },
    {
        id: 'abrhil@type-contract-sat',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: 'modules/abrhil/timbrasoft/ContractSAT',
    },
    {
        id: 'abrhil@concept-sat',
        type: 'item',
        componentElm: 'modules/abrhil/timbrasoft/StampConcept',
    },
    {
        id: 'abrhil@rfc',
        type: 'item',
        componentElm: 'modules/abrhil/timbrasoft/RFC',
    },

    // In construction
    {
        id: 'abrhil@packages_admin',
        type: 'item',
        inConstruction: true,
        validateItem: true,
        icon: faFolderGear,
        componentElm: true,
    },
    {
        id: 'abrhil@nationalities',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@federal-entities',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@tax-regime',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@working-day',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@salary-type',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@tax-tables',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@status-payroll',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@credit-type-discount',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@movement-type',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@credit-type-place',
        type: 'item',
        inConstruction: true,
        showInMenu: false,
        componentElm: true
    },
    {
        id: 'abrhil@banks-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@dynamic-banks-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@food-voucher-enterprise',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@voucher-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@dynamic-voucher-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    }
];

export default abrilItems;