import React from "react";
import { useDispatch, useSelector } from "react-redux";
//--project imports
import { hideNotificationSuccess } from '../../../../../store/actions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import StatusSkeleton from "./DialogSkeleton/StatusSkeleton";
import { Transition } from "./DialogSkeleton/Transition";

const SuccessNotification = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		title,
		description,
		message,
		show,
		maxWidth,
		onConfirm,
		...others
	} = useSelector(state => state.notification.successNotification);

	const handleClose = () => {
		dispatch(hideNotificationSuccess());
		if (typeof onConfirm === "function") {
			onConfirm();
		}
	};

	return (
		<Dialog
			open={show}
			slots={{ transition: Transition }}
			className="myDialog successDialog"
			maxWidth={maxWidth ?? "lg"}
			onClose={handleClose}
		>
			<StatusSkeleton
				title={title || t("success-general-title")}
				lottieIcon={"success-icon"}
				message={message || ""}
				description={description || ""}
				close={handleClose}
				{...others}
			/>
		</Dialog>
	);
};

export default SuccessNotification;