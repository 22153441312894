import React, { Suspense, useEffect } from 'react';
import {
	Navigate,
	Routes,
	Route,
	BrowserRouter,
} from 'react-router-dom';
import initialRoutes from '../route';
import { authentication } from '../core/general/@components/general/auth';

import AdminLayout from './layout/AdminLayout';
import PageSkeleton from './layout/PageSkeleton';
import DomainReload from '../core/general/@components/Pages/DomainReload';
import { dashboardPath } from '../core/general/@components/requests/validate-tenant';

const App = () => {
	useEffect(() => {
		const body = document.querySelector("body");
		body.setAttribute("app-mode", process.env.REACT_APP_ABR_MODE);
		//eslint-disable-next-line
	}, []);

	const menu = [
		{ path: '/', exact: true, element: <InitialLayout /> },
		...initialRoutes,
		{ path: '/app/loader', exact: true, element: <DomainReload /> },
		{ path: 'app/*', exact: true, element: <AppLayout /> },
	].map((route) => {
		return <Route {...route} key={route.path} />;
	});

	return (
		<Suspense fallback={<PageSkeleton />}>
			<BrowserRouter>
				<Routes>{menu}</Routes>
			</BrowserRouter>
		</Suspense>
	);
};

export default App;

const InitialLayout = () => {
	if (authentication()) {
		// user is authenticated
		return <Navigate replace={true} to={`/app/${dashboardPath()}`} />;
	}

	return <Navigate replace={true} to='/login' />;
};

const AppLayout = () => {
	if (!authentication()) {
		// user is not authenticated
		return <Navigate replace={true} to='/login' />;
	}

	return <AdminLayout />;
};
