import PropTypes from "prop-types"
import React from "react";
import { size, isFunction, isArray, isObject, /* isArray */ } from "lodash";
import { useDispatch, useSelector } from "react-redux";
//--project imports
import { hideControlledError } from '../../../../../store/actions';
import Dialog from '@mui/material/Dialog';
import LANG from "i18next";
import { useTranslation } from "react-i18next";
import StatusSkeleton from "./DialogSkeleton/StatusSkeleton";
import { hasValue, valueOrOption } from '../../general/GeneralUtilities';
import { UlElement } from '../../form/ErrorsList';
import { Transition } from "./DialogSkeleton/Transition";
import MsgBody from "./DialogSkeleton/MsgBody";

const ControlledError = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		show,
		fields,
		general,
		maxWidth,
		title: notificationTitle,
		message,
		description,
		paramsLabels,
		onConfirm,
	} = useSelector(state => state?.notification?.controlledError) ?? {};

	const errors = valueOrOption(fields, general);
	const controlledType = typeof errors !== "string" && errors?.severity !== "error" ? "warning" : "error";
	const title = `${controlledType}-general-title`;
	const dialogType = `${controlledType}Dialog`;
	const icon = `${controlledType}-icon`;

	const onConfirmClick = () => {
		dispatch(hideControlledError());
		if (isFunction(onConfirm)) {
			onConfirm();
		}
	};

	return (
		<Dialog
			open={show}
			slots={{ transition: Transition }}
			className={`myDialog ${dialogType}`}
			maxWidth={maxWidth ?? "md"}
			onClose={onConfirmClick}
		>
			<StatusSkeleton
				isCustomChild={true}
				customChildren={<CustomChild paramsLabels={paramsLabels} errors={errors} fields={fields} />}
				lottieIcon={icon}
				title={notificationTitle ?? t(title)}
				message={message ?? ""}
				description={description ?? ""}
				close={onConfirmClick}
			/>
		</Dialog>
	);
};


const CustomChild = ({
	errors,
	paramsLabels = {},
}) => {

	const { t } = useTranslation();

	if (typeof errors === "string") {
		if (errors.includes("DOCTYPE")) {
			return <MsgBody message={t("error-get-message")} description={t("error-general-description")} />;
		}

		return <div className="controlledContainer">
			{errors}
		</div>;
	}

	const total = size(errors);
	const keys = !isArray(errors) && isObject(errors) ? Object.keys(errors) : errors;
	if (
		(["detail", "non_field_errors"].includes(keys?.[0]) && total === 1) ||
		((total === 2 || total === 3) && errors?.detail_code && errors?.message)
	) {
		return (
			<div className="textContainer">
				<div className={`dialogDescription`}>
					<p className={`dialogMessage`}>{
						renderMessage(errors)}
					</p>
					<p>{renderDetail(errors)}</p>
				</div>
			</div>
		);
	}

	return (
		<div className="controlledContainer">
			<UlElement
				errors={errors}
				fieldsLabels={paramsLabels}
			/>
		</div>
	);
};

CustomChild.propTypes = {
	errors: PropTypes.any,
	paramsLabels: PropTypes.any
}

export default ControlledError;

const renderMessage = ({ detail_code, message, detail, non_field_errors }) => {
	const { t } = LANG;
	let msg = message ?? detail ?? non_field_errors;
	if (hasValue(detail_code) && t(detail_code) !== detail_code) {
		msg = t(detail_code);
	}
	return msg;
};

const renderDetail = ({ detail_code }) => {
	const { t } = LANG;
	if ([
		"not-role-assigned-to-user",
		"role-is-not-assigned-to-user",
		"not-branch-employer-assigned",
		"not-payroll-permissions",
		"not-calculate-permissions",
		"not-module-permissions-configured",
		"not-payroll-reset-permissions",
		"not-payroll-cancel-vacations-permissions",
		"not-payroll-use-payroll-policy-permissions",
		"not-payroll-use-provision-policy-permissions",
		"not-special-payrolls-delete-permissions",
		"user-not-manage-payroll-worker",
		"perdeduc-access",
		"block-payroll-access",
		"cancel-payroll",
	].includes(detail_code)) {
		return t('review-perm-error');
	}
	return null;
};