import React from "react";
import i18next from 'i18next';
import {
	size,
	isArray,
	isString,
	isEqual,
} from 'lodash';
import { iconsDictionary } from "./tree/icons";
import { store } from "../../../../store";
import { hasValue, valueOrOption } from '../general/GeneralUtilities';
import icons from '../../../../assets/scss/custom-icons/icons';
import { motion } from "framer-motion";
const { t } = i18next;

export const concidence = (title, search) => {
	search = replaceLetter(search);
	title = replaceLetter(title);

	return title.indexOf(search) !== -1;
};

/* funcion usada para la busqueda de elementos en el menu lateral de la aplicación */
export const filterChildrens = (children, search, title) => {

	if (!hasValue(search) || concidence(title, search)) {
		return children;
	}

	if (!isArray(children)) {
		return null;
	}

	return children.map(child => {
		child.filtereds = child.children;

		if (size(child.filtereds) > 0) {
			child.filtereds = filterChildrens(child.filtereds, search, child?.title);
		}
		const validation = (concidence(child.title, search) && !!child.path) || size(child.filtereds) > 0;

		return validation && child.showInMenu ? child : null;
	}).filter(item => item !== null);
};

export const replaceLetter = (word) => {
	return valueOrOption(word, "")
		.toString()
		.toLowerCase()
		// .replace(/ñ|Ñ/g,"n")
		.replace(/[áäªàâãå]/g, "a")
		.replace(/[éëèêęėē]/g, "e")
		.replace(/[íïìîįī]/g, "i")
		.replace(/[óöºòôõō]/g, "o")
		.replace(/[úüùûū]/g, "u");
};

export const getTabIcon = (item, tab) => {

	const { icon, icon_name, key, } = item ?? {};

	if (hasValue(icon)) {
		return icon;
	}

	let category = replaceLetter(tab);
	if (size(category.split(" ")) <= 1) {
		category = getItemCategory(category);
	}

	const item_icon = valueOrOption(icon, icon_name);
	const predefined = getPredefinedIcon(item_icon, key);

	return valueOrOption(predefined, {
		tools: 'config-icon',
		catalogs: 'catalog',
		settings: 'config-icon',
		reports: 'report',
		home: 'home',
	}[category]) ?? null;
};


const getItemCategory = (tab) => {
	if (!hasValue(tab)) {
		return "home";
	}

	if (tab.includes("catalog")) {
		return "catalogs";
	} else if (tab.includes("report")) {
		return "reports";
	} else if (tab.includes("config") || tab.includes("setti")) {
		return "settings";
	}

	return "tools";
};

export const getPredefinedIcon = (icon, key) => {
	if (!isString(icon)) {
		return valueOrOption(icon, null);
	}

	const lowIcon = icon.toLowerCase();
	const lowKey = key.toLowerCase();
	if (hasValue(icons[lowIcon])) {
		return icon;
	}
	if (hasValue(icons[lowKey])) {
		return key;
	}
	const abrStr = iconsDictionary;
	const predefined = valueOrOption(abrStr[lowIcon], abrStr[lowKey]);
	if (hasValue(predefined)) {
		return predefined;
	}
	return valueOrOption(icon, null);
};

export const getNavItem = (key, replace = true, fullItem = false) => {
	if (!hasValue(key)) {
		return null;
	}
	const routes = store.getState().navigate?.routes;
	let item = null;
	if (routes) {
		for (const route of routes) {
			const foundItem = findItemByKey(route, key);
			if (foundItem) {
				item = foundItem;
				break;
			}
		}
	}

	if (fullItem) {
		return item;
	}

	const { path } = item ?? {};

	if (!hasValue(path)) {
		return null;
	}

	return replace ? `../${path}` : path;
};

export const getModulePerm = (moduleId, permName) => {
	const modulePerms = getNavItem(moduleId, false, true)?.perms;
	if (!permName) {
		return modulePerms;
	}
	return modulePerms?.find(el => el?.action === permName)?.has_perm;
};

export const permsGate = (perms) => {
	if (isArray(perms)) {
		return perms.map(perm => permsGate(perm)).some(el => el);
	}
	if (isString(perms)) {
		const sPerm = perms.split('.');
		const foundPerms = getModulePerm(sPerm[0], sPerm[1]);
		if (!sPerm[1]) return foundPerms?.some(el => el?.has_perm);
		return foundPerms;
	}
};

function findItemByKey(item, key) {
	if (item?.key === key || item?.id === key) {
		return item;
	}
	if (item?.children) {
		for (const child of item.children) {
			const response = findItemByKey(child, key);
			if (response) {
				return response;
			}
		}
	}
	return null;
}

export function findItemByPath(path, items) {
	if (!items) return;
	let item = null;
	for (const child of items) {
		const foundItem = itemByPath(child, path);
		if (foundItem) {
			item = foundItem;
			break;
		}
	}
	return item;
}

function itemByPath(item, path) {
	let itemPath = item?.path;
	if (item?.parentPath) {
		itemPath = `${item?.parentPath}/${item?.path}`;
	}
	if (itemPath === path) {
		return item;
	}
	if (item?.sub_routes) {
		for (const child of item.sub_routes) {
			const response = itemByPath(child, path);
			if (response) {
				return response;
			}
		}
	}
	if (item?.children) {
		for (const child of item.children) {
			const response = itemByPath(child, path);
			if (response) {
				return response;
			}
		}
	}
	return null;
}

export function validatePerms(tree) {
	function traverse(node) {
		if (node && node?.perms) {
			const has_perm = node.perms.find(el => el?.has_perm === true);
			if (has_perm) {
				return true;
			}
		}
		if (node && node?.children && size(node?.children) > 0) {
			for (let i = 0; i < node.children.length; i++) {
				if (traverse(node.children[i])) {
					return true;
				}
			}
		}
		return false;
	}
	return traverse(tree);
}

export const fromAttendance = (returnObject = true) => {
	const currentPath = getCurrentPath();
	const attendancePath = 'attendance-mopers';
	const value = currentPath.includes(attendancePath);
	const object = value ? { from_list: true } : {};
	return returnObject ? object : value;
};

export const fromMopers = (returnObject = true) => {
	const mopersPath = getNavItem('modules@mopers', false);
	const currentPath = getCurrentPath();
	const value = currentPath.includes(mopersPath);
	const object = value ? { from_mopers: true } : {};
	return returnObject ? object : value;
};

export const getCurrentPath = () => window.location.pathname.replace('/app/', '');

export const markText = (title, search) => {

	if (!hasValue(title) || !hasValue(search)) {
		return (
			<p className='first-letter'>
				{title}
			</p>
		);
	}


	const original = title;
	const searchSize = size(search);

	title = replaceLetter(title);
	search = replaceLetter(search);

	const start = title.indexOf(search);

	if (start !== -1) {
		return (
			<span className='first-letter'>
				{original.substring(0, start)}
				<pre className={"markText"}>
					{original.substring(start, start + searchSize)}
				</pre>
				{original.substring(start + searchSize)}
			</span>
		);
	}

	return original;
};

export const isSelected = (itemBreads, currentBreads) => {
	if (!hasValue(itemBreads) || !hasValue(currentBreads)) {
		return "";
	}
	const breads = currentBreads.slice(0, size(itemBreads));
	return isEqual(breads, itemBreads) ? "selected" : "";
};

export const withLayout = {
	component: motion.div,
	layout: true,
};