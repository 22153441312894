

const payrollsIcons = {
	alimony: {
		allowedTag: "Alimony",
		Icon: "./Alimony.svg",
	},
	loansandpaids: {
		allowedTag: "Loans-And-Paids",
		Icon: "./LoansAndPaids.svg",
	},
	payrolldispersion: {
		allowedTag: "Payroll-Dispersion",
		Icon: "./PayrollDispersion.svg",
	},
	payrollkardex: {
		allowedTag: "Payroll-Kardex",
		Icon: "./PayrollKardex.svg",
	},
	periodtotals: {
		allowedTag: "Period-Totals",
		Icon: "./PeriodTotals.svg",
	},
	scheduledconcepts: {
		allowedTag: "Scheduled-Concepts",
		Icon: "./ProgramedConcepts.svg",
	},
	voucherdispersion: {
		allowedTag: "Voucher-Dispersion",
		Icon: "./VoucherDispersion.svg",
	},
	provisionpolicy: {
		allowedTag: "Provision-Policy",
		Icon: "./ProvisionPolicy.svg",
	},
	payrollpolicy: {
		allowedTag: "Payroll-Policy",
		Icon: "./PayrollPolicy.svg",
	},
	lock: {
		allowedTag: "Lock",
		Icon: "./Lock.svg",
	},
	unlock: {
		allowedTag: "Unlock",
		Icon: "./Unlock.svg",
	},
	payrolldelete: {
		allowedTag: "Payroll-Delete",
		Icon: "./PayrollDelete.svg",
	},
	reset: {
		allowedTag: "Reset",
		Icon: "./Reset.svg",
	},
	updatepaytype: {
		allowedTag: "Update-Pay-Type",
		Icon: "./UpdatePayType.svg",
	},
};

export default payrollsIcons;