/* libraries */
import PropTypes from "prop-types";
import React, { lazy, memo, Suspense } from 'react';
/* kendo ui */
import { valueOrOption } from '../../general/GeneralUtilities';
const StatusColumn = lazy(() => import('./StatusColumn'));
const ChipCell = lazy(() => import('./ChipCell'));
const BasicCell = lazy(() => import('./BasicCell'));

/**
 * Es un itermediario para las celdas finales, todas la propiedades son basadas en las celdas de kendo react
 * Item de referencia para los ejemplos {
		key: 1,
		name : "branch",
		Zone : { key: "a" name : "zone a" , }
	}
 * @param {object} params:  : está propiedad define que formato sera usado para mostrar los valores de la celda
 * @param {string} params.format:  : está propiedad define que formato sera usado para mostrar los valores de la celda

 *	 ejemplos:
	** ? "date" : usado para mostrar campos de fecha
	** ? "currency" : usado para mostrar campos dinero
	** ? "keyName" : usado para mostrar campos con el formato "Key - name" --> eje: "1 - branch"
	** Mas de estos ejemplos se pueden encontrar en la función getValue de este mismo documento
	---------------------------------------------------------------------------------------------
	** Si se pasa un formato como el siguiente "string|string" : (Util para campos onjeto) se spliteara usando el primero para definir el formato
		y el segundo para definir el campo que sera tomado si es diferente al field, como el siguiente emjemplo
	*? "keyName|Zone" :dara como resultado "a - Zone a"

**/
const BaseCell = memo(({
	format,
	...others
}) => {

	const splitformat = (format || "").split("|");
	const cellType = valueOrOption(splitformat[0], "basic");
	const DynamicComponent = {
		"chip": ChipCell,
		"status": StatusColumn,
	}[cellType] ?? BasicCell;

	return (
		<Suspense fallback={<td></td>}>
			<DynamicComponent {...others} splitformat={splitformat} />
		</Suspense>
	);
});

BaseCell.displayName = "BaseCell";
BaseCell.propTypes = {
	format: PropTypes.any
};

export default BaseCell;






