import React from "react";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "@mui/material";
import { gridSkeletonProps } from "../../navigation/components/userSection/ChangePassword/constants";

const commonCardStyle = {
    ...gridSkeletonProps.style,
    height: "10rem"
};

function ContentSkeleton() {


    return (
        <Grid container spacing={2}>
            <Grid size={6}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid container size={12}>
                <Grid size={6}>
                    <Skeleton
                        {...gridSkeletonProps}
                        style={commonCardStyle}
                    />
                </Grid>
                <Grid size={6}>
                    <Skeleton
                        {...gridSkeletonProps}
                        style={commonCardStyle}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContentSkeleton;