import React from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ItemIcon } from '../Search/NavItem';
import CustomTooltip from '../../../../Templates/CustomTooltip';

const FixedWindow = ({
    onClick,
    show,
    path = "",
    location = ""
}) => {

    const { t } = useTranslation();

    if (!show || location.includes(path)) {
        return null;
    }

    return (
        <CustomTooltip title={t("fixed-window")} className="fixed-window menu-item-tooltip" placement="top">
            <NavLink
                to={path}
                onClick={onClick}
            >
                <ItemIcon icon={"pushpin"} />
            </NavLink>
        </CustomTooltip>
    );
};

export default FixedWindow;