import React from "react";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "@mui/material";
import { gridSkeletonProps } from "../../navigation/components/userSection/ChangePassword/constants";

function ActionsSkeleton() {


    return (
        <Grid
            container
            spacing={2}
            direction="row"
            style={{
                justifyContent: "flex-end"
            }}
        >
            <Grid size={2}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid size={2}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
        </Grid>
    );
};

export default ActionsSkeleton;