import React from "react";
import PropTypes from "prop-types";
import { isArray } from "lodash";

function ChangeValue({
    value,
    className
}) {

    if (!isArray(value)) {
        return (
            <div className={`change ${className}`}>
                {value.toString()}
            </div>
        );
    }

    return value.map((item, index) => (
        <div key={index} className={`change ${className}`}>
            {item.toString()}
        </div>
    ));
};

export default ChangeValue;

ChangeValue.propTypes = {
    className: PropTypes.any,
    value: PropTypes.any
};