import * as yup from 'yup';
import { t } from 'i18next';
import arrayValidation from "../../../../general/@components/validations/array";
import { stringValidation } from "../../../../general/@components/validations/stringValidation";
import { previousDate } from './utilities';
import { monthLabel } from '../../../../general/@components/Inputs/Dates/utilities';

export const ValidationSchema = yup.object().shape({
    year: stringValidation({ required: true }),
    month: stringValidation({ required: true }),
    employer_registrations: arrayValidation({ required: true, min: 1 }),
});

export const initValues = {
    type: '1',
    ...previousDate(false, true),
    employer_registrations: []
};

export const liquidationViews = [
    { id: '1', name: `${t("liquidation")} ${t("monthly")}` },
    { id: '2', name: `${t("liquidation")} ${t("bimonthly")}` }
];

export const months = [
    { label: `${monthLabel(1)} - ${monthLabel(2)}`, value: "1,2", send: 1 },
    { label: `${monthLabel(3)} - ${monthLabel(4)}`, value: "3,4", send: 2 },
    { label: `${monthLabel(5)} - ${monthLabel(6)}`, value: "5,6", send: 3 },
    { label: `${monthLabel(7)} - ${monthLabel(8)}`, value: "7,8", send: 4 },
    { label: `${monthLabel(9)} - ${monthLabel(10)}`, value: "9,10", send: 5 },
    { label: `${monthLabel(11)} - ${monthLabel(12)}`, value: "11,12", send: 6 },
];

export const downloadLiquidationReport = async (taskID) => {
    const date = new Date(current_year(), reportMonth - 1, 1);
    const month = format(date, 'MMMM', { locale: es });

    const bimonthly = months?.find(element => element?.send === parseInt(reportBiMonthly))?.label;

    try {
        let response = await socketsApi.downloadReport(taskID);
        FileSaver.saveAs(
            response,
            `${t("liquidation")} ${t(reportType)} ${reportType === 'monthly' ? upperFirst(month) : bimonthly} ${reportYear} ${reportPatronalRegistration} ${format(
                new Date(),
                "yyyy-MM-dd hh:mm:ss"
            )}`
        );
    } catch (error) {
        return error;
    }
};