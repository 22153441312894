import axios from "axios";
import { currentUserInfo } from "../../../../../requests/user-store";
import { config as cloudConfig, getPublicApi } from "../../../../../../services/config";
import useTokenStore from "./useTokenStore";
// import { store } from "../../../../../../../../store";

const AHD_DOMAIN = process.env.HELPDESK_SERVER;

const BASE_DOMAIN = `${AHD_DOMAIN}api/`;

function HelpdeskAPIS() {
    const userEmail = () => currentUserInfo()?.user_info?.email;

    const token = () => useTokenStore.getState()?.token;

    // const tenantDomain = () => store.getState()?.tenant?.current?.slug;

    const getUrl = (url) => BASE_DOMAIN + url;

    const getCloudUrl = (url) => `${getPublicApi()}/${url}/`;

    const config = (params = {}, data = {}, isFormData = false) => {
        const { signal } = params;
        delete params.signal;
        return {
            signal,
            params,
            data,
            headers: {
                'Authorization': `Bearer ${token()}`,
                'Accept-Language': 'es-mx',
                ...(isFormData ? { 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW' } : {})
                // 'Django-Timezone': currentTimezone(),
            }
        };
    };

    const getCounter = () => {
        const url = getUrl('cloud/tickets');
        const finalParams = {
            email: userEmail(),
        };
        return controller.get(url, config(finalParams));
    };

    const getTickets = () => {
        const url = getUrl('cloud/tickets');
        const finalParams = {
            email: userEmail(),
            copied: true,
            contact: true,
        };
        return controller.get(url, config(finalParams));
    };

    const getAccessToken = () => {
        const url = getCloudUrl('helpdesk-login');
        return controller.get(url, cloudConfig());
    };

    const getTicketDetails = (id) => {
        const url = getUrl(`v2/tickets/${id}`);
        const finalParams = {
            email: userEmail(),
        };
        return controller.get(url, config(finalParams));
    };

    const createTicket = (payload = {}) => {
        const url = getUrl('issues');
        const finalParams = {
            // office_key: tenantDomain(),
            email: userEmail(),
            ...payload,
        };
        return controller.post(url, finalParams, config({}, {}, true));
    };

    const postMessage = (id, payload = {}) => {
        const url = getUrl(`tickets/${id}/comments`);
        const finalParams = {
            // office_key: tenantDomain(),
            email: userEmail(),
            ...payload,
        };
        return controller.post(url, finalParams, config({}, {}, !!payload.file));
    };

    const postFileMessage = (id, payload = {}) => {
        const url = getUrl(`tickets/${id}/upload`);
        const finalParams = {
            // office_key: tenantDomain(),
            email: userEmail(),
            public: 1,
            ...payload,
        };
        return controller.post(url, finalParams, config({}, {}, true));
    };

    const getFilePreview = (api) => {
        if (!api) return api;
        return controller.get(api, config());
    };

    const downloadFile = (filename) => {
        if (!filename) return;
        const url = getUrl(`download/${filename}`);
        const headers = new Headers(config().headers);
        return fetch(url, { headers })
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    return Promise.reject("error 404");
                }
            })
            .then((blob) => blob);
    };

    const closeTicket = (id, payload = {}) => {
        const url = getUrl(`client/tickets/${id}/close`);
        const finalParams = {
            email: userEmail(),
            ...payload,
        };
        return controller.post(url, finalParams, config({}, {}, true));
    }

    return {
        getCounter,
        getTickets,
        createTicket,
        closeTicket,
        getFilePreview,
        getTicketDetails,
        getAccessToken,
        postFileMessage,
        postMessage,
        downloadFile,
    };
};

const helpdeskAPIS = HelpdeskAPIS();

export default helpdeskAPIS;

export const AHD_MISSING_ENV_MSG = "ENV HELPDESK SERVER MISSING";

export const AHD_MISSING_ENV_KEY = "AHD_MISSING";

const controller = {
    performRequest: async (method, url, data, config) => {
        if (!AHD_DOMAIN) {
            throw CustomError(AHD_MISSING_ENV_MSG, AHD_MISSING_ENV_KEY);
        }
        return method(url, data, config).then(res => res.data);
    },
    get: async (url, data, config) => controller.performRequest(axios.get, url, data, config),
    post: async (url, data, config) => controller.performRequest(axios.post, url, data, config),
};

const CustomError = (message, code = "CUSTOM_ERROR") => ({
    message,
    code,
});