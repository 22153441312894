
const clockItems = [
	//* CONTROL DE ACCESO *//
	{
		id: 'access-control@clocks',
		type: 'item',
		icon: 'clock',
		componentElm: 'modules/clock/Clock',
	},
	{
		id: 'access-control@clock-workers',
		type: 'item',
		icon: 'assign-clocks',
		componentElm: 'modules/clock/ClockWorker',
	},
];

export default clockItems;