
const imssItems = [
	{
		id: 'modules@affiliate-report',
		type: 'item',
		icon: 'idsetransfer',
		componentElm: 'modules/imss/AffiliateReport',
	},
	{
		id: 'modules@idse-audit',
		type: 'item',
		icon: 'idseaudit',
		componentElm: 'modules/imss/IDSEAudit',
	},
	{
		id: 'modules@liquidation',
		type: 'item',
		icon: 'settlement',
		componentElm: 'modules/imss/Liquidation',
	},
	{
		id: 'modules@EMA-EBA',
		type: 'item',
		icon: 'emaeba',
		componentElm: 'modules/imss/EMA-EBA',
	},
	{
		id: 'modules@confirmation',
		type: 'item',
		icon: 'idseconfirmation',
		componentElm: 'modules/imss/IDSEConfirmation',
	},
	{
		id: 'modules@transfer',
		type: 'item',
		componentElm: 'modules/imss/SUAtransfer',
	},
	{
		id: 'modules@monthly-settlement-comparison',
		type: 'item',
		icon: 'compare',
		componentElm: 'modules/imss/MSettlementComparasion',
	},
	{
		id: 'modules@work-risk-history',
		type: 'item',
		icon: 'workriskhistory',
		componentElm: 'modules/imss/WorkRiskHistory',
	},
	{
		id: 'modules@imss-settings',
		type: 'item',
		componentElm: 'modules/imss/SettingsIMSS',
	},
	//En construccion
	{
		id: 'modules@imss-catalogs',
		type: 'item',
		inConstruction: true,
		componentElm: true,
	},
	{
		id: 'modules@imss-settings',
		title: 'settings',
		type: 'item',
		inConstruction: true,
		componentElm: true,
	},
];

export default imssItems;