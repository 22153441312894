

const attendanceAndMopers = {
	applybreaks: {
		allowedTag: "Apply-Breaks",
		Icon: "./apply-breaks-coffee-cup.svg",
	},
	applyfaults: {
		allowedTag: "Apply-Faults",
		Icon: "./apply-faults.svg",
	},
	applyholidays: {
		allowedTag: "Apply-Holidays",
		Icon: "./apply-holidays.svg",
	},
	assistadjustment: {
		allowedTag: "Assist-Adjustment",
		Icon: "./assist-adjustment.svg",
	},
	attendancesign: {
		allowedTag: "Attendance-Sign",
		Icon: "./attendance-sign.svg",
	},
	compactattendancelist: {
		allowedTag: "Compact-Attendance-List",
		Icon: "./compact-attendance-list.svg",
	},
	detailattendancelist: {
		allowedTag: "Detail-Attendance-List",
		Icon: "./detail-attendance-list.svg",
	},
	kioscoprerequest: {
		allowedTag: "Kiosco-Pre-Request",
		Icon: "./kiosc-pre-request.svg",
	},
	printattendance: {
		allowedTag: "Print-Attendance",
		Icon: "./print-attendance.svg",
	},
	vacation: {
		allowedTag: "Vacation",
		Icon: "./vacation.svg",
	},
	graphicsignatures: {
		allowedTag: "Graphic-Signatures",
		Icon: "./GraphicSignatures.svg",
	},
	moperssign: {
		allowedTag: "Mopers-Sign",
		Icon: "./MopersSign.svg",
	},
	signaturesbyworker: {
		allowedTag: "Signatures-By-Worker",
		Icon: "./SignaturesByWorker.svg",
	},
	workgroups: {
		allowedTag: "Workgroups",
		Icon: "./Workgroups.svg",
	},
	stats: {
		allowedTag: "Stats",
		Icon: "./stats.svg",
	},
	absenteeisms: {
		allowedTag: "Absenteeisms",
		Icon: "./absenteeisms.svg",
	},
	congoce: {
		allowedTag: "Con-Goce",
		Icon: "./con_goce.svg",
	},
	singoce: {
		allowedTag: "Sin-Goce",
		Icon: "./sin_goce.svg",
	},
	adjustexistent: {
		allowedTag: "Adjust-Existent",
		Icon: "./adjust_exist.svg",
	},
	lackregistration: {
		allowedTag: "Lack-Registration",
		Icon: "./lack_registration.svg",
	},
	scheduleincidence: {
		allowedTag: "Schedule-Incidence",
		Icon: "./schedule_incidence.svg",
	},
	timedelay: {
		allowedTag: "Time-Delay",
		Icon: "./time_delay.svg",
	},
	workhoursgreater: {
		allowedTag: "Work-Hours-Greater",
		Icon: "./work_hours_greater.svg",
	},
	workhourssmaller: {
		allowedTag: "Work-Hours-Smaller",
		Icon: "./work_hours_smaller.svg",
	},
	leftarrow: {
		allowedTag: "Left-Arrow",
		Icon: "./left_arrow.svg",
	},
	rightarrow: {
		allowedTag: "Right-Arrow",
		Icon: "./right_arrow.svg",
	},
	inficon: {
		allowedTag: "Inf-Icon",
		Icon: "./inf_icon.svg",
	},
	close: {
		allowedTag: "Close",
		Icon: "./close.svg",
	},
	completeturn: {
		allowedTag: "Complete-Turn",
		Icon: "./complete-turn.svg",
	},
	newcheck: {
		allowedTag: "New-Check",
		Icon: "./new-check.svg",
	},
	requesthours: {
		allowedTag: "Request-Hours",
		Icon: "./request-hours.svg",
	},
	attendancelist: {
		allowedTag: "Attendance-List",
		Icon: "./attendance-list.svg",
	},
	glosary: {
		allowedTag: "Glosary",
		Icon: "./glosary.svg",
	},
	genericincapacity: {
		allowedTag: "Generic-Incapacity",
		Icon: "./generic_incapacity.svg",
	},
	decline: {
		allowedTag: "Decline",
		Icon: "./decline.svg",
	},
	entry: {
		allowedTag: "Entry",
		Icon: "./entry.svg",
	},
	exit: {
		allowedTag: "Exit",
		Icon: "./exit.svg",
	},
	daycalculate: {
		allowedTag: "Day-Calculate",
		Icon: "./day-calculation.svg",
	},
	requestovertime: {
		allowedTag: "Request-Overtime",
		Icon: "./ov_request_icon.svg",
	},
	dayrest: {
		allowedTag: "Day-Rest",
		Icon: "./rest_day.svg",
	},
	calculation: {
		allowedTag: "Calculation",
		Icon: "./calculation.svg",
	},
	rounding: {
		allowedTag: "Rounding",
		Icon: "./rounding.svg",
	},
	slimarrow: {
		allowedTag: "Slim-Arrow",
		Icon: "./slimarrow.svg",
	},
};

export default attendanceAndMopers;