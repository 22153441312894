import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { isString } from "lodash";
import { t } from "i18next";
import { getTheme } from "../Sockets/utilities";
import { getCurrentWindow } from "./trigger-notification-socket";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

/**
 * @typedef {Object} NotificationProps
 * @param {string} title - Texto a mostrar como titulo
 * @property {string} message - Texto a mostrar como mensaje.
 * @property {?boolean} [showTitle] - Define si mostrar el titulo superior.
 * @property {"success"|"info"|"warning"|"error"} [type="success"] - Tipo de notificación.
 * @property {?boolean|number} [autoClose=3000] - Define el tiempo de delay para ocultar la notificación, en false para no ocultar automáticamente.
 */

/**
 * @param {object} param
 * @param {string} param.title - Texto a mostrar como titulo
 * @param {string} param.message - Texto a mostrar como mensaje
 * @param {?boolean} param.showTitle - Define si mostrar el titulo superior
 * @param {?"success"|"info"|"warning"|"error"} param.type - Tipo de notificación
 * @param {?boolean|number} param.autoClose - Define el tiempo de delay para ocultar la notificación, en false para no ocultar automáticamente
 */
export const showNotification = ({
    title,
    message,
    subtitle,
    showTitle,
    type = 'success',
    autoClose = 3000,
    ...others
} = {}, props = {}) => {

    const moreProps = {
        type,
        autoClose,
        theme: getTheme(),
        ...others,
        ...props,
    };

    if (!subtitle) {
        subtitle = getCurrentWindow()?.title;
    }

    toast(
        <NotificationMessage
            title={title}
            subtitle={subtitle}
            message={message}
            showTitle={showTitle}
        />,
        moreProps
    );
}

const getProps = (props = {}, type = null) => {

    const title = {
        success: t('success-general-title'),
        warning: t('warning-general-title'),
        info: t('general-information'),
        error: t('error-general-title')
    }[type];

    const fProps = isString(props) ? { message: props } : props;
    const allProps = { title, ...fProps };

    return allProps;
};

/**
 * Muestra una notificación de éxito.
 * @param {NotificationProps} [props={}] - Propiedades para la notificación.
 * @param {string} props.title - Texto a mostrar como titulo
 * @param {string} props.message - Texto a mostrar como mensaje.
 * @param {?boolean} [props.showTitle] - Define si mostrar el titulo superior.
 * @param {?boolean|number} [props.autoClose=3000] - Define el tiempo de delay para ocultar la notificación, en false para no ocultar automáticamente.
 */
export const showSuccessNotification = (props = {}, moreProps = {}) => showNotification({ ...getProps(props, 'success'), ...moreProps, type: 'success' });
/**
 * Muestra una notificación de advertencia.
 * @param {NotificationProps} [props={}] - Propiedades para la notificación.
 * @param {string} props.title - Texto a mostrar como titulo
 * @param {string} props.message - Texto a mostrar como mensaje.
 * @param {?boolean} [props.showTitle] - Define si mostrar el titulo superior.
 * @param {?boolean|number} [props.autoClose=3000] - Define el tiempo de delay para ocultar la notificación, en false para no ocultar automáticamente.
 */
export const showWarningNotification = (props = {}, moreProps = {}) => showNotification({ ...getProps(props, 'warning'), ...moreProps, type: 'warning' });
/**
 * Muestra una notificación informativa.
 * @param {NotificationProps} [props={}] - Propiedades para la notificación.
 * @param {string} props.title - Texto a mostrar como titulo
 * @param {string} props.message - Texto a mostrar como mensaje.
 * @param {?boolean} [props.showTitle] - Define si mostrar el titulo superior.
 * @param {?boolean|number} [props.autoClose=3000] - Define el tiempo de delay para ocultar la notificación, en false para no ocultar automáticamente.
 */
export const showInfoNotification = (props = {}, moreProps = {}) => showNotification({ ...getProps(props, 'info'), ...moreProps, type: 'info' });
/**
 * Muestra una notificación de error.
 * @param {NotificationProps} [props={}] - Propiedades para la notificación.
 * @param {string} props.title - Texto a mostrar como titulo
 * @param {string} props.message - Texto a mostrar como mensaje.
 * @param {?boolean} [props.showTitle] - Define si mostrar el titulo superior.
 * @param {?boolean|number} [props.autoClose=3000] - Define el tiempo de delay para ocultar la notificación, en false para no ocultar automáticamente.
 */
export const showErrorNotification = (props = {}, moreProps = {}) => showNotification({ ...getProps(props, 'error'), ...moreProps, type: 'error' });

export const NotificationMessage = ({
    title,
    message,
    subtitle,
    showTitle = true,
    subProcessMessage = null
}) => {
    const { t } = useTranslation();

    const date = format(new Date(), 'dd/MM/yyyy HH:mm');

    return (
        <div className="notification-custom-body-message">
            <div>
                {showTitle && <span className="custom-notification-title">{title ?? t('success-general-title')}</span>}
                {!!subtitle && <span className="custom-notification-subtitle">{`${subtitle} | ${date}`}</span>}
            </div>
            <span className="custom-notification-content">{message}</span>
            {subProcessMessage}
        </div>
    );
};

NotificationMessage.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    showTitle: PropTypes.bool,
    subProcessMessage: PropTypes.any,
    subtitle: PropTypes.string,
    title: PropTypes.string
};