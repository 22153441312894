import { create } from "zustand";

const useNotificationsStore = create((set) => ({
    openHD: false,
    openSubHD: false,
    openNoti: false,
    setOpenHD: (value) => set({ openHD: value }),
    setOpenSubHD: (value) => set({ openSubHD: value }),
    setOpenNoti: (value) => set({ openNoti: value }),
}));

export default useNotificationsStore;