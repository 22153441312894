import React, { Suspense, useMemo } from 'react';
import PropTypes from "prop-types";
import icons from "../../../../assets/scss/custom-icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasValue } from '../general/GeneralUtilities';
import { isFunction, isString, last } from 'lodash';
import DynamicImport from "../navigation/components/windows/DynamicImport";

const allIcons = import.meta.glob("../../../../assets/scss/custom-icons/**/**/*.svg");
const allPaths = Object.keys(allIcons || {});

/**
 * Componente de uso rapido para los iconos svg
 * @param {object} params
 * @param {string | object} params.icon -  el nombre del icon que se va a usar dependiendo del nombre o objecto para los fontawesomes se mostrara uno u otro
 * @param {?string} params.className -  Custom classname para el icono
 * @returns {span}
 */

const IconUse = ({
	icon = null,
	className = "",
	style = {},
	onClick = null,
	...others
}) => {

	if (!hasValue(icon)) { return null; }

	if (isFunction(icon?.render)) {
		const FinalIcon = icon;
		return (
			<FinalIcon
				className={`custom-abr-icon ${className}`}
				style={style}
				onClick={onClick}
			/>
		);
	}

	if (isString(icon)) {
		icon = icon?.replace(/[-_]/g, "").toLowerCase();
		if (hasValue(icons?.[icon])) {
			return (
				<Icon
					icon={icon}
					className={`custom-abr-icon ${className}`}
					style={style}
					onClick={onClick}
				/>
			);
		}
	}

	return (
		<FontAwesomeIcon
			icon={icon}
			className={className}
			onClick={onClick}
			style={style}
			{...others}
		/>
	);
};

IconUse.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.any,
	onClick: PropTypes.func,
	style: PropTypes.object
};

export default React.memo(IconUse);

const Icon = ({ icon, ...others }) => {
	const foundIcon = icons?.[icon]?.Icon ?? icons?.[icon];
	const sliced = foundIcon?.slice(2, foundIcon?.length);
	const foundPath = findIconPath(sliced);

	const SvgIcon = useMemo(() => {
		return foundPath ? DynamicImport(allIcons, foundPath) : foundIcon;
	}, [foundPath]);

	if (!hasValue(SvgIcon)) { return null; };

	return (
		<Suspense fallback={<div className='custom-abr-icon-skeleton' />}>
			<SvgIcon {...others} />
		</Suspense>
	);
};

Icon.propTypes = {
	icon: PropTypes.any
};

const findIconPath = (iconName) => {
	return allPaths.find(path => {
		const treatPath = last(path.split('/'));
		return treatPath === iconName;
	});
}