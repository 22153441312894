import React, { useState, useEffect, useRef } from "react";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import {
	format,
	parse
} from 'date-fns';
import { Popup } from "@progress/kendo-react-popup";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import {
	dateTimeFormat,
	dateValue,
	defaultDate,
	defaultMask,
	makeMask,
	rules,
	validDate,
	validTime,
} from "./utilities";
import CustomCalendar from './CustomCalendar';
import { KendoLabelContainer } from '../../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../../uiDesign/sizesUtilities';
import { useEditorIDHelper } from '../../general/GeneralUtilities';

/**
 * Input de fechas para el sistema, Estab basado en el Kendo datePicker asi que puede recibir todos los atributos de este
 * @param {object} params
 * @param {?string} params.id - Es el id que sera usado por el input
 * @param {?string} params.size - El tamaño del input
 * @param {?string} params.label - La etiqueta que se muestra en el input
 * @param {?string} params.name - El nombre del input
 * @param {?date} params.min - La fehca mínima que se podra seleccionar con el input
 * @param {?date} params.max - La fecha máxima que se podra seleccionar con el input
 * @param {string|date} params.value - El valor a mostrar en el input, solo puede ser un string cuando se maneja junto con value format
 * @param {string} params.valueFormat - El formato que se maneja tras bambalinas para la fecha
 * @param {string} params.outFormat - El formato que se maneja tras bambalinas para la fecha
 * @param {function} params.onChange - La funcion que se ejecuta al cambiar el valor del input
 * @param {?boolean} params.sideLabel - Indica si se va a manejar el label del input a un lado por default false, flotante
 * @returns
 */
const KendoDateTime = ({
	id,
	label,
	name,
	min,
	max,
	minTime,
	maxTime,
	value,
	valueFormat,
	outFormat,
	onChange,
	sideLabel = false,
	...others
}) => {


	const editorId = useEditorIDHelper(id, "dateTimeP");

	const { size, className } = kendoSizeClasses(others);

	const inputValue = dateValue(value, valueFormat);
	const [mask, setMask] = useState(`${defaultMask()} HH:MM`);
	const actualValue = useRef(value);

	const change = e => {

		if (!e?.syntheticEvent && e?.nativeEvent?.inputType === "insertText") {
			return;
		}
		e.targetValue = e.syntheticEvent?.value;
		setMask(e.syntheticEvent?.value);

		if (e.nativeEvent) {
			const parent = e.target._element.parentElement;
			let classN = parent.getAttribute("class").replace("k-focus", "");
			parent.setAttribute("class", classN);
		}

		let newValue = e.value;
		actualValue.current = newValue;
		if (outFormat && newValue) {
			newValue = format(newValue, outFormat);
			e = {
				type: "change",
				value: newValue,
				targetValue: e.targetValue,
				target: { value: newValue }
			};
			return onChange(e);
		}
		e.type = "change";

		return onChange(e);
	};

	useEffect(() => {
		if (actualValue.current !== value) {
			setMask(makeMask(value, "datetime"));
			actualValue.current = value;
		}
	}, [value]);

	return <KendoLabelContainer
		label={label}
		editorId={editorId}
		editorValue={true}
		className={"custom-floating-label"}
		sideLabel={sideLabel}
	>
		<DateTimePicker
			{...others}
			className={className}
			value={inputValue}
			id={editorId}
			name={name ?? editorId}
			onChange={change}
			format={dateTimeFormat}
			// format={"dd/MM/yyyy hh:mm a"}
			min={defaultDate(min, "1920/01/01 12:00", valueFormat)}
			max={defaultDate(max, "2099/12/31 12:00", valueFormat)}
			minTime={defaultDate(minTime, "2000/01/01 00:00", valueFormat)}
			maxTime={defaultDate(maxTime, "2000/01/01 23:59", valueFormat)}
			size={size}
			formatPlaceholder={{ mask: mask, maskSize: size }}
			calendar={CustomCalendar}
			dateInput={MaskedTexForDateInput}
			popup={CustomPopup}
		/>
	</KendoLabelContainer>;
};

export default KendoDateTime;

const MaskedTexForDateInput = ({
	value,
	onChange,
	name,
	formatPlaceholder,
	min,
	max,
	minTime,
	maxTime,
	...others
}) => {

	const { mask, maskSize } = formatPlaceholder;
	const [maskValue, setMaskValue] = useState(`${defaultMask()} HH:MM`);

	useEffect(() => {
		if (value !== null) {
			const formated = format(value, dateTimeFormat);
			if (formated !== maskValue) {
				setMaskValue(formated);
			}
		}
		// eslint-disable-next-line
	}, [value, mask]);

	const handleChange = (e) => {
		if (maskValue === e.target.value) {
			return;
		}
		const eValue = e.value || "";
		const parts = eValue.split(" ");
		const dateParts = parts[0].split("/");
		const timeParts = parts[1].split(":");
		const values = validDate({
			day: dateParts[0],
			month: dateParts[1],
			year: dateParts[2],
			min: min,
			max: max,
		});
		const time = validTime({
			hour: timeParts[0],
			minutes: timeParts[1],
			min: minTime,
			max: maxTime,
		});
		const maskValueTemp = `${values[1]} ${time[1]}`;
		setMaskValue(maskValueTemp);
		onChange({
			value: values[0] && time[0] ? parse(maskValueTemp, dateTimeFormat, new Date()) : null,
			syntheticEvent: e,
			target: this,
		});
	};

	return (
		<MaskedTextBox
			{...others}
			value={maskValue}
			mask="dD/mM/yYYY hH:nN"
			rules={rules}
			name={name}
			onChange={handleChange}
			size={maskSize}
		/>
	);
};

const CustomPopup = ({
	className,
	...others
}) => (
	<Popup
		{...others}
		className={`${className ?? ""} customPopup datetime-container`}
	/>
);