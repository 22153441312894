import React, { Suspense } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useAdvanceFilters from '../useAdvanceFilters';
import InputsRenderSkeleton from '../skeletons/InputsRender';
import ButtonActionsSkeleton from '../skeletons/ButtonActions';
import { Transition } from '../ConstantsAndUtilities/transitions';
import InputsRender from './InputsRender';
import Buttons from './Buttons';
import Title from './Title';

const Content = () => {

	const {
		open,
		onClose,
	} = useAdvanceFilters();

	return (
		<Dialog
			open={open}
			slots={{ transition: Transition }}
			onClose={onClose}
			aria-describedby="advancefilter-slide-description"
			className="advanceFilters"
		>
			<Title />
			<DialogContent >
				<Suspense fallback={<InputsRenderSkeleton />}>
					<InputsRender />
				</Suspense>
			</DialogContent>
			<Suspense fallback={<ButtonActionsSkeleton />}>
				<Buttons />
			</Suspense>
		</Dialog>
	);
};

export default Content;