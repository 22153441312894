import React, { lazy, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showSuccessNotification } from "../../../../Notifications";
import { resolveError } from "../../../../requests/resolve-error";
import { successUpdated } from "../../../../Notifications/notification-messages";
import { lockedWindow, unlockedWindow } from "../../../../../../../store/actions";
import { usersApi } from "../../../../../services/administrator";
import { stringValidation } from "../../../../validations/stringValidation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import DialogForm from "../../../../form/Dialog";
import ContentSkeleton from "./ContentSkeleton";
import { toggleChangePassword } from "../../../../../../../store/triggers/navigate";

const FormContent = lazy(() => import("./FormContent"));

function ChangePasswordForm() {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { change_password_open } = useSelector(state => state.navigate);

	const onClose = () => dispatch(toggleChangePassword());

	const defaultValues = {
		old_password: "",
		password1: "",
		password2: "",
	};

	const ValidationSchema = yup.object().shape({
		old_password: stringValidation({ required: true, min: 8, max: 50 }),
		password1: stringValidation({ required: true, min: 8, max: 50 }),
		password2: stringValidation({ required: true, min: 8, max: 50 })
			.oneOf([yup.ref("password1"), null], t("password-not-match")),
	}, [
		/* evita el error de dependencias cliclicas poniendo un arrary de los pares que se validan */
		["password1", "password2"],
	]);

	const {
		reset,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(ValidationSchema),
		mode: "onChange",
		defaultValues: defaultValues
	});

	useEffect(() => {
		if (change_password_open) {
			reset(defaultValues);
		}
		//eslint-disable-next-line
	}, [change_password_open]);

	const onSubmit = async (data) => {
		dispatch(lockedWindow());
		await usersApi.changePassword(data)
			.then(() => {
				onClose();
				showSuccessNotification(successUpdated());
			})
			.catch((error) => {
				resolveError(error);
			})
			.finally(() => {
				dispatch(unlockedWindow());
			});
	};

	const currentPassword = useWatch({ control, name: 'old_password' });
	const newPassword = useWatch({ control, name: 'password1' });
	const confirmPassword = useWatch({ control, name: 'password2' });

	const validateFields = () => {
		if (!currentPassword || !newPassword || !confirmPassword) {
			return true;
		}
		return (Object.keys(errors).length > 0);
	};

	return (
		<DialogForm
			title={`${t("password")}`}
			open={change_password_open ?? false}
			isEdit={true}
			onClose={onClose}
			handleSubmit={handleSubmit}
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			maxWidth={"sm"}
			fieldsLabels={{
				"old_password": t("current-password"),
				"password1": t("new-password"),
				"password2": t("confirm-password"),
			}}
			disableSave={validateFields()}
		>
			<Suspense fallback={<ContentSkeleton />}>
				<FormContent control={control} />
			</Suspense>
		</DialogForm >
	);
};

export default ChangePasswordForm;