

const applicationsIcons = {
	training: {
		allowedTag: "Training",
		Icon: "./Capacitacion.svg",
	},
	contractsandformats: {
		allowedTag: "Contracts-And-Formats",
		Icon: "./ContractsAndFormats.svg",
	},
	fileexplorer: {
		allowedTag: "File-Explorer",
		Icon: "./FileExplorer.svg",
	},
	kiosk: {
		allowedTag: "Kiosk",
		Icon: "./Kiosco.svg",
	},
	kiosko: {
		allowedTag: "Kiosko",
		Icon: "./Kiosco.svg",
	},
	mopers: {
		allowedTag: "Mopers",
		Icon: "./Mopers.svg",
	},
	humanresources: {
		allowedTag: "Human-Resources",
		Icon: "./HumanResources.svg",
	},
	clock: {
		allowedTag: "Clock",
		Icon: "./Reloj.svg",
	},
	systems: {
		allowedTag: "Systems",
		Icon: "./Sistemas.svg",
	},
};

export default applicationsIcons;