//--project imports
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from 'i18next';
//--project imports
import "./app.scss";
import { setSearch } from "../../../store/actions";
import { logout } from "../../../core/general/@components/general/auth";
import locales from './locales';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';

import {
	IntlProvider,
	loadMessages,
	LocalizationProvider,
} from "@progress/kendo-react-intl";
import { load } from '@progress/kendo-intl';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es-MX/numbers.json";
import localCurrency from "cldr-numbers-full/main/es-MX/currencies.json";
import caGregorian from "cldr-dates-full/main/es-MX/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es-MX/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es-MX/timeZoneNames.json";
/* local languages */
import esMessages from "../../../core/general/language/es/kendoMessages.json";

/* Own components */
import Navigation from "../../../core/general/@components/navigation";
import AdvanceFilters from "../../../core/general/@components/filter/AdvanceFiltersV2";
import ConfirmNotification from "../../../core/general/@components/modal/dialog/ConfirmNotification";
import ErrorNotification from "../../../core/general/@components/modal/dialog/ErrorNotification";
import SuccessNotification from "../../../core/general/@components/modal/dialog/SuccessNotification";
import WarningNotification from "../../../core/general/@components/modal/dialog/WarningNotification";
import ImportNotification from "../../../core/general/@components/modal/dialog/ImportNotification";
import RemoveNotification from "../../../core/general/@components/modal/dialog/RemoveNotification";
import ControlledError from "../../../core/general/@components/modal/dialog/ControlledError";
import InfoNotification from '../../../core/general/@components/modal/dialog/InfoNotification';
import ChangePasswordForm from '../../../core/general/@components/navigation/components/userSection/ChangePassword/ChangePasswordForm';
import CustomNotification from "../../../core/general/@components/modal/dialog/CustomNotification";
import OptionNotification from '../../../core/general/@components/OptionNotification';
import ModalQuestionLogout from "../QuestionLogout";

load(
	likelySubtags,
	currencyData,
	weekData,
	localCurrency,
	numbers,
	caGregorian,
	dateFields,
	timeZoneNames
);

loadMessages(esMessages, "es-MX");

function minToMillSec(min) {
	return min * 60 * 1000;
}

const min = {
	0: 60,
	1: 5,
	2: 10,
	3: 15,
	4: 20,
};

function AdminLayout() {
	const dispatch = useDispatch();
	const [viewCount, setViewCount] = useState(false);
	const [modalQuestionLogout, setModalQuestionLogout] = useState(false);
	const inactivityTime = useSelector((state) => state.configuration.abrhil_package?.inactivity_time?.value || 0);

	const locale = locales[i18next.t("systemLanguage") === "es" ? 1 : 0];

	function onConfirm() {
		setViewCount(false);
		setModalQuestionLogout(false);
		message('setViewCount', true);
	}

	function showInactivityModal() {
		if (process.env.REACT_APP_ABR_MODE === "localhost" || process.env.REACT_APP_ABR_MODE === "development") return;
		dispatch(setSearch(null));
		setModalQuestionLogout(true);
		setViewCount(true);
	}


	const onMessage = (e) => {
		if (e === "setViewCount") {
			setModalQuestionLogout(false);
			setViewCount(false);
		}
	};

	const {
		message
	} = useIdleTimer({
		onIdle: showInactivityModal,
		onMessage,
		timeout: minToMillSec(min[inactivityTime]),
		crossTab: true,
		disabled: !inactivityTime,
		leaderElection: true,
		syncTimers: 200
	});

	return (
		<LocalizationProvider language={locale.language}>
			<IntlProvider locale={locale.locale}>
				<Navigation />
				{viewCount && <ForceLogout />}
				{modalQuestionLogout && <ModalQuestionLogout onConfirm={onConfirm} />}
				<AdvanceFilters />
				<SuccessNotification />
				<ErrorNotification />
				<WarningNotification />
				<ImportNotification />
				<ConfirmNotification />
				<RemoveNotification />
				<InfoNotification />
				<CustomNotification />
				<ControlledError />
				<OptionNotification />
				{/* <IconsViewer /> */}
				<ChangePasswordForm />
			</IntlProvider>
		</LocalizationProvider>
	);
}

export default AdminLayout;

function ForceLogout() {
	//Componente que forza la salida
	const handleOnId = () => {
		logout();
	};

	return (
		<IdleTimerProvider
			timeout={minToMillSec(1)} //tiempo de inactividad 1 min
			onIdle={handleOnId}
		/>
	);
}