import abrhilIcons from "./Abrhil";
import aplicationsIcons from "./Aplicaciones";
import humanResourcesIcons from "./HumanResources";
import attendanceAndMopersIcons from "./AttendanceAndMopers";
import imssIcons from "./Imss";
import kioskIcons from "./Kiosco";
import payrollsIcons from "./Payrolls";
import clocksIcons from "./Relojes";
import systemsIcons from "./Systems";
import toolsBarIcons from "./ToolsBar";
import TimbrasoftIcons from "./Timbrasoft";
import Courses from "./Courses";
import actionsIcons from "./Actions";
import fileIcons from "./Files";
import requisitionIcons from "./Requisitions";
import notificationIcons from './Notifications';
import outsideIcons from '../svg';
import aditionalsIcons from "./Aditionals";
import staffingIcons from './Staffing';

const icons = {
	...abrhilIcons,
	...aplicationsIcons,
	...humanResourcesIcons,
	...attendanceAndMopersIcons,
	...imssIcons,
	...kioskIcons,
	...payrollsIcons,
	...clocksIcons,
	...systemsIcons,
	...toolsBarIcons,
	...TimbrasoftIcons,
	...Courses,
	...actionsIcons,
	...fileIcons,
	...requisitionIcons,
	...notificationIcons,
	...outsideIcons,
	...aditionalsIcons,
	...staffingIcons,
	modules: {
		allowedTag: "Modules",
		Icon: "./Modules.svg",
	},
	catalog: {
		allowedTag: "Catalog",
		Icon: "./CatalogIcon.svg",
	},
	report: {
		allowedTag: "Report",
		Icon: "./ReportIcon.svg",
	},
	configicon: {
		allowedTag: "Config-icon",
		Icon: "./ConfigIcon.svg",
	},
	goto: {
		allowedTag: "Go-To",
		Icon: "./Ir.svg",
	},
	goback: {
		allowedTag: "Go-Back",
		Icon: "./Regresar.svg",
	},
	package: {
		allowedTag: "Package",
		Icon: "./Package.svg",
	},
};

export default icons;