import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import { parse, format, isValid } from 'date-fns';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
	backDateFormat,
	dateFormat,
	dateValue,
	defaultDate,
	defaultMask,
	makeMask,
	rules,
	validDate,
} from './utilities';
import CustomCalendar from './CustomCalendar';
import { conditional, hasValue, useEditorIDHelper, valueOrOption } from '../../general/GeneralUtilities';
import { KendoLabelContainer } from '../../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../../uiDesign/sizesUtilities';

/**
 * Input de fechas para el sistema, Estab basado en el Kendo datePicker asi que puede recibir todos los atributos de este
 * @param {object} params
 * @param {?string} params.id - Es el id que sera usado por el input
 * @param {?string} params.size - El tamaño del input
 * @param {?string} params.label - La etiqueta que se muestra en el input
 * @param {?string} params.name - El nombre del input
 * @param {?date} params.min - La fecha mínima que se podra seleccionar con el input
 * @param {?date} params.max - La fecha máxima que se podra seleccionar con el input
 * @param {string|date} params.value - El valor a mostrar en el input, solo puede ser un string cuando se maneja junto con value format
 * @param {string} params.valueFormat - El formato que se maneja tras bambalinas para la fecha
 * @param {string} params.outFormat - El formato que se maneja tras bambalinas para la fecha
 * @param {function} params.onChange - La funcion que se ejecuta al cambiar el valor del input
 * @param {?boolean} params.sideLabel - Indica si se va a manejar el label del input a un lado por default false, flotante
 * @param {?boolean} params.useBackFormats - Indica se para la entrada y salida de la valor del input se va a manejar el formato del backend
 * @returns
 */
const KendoDatePicker = ({
	id,
	label,
	name,
	min,
	max,
	value,
	highlight,
	valueFormat,
	outFormat,
	onChange,
	popupSettings = {},
	sideLabel = false,
	useBackFormats = false,
	...others
}) => {

	if (useBackFormats) {
		valueFormat = backDateFormat;
		outFormat = backDateFormat;
	}

	const editorId = useEditorIDHelper(id, "dateP");

	const { size, className } = kendoSizeClasses(others);
	value = !value ? null : value;

	const [mask, setMask] = useState(defaultMask());
	const actualValue = useRef(value);

	const change = e => {

		if (!e?.syntheticEvent && e?.nativeEvent?.inputType === "insertText") {
			return;
		}

		const maskValue = e.syntheticEvent?.value;

		if (maskValue) {
			e.targetValue = maskValue;
			setMask(maskValue);
		}

		const parent = e?.target?.element?.parentElement;
		if (parent) {
			let classN = parent.getAttribute("class").replace("k-focus", "");
			parent.setAttribute("class", classN);
		}

		const foundValue = e.value ?? e.target?.value;
		let newValue = conditional(isValid(foundValue), foundValue, null);
		actualValue.current = newValue;

		if (outFormat && newValue) {
			newValue = format(newValue, outFormat);
		}

		e = {
			value: newValue,
			dateValue: e.value,
			target: { value: newValue },
			targetValue: e.targetValue,
			type: "change"
		};

		return onChange(e);
	};

	useEffect(() => {
		if (actualValue.current !== value) {
			setMask(makeMask(value, "date"));
			actualValue.current = value;
		}
		//eslint-disable-next-line
	}, [value]);

	const pasteDate = (event) => {
		event.preventDefault();
		let pastedData = event.clipboardData.getData("text");
		const dateFormats = [
			'dd/MM/yyyy',
			'MM/dd/yyyy',
			'yyyy/MM/dd',
			'yyyy-MM-dd',
			'dd-MM-yyyy'
		];

		let parsedDate = null;
		for (let format of dateFormats) {
			parsedDate = parse(pastedData, format, new Date());

			if (isValid(parsedDate)) {
				break;
			}
			parsedDate = null;
		}

		if (parsedDate && isValid(parsedDate)) {
			actualValue.current = parsedDate;
			let formattedDate = format(parsedDate, outFormat);
			onChange({
				value: formattedDate,
				dateValue: parsedDate,
				target: { value: formattedDate },
				targetValue: pastedData,
				type: "change"
			});
		}
	};

	const inputValue = dateValue(value, valueFormat);
	return <KendoLabelContainer
		label={label}
		editorId={editorId}
		editorValue={true}
		className={"custom-floating-label"}
		sideLabel={sideLabel}
	>
		<DatePicker
			popupSettings={{
				...popupSettings,
				popupClass: "customPopup datepicker-popup"
			}}
			{...others}
			value={inputValue}
			id={editorId}
			onPaste={pasteDate}
			name={name ?? editorId}
			onChange={change}
			label=""
			format={dateFormat}
			min={defaultDate(min, "1920/01/01", valueFormat)}
			max={defaultDate(max, "2099/12/31", valueFormat)}
			size={size}
			className={`${className} ${highlight ? 'input-highlight' : ''}`}
			dateInput={MaskedTexForDateInput}
			calendar={CustomCalendar}
			formatPlaceholder={{ mask: mask, maskSize: size }}
		/>
	</KendoLabelContainer>;
};

KendoDatePicker.propTypes = {
	id: PropTypes.any,
	label: PropTypes.any,
	max: PropTypes.any,
	min: PropTypes.any,
	name: PropTypes.any,
	highlight: PropTypes.bool,
	onChange: PropTypes.func,
	outFormat: PropTypes.any,
	popupSettings: PropTypes.object,
	sideLabel: PropTypes.bool,
	useBackFormats: PropTypes.bool,
	value: PropTypes.any,
	valueFormat: PropTypes.any
};

export default KendoDatePicker;

export const MaskedTexForDateInput = ({
	value,
	onChange,
	name,
	formatPlaceholder,
	min,
	max,
	...others
}) => {

	const [maskValue, setMaskValue] = useState(defaultMask());

	useEffect(() => {
		if (hasValue(value)) {
			const formated = format(value, dateFormat);
			if (formated !== maskValue) {
				setMaskValue(formated);
			}
			return;
		}

		setMaskValue(makeMask(formatPlaceholder?.mask, "date"));
		// eslint-disable-next-line
	}, [value, formatPlaceholder?.mask]);

	const handleChange = (e) => {

		if (maskValue === e.target.value) {
			return;
		}
		const eValue = valueOrOption(e.value || "");
		const dateParts = eValue.split("/");
		const values = validDate({
			day: dateParts[0],
			month: dateParts[1],
			year: dateParts[2],
			min: min,
			max: max,
		});
		setMaskValue(values[1]);
		const changeObj = {
			...e,
			value: values[0],
			syntheticEvent: e,
			target: { ...e.target, value: values[0] },
		};

		onChange(changeObj);
	};

	return (
		<MaskedTextBox
			{...others}
			value={maskValue}
			mask="dD/mM/yYYY"
			rules={rules}
			name={name}
			onChange={handleChange}
			size={formatPlaceholder?.maskSize}
		/>
	);
};
