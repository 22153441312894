const outsideIcons = {
	abrhilidea: {
		allowedTag: "Abrhil-Idea",
		Icon: "./abrhil-idea.svg",
	},
	crutches: {
		allowedTag: "Crutches",
		Icon: "./crutches.svg",
	},
	facemask: {
		allowedTag: "Face-Mask",
		Icon: "./face-mask.svg",
	},
	folderstree: {
		allowedTag: "Folders-Tree",
		Icon: "./folders-tree.svg",
	},
	notification: {
		allowedTag: "notification",
		Icon: "./notification.svg",
	},
	puzzlepiece: {
		allowedTag: "Puzzle-Piece",
		Icon: "./puzzle-piece.svg",
	},
	sidefacemask: {
		allowedTag: "Side-Face-Mask",
		Icon: "./side-face-mask.svg",
	},
};

export default outsideIcons;