import useNotificationsStore from "./useDialogsStore";
import useStore from "../components/Contents/Helpdesk/hooks/useStore";

function useNotificationDialogs() {
    const openHD = useNotificationsStore(state => state.openHD);
    const openSubHD = useNotificationsStore(state => state.openSubHD);
    const openNoti = useNotificationsStore(state => state.openNoti);

    const setOpenHD = useNotificationsStore(state => state.setOpenHD);
    const setOpenSubHD = useNotificationsStore(state => state.setOpenSubHD);
    const setOpenNoti = useNotificationsStore(state => state.setOpenNoti);

    const setTicketFocus = useStore(state => state.setTicketFocus);
    const ticketId = useStore(state => state.ticket_focus);

    const onCloseHD = () => setOpenHD(false);
    const onCloseNoti = () => setOpenNoti(false);
    const onCloseSubHD = () => {
        setOpenSubHD(false);
        setTicketFocus(null);
    }

    return {
        openHD,
        openNoti,
        openSubHD,
        ticketId,
        onCloseHD,
        onCloseNoti,
        onCloseSubHD,
    };
};

export default useNotificationDialogs;