import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import "./_styles.scss";

function Loader({ size }) {

    return (
        <div className="loader-wrap">
            <svg className="gradient">
                <defs>
                    <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                        <stop offset="20%" stopColor="#1beef9" />
                        <stop offset="40%" stopColor="#5aaafa" />
                        <stop offset="60%" stopColor="#007aff" />
                        <stop offset="80%" stopColor="#1a55eb" />
                        <stop offset="100%" stopColor="#26328a" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                variant="indeterminate"
                className="custom-abr-loader"
                size={size}
                thickness={4}
                classes={{ circle: 'locked-circle' }}
                sx={{
                    animationDuration: '1200ms',
                }}
            />
        </div>
    );
};

export default Loader;

Loader.propTypes = {
    size: PropTypes.number
};