

const abrhilIcons = {
	corporate: {
		allowedTag: "Corporate",
		Icon: "./Corporativo.svg"
	},
	enterprise: {
		allowedTag: "Enterprise",
		Icon: "./Empresas.svg",
	},
	imss: {
		allowedTag: "Imss",
		Icon: "./Imss.svg",
	},
	attendance: {
		allowedTag: "Attendance",
		Icon: "./AttendanceList.svg",
	},
	migration: {
		allowedTag: "Migration",
		Icon: "./Migracion.svg",
	},
	payrolls: {
		allowedTag: "Payrolls",
		Icon: "./Nominas.svg",
	},
	piechart: {
		allowedTag: "Pie-Chart",
		Icon: "./PieChart.svg",
	},
	tools: {
		allowedTag: "Tools",
		Icon: "./herramientas.svg",
	},
	pushpin: {
		allowedTag: "Pushpin",
		Icon: "./pushpin.svg",
	},
	token: {
		allowedTag: "Token",
		Icon: "./token.svg",
	},
	copy: {
		allowedTag: "Copy",
		Icon: "./Copy.svg",
	},
	settings: {
		allowedTag: "Settings",
		Icon: "./settings.svg",
	},
	picture: {
		allowedTag: "Picture",
		Icon: "./picture.svg",
	},
	shield: {
		allowedTag: "Shield",
		Icon: "./shield.svg",
	},
	externalroles: {
		allowedTag: "ExternalRoles",
		Icon: "./external_roles.svg",
	},
	poweronoff: {
		allowedTag: "PowerOnOff",
		Icon: "./power_on_off.svg",
	},
	plus: {
		allowedTag: "Plus",
		Icon: "./plus.svg",
	},
	message: {
		allowedTag: "Message",
		Icon: "./message.svg",
	},
	edit: {
		allowedTag: "Edit",
		Icon: "./edit.svg",
	},
	helpdesk: {
		allowedTag: "Helpdesk",
		Icon: "./Helpdesk.svg",
	},
	clip: {
		allowedTag: "Clip",
		Icon: "./Clip.svg",
	},
	send: {
		allowedTag: "Send",
		Icon: "./Send.svg",
	}
};

export default abrhilIcons;
