import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import { gridSkeletonProps } from "../../../navigation/components/userSection/ChangePassword/constants";

function ContentSkeleton({
    fontSize,
}) {

    return (
        <div style={{ width: "100%" }}>
            <Skeleton
                variant="text"
                {...gridSkeletonProps}
                style={{ fontSize }}
            />
        </div>
    );
};

export default ContentSkeleton;

ContentSkeleton.propTypes = {
    fontSize: PropTypes.string,
};