
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEqual, pick, size, toLower } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
// components
import { usePackages } from '../../../../../@hooks/usePackages/usePackages';
import { changeCorporate, changeGroup, changeTenant, corporatesInit, groupInit, showNotificationWarning, tenantInit } from '../../../../../../../store/actions';
import { getObjStorage, setObjStorage } from './utilities';
import { hasValue, valueOrOption } from '../../../../general/GeneralUtilities';
import { userOptionsApi } from '../../../../../services/administrator';
import { logout } from '../../../../general/auth';
import { implementService } from '../../../../../services/implemet-service';
import { currentCorporate, currentTenant } from '../../../../requests/validate-tenant';
import { currentGroup } from "../../../../requests/user-store";

const isSameValue = (value1, value2) => {
	value1 = valueOrOption(value1, 1);
	value2 = valueOrOption(value2, 2);

	return value1 === value2 || parseInt(value1) === parseInt(value2);
};

const useController = () => {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();

	const {
		loadPackages,
		resetPackages,
		loadMenu,
		getUserConfigs,
	} = usePackages();

	const { source: tenantData, current: tenantValue } = useSelector((state) => state.tenant) ?? {};
	const { source: corporateData, current: corporateValue } = useSelector((state) => state.corporate) ?? {};
	const { data: roleData, select: roleValue } = useSelector((state) => state.group) ?? {};

	const toLogin = () => {
		setTimeout(() => {
			logout();
			navigate("/login");
		}, 2000);
	};

	const getTenantSource = () => {
		const api = userOptionsApi();
		implementService(api.get(),
			async (response) => {
				const companies = valueOrOption(response?.companies, []);
				const corporates = valueOrOption(response?.corporates, []);
				dispatch(tenantInit(companies));
				dispatch(corporatesInit(corporates));
				if (size(companies)) {
					const company = await onLoadCompanies(companies);
					if (size(corporates)) onLoadCorporates(corporates, company);
					const userData = await getUserConfigs(company?.id);
					const roles = valueOrOption(response?.roles, []);
					onLoadRoles(roles, company?.id, userData?.is_superuser);
					return;
				}
				toLogin();
			},
			error => {
				if (error?.response?.status === 401) {
					toLogin();
				}
			},
			true,
			null,
			false,
		);
	};

	useEffect(() => {
		getTenantSource();
		// eslint-disable-next-line
	}, []);

	const onLoadCompanies = async (companies) => {
		const { valid, value } = getObjStorage("tenantStorage");
		let selected = null;
		if (valid && !isEqual(value, tenantValue)) {
			selected = value;
			await onChangeTenant(value);
		} else if (!hasValue(value) && !hasValue(tenantValue)) {
			selected = companies.find(el => state?.tenant ? toLower(el?.slug) === state?.tenant : isSameValue(el.id, currentTenant()));
			selected = valueOrOption(selected, companies[0]);
			await onChangeTenant(selected);
		}
		return selected;
	};

	const onLoadCorporates = (corporates, company) => {
		const { valid, value } = getObjStorage("corporateStorage");
		let selected = null;
		if (valid && !isEqual(value, corporateValue)) {
			selected = value;
			onChangeCorporate(value);
		} else if (!hasValue(value) && !hasValue(corporateValue)) {
			selected = corporates.find(el => company ? company.corporate === el?.id : isSameValue(el.id, currentCorporate()));
			selected = valueOrOption(selected, corporates[0]);
			onChangeCorporate(selected);
		}
		return selected;
	};

	const onLoadRoles = async (roles, company, is_superuser) => {
		if (!hasValue(company)) { return; }
		const company_finded = roles?.find(c => c.company === company)?.roles;
		if (!size(company_finded)) {
			if (is_superuser) onChangeGroup(company);
			dispatch(showNotificationWarning({
				maxWidth: "sm",
				title: t("warning-general-title"),
				message: t(is_superuser ? "not-configured-roles" : "not-configured-roles2")
			}));
			return;
		}

		const company_roles = valueOrOption(company_finded, []);
		dispatch(groupInit(company_roles));

		const { valid, value } = getObjStorage("roleStorage");

		const hasRole = company_roles?.find(el => el.id === value?.id);

		if (valid && !isEqual(value, roleValue) && hasRole) {
			await onChangeGroup(company, value);
		} else if ((!hasValue(value) && !hasValue(roleValue)) || !hasRole) {
			let selected = company_roles.find(el => isSameValue(el.id, currentGroup()));
			selected = valueOrOption(selected, company_roles[0]);
			await onChangeGroup(company, selected);
		}
	};

	const onChangeTenant = async (newValue) => {
		dispatch(changeTenant(newValue));
		setObjStorage("tenantStorage", newValue);
		if (!hasValue(newValue?.id)) {
			return resetPackages();
		}
		loadPackages(newValue.id);
	};

	const onChangeCorporate = (newValue) => {
		dispatch(changeCorporate(newValue));
		setObjStorage("corporateStorage", newValue);
	};

	const onChangeGroup = async (tenant, newValue) => {
		const defaultGroup = valueOrOption(newValue, roleData[0]);
		setObjStorage("roleStorage", pick(defaultGroup, ['id', 'name', 'config']));
		dispatch(changeGroup(defaultGroup));
		return await loadMenu(tenant, defaultGroup?.id);
	};

	const handleChangeGroup = async (tenant, newGroup) => {
		const path = await onChangeGroup(tenant, newGroup);
		navigate(path);
	};

	const onChange = e => onChangeTenant(e.objectValue);
	const onChangeRole = e => handleChangeGroup(tenantValue?.id, e.objectValue);

	return {
		tenantData,
		corporateData,
		corporateValue,
		tenantValue,
		roleData,
		roleValue,
		onChange,
		onChangeRole,
	};
};

export default useController;