import { create } from "zustand";
import { orderBy } from "lodash";

const useStore = create((set) => ({
    ticket_focus: null,
    ticket_tab: 0,
    details_tab: 0,
    setTicketFocus: (value) => set({ ticket_focus: value }),
    setTicketTab: (value) => set({ ticket_tab: value }),
    setDetailsTab: (value) => set({ details_tab: value }),
}));

export default useStore;

const orderByDate = arr => orderBy(arr, ["time"], ["asc"]);