export const CORPORATE_INIT = 'corporate/init';
export const CORPORATE_CURRENT = 'corporate/current';

const initialState = {
  current: null,
  source: []
};

export function corporate(state = initialState, action) {
  switch (action.type) {
    case CORPORATE_INIT:
      return {
        ...state,
        source: action.payload
      };

    case CORPORATE_CURRENT:
      return {
        ...state,
        current: action.payload
      };

    default:
      return state;
  }
}