import React, { lazy, Suspense, useRef } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid2";
import MUICard from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import { Transition } from "../../../../../../filter/AdvanceFiltersV2/ConstantsAndUtilities/transitions";
import { SolidDivider } from "../../../../../../general/Dividers";
import { gridSkeletonProps } from "../../../../userSection/ChangePassword/constants";
import { isFunction } from "lodash";
import "./styles/_styles.scss";
import ContentSkeleton from '../Contents/Skeleton';

const Title = lazy(() => import("./Components/Title"));
const SubContent = lazy(() => import("./Components/SubContent"));

function DialogComponent({
    open,
    title,
    onClose,
    children,
    openSub,
    subContentKey,
    subContent,
    onCloseSub,
    style = {},
    ...others
}) {
    const containerRef = useRef(null);

    const onMainClose = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    return (
        <div
            ref={containerRef}
            className="hd-dialog-main-container right-side-dialog-container"
        >
            <Dialog
                open={open}
                onClose={onMainClose}
                slots={{ transition: Transition }}
                container={containerRef.current}
                className="right-side-fill-dialog"
                style={{ ...style, position: "absolute" }}
                disablePortal
                {...others}
            >
                <Suspense fallback={<ContentSkeleton style={{ padding: "15px" }} />}>
                    <SubContent
                        open={openSub}
                        contentKey={subContentKey}
                        onClose={onCloseSub}
                        content={subContent}
                    />
                </Suspense>
                <MUICard className="right-side-main-container">
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                    >
                        {!!title &&
                            <>
                                <Grid size={12}>
                                    <Suspense fallback={<Skeleton {...gridSkeletonProps} />}>
                                        <Title onClose={onClose} title={title} />
                                    </Suspense>
                                </Grid>
                                <Grid size={12}>
                                    <SolidDivider />
                                </Grid>
                            </>}
                        <Grid size={12} className="noti-dialog-content">
                            {children}
                        </Grid>
                    </Grid>
                </MUICard>
            </Dialog>
        </div>
    );
};

export default DialogComponent;

DialogComponent.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    style: PropTypes.object,
    openSub: PropTypes.bool,
    subContentKey: PropTypes.string,
    onCloseSub: PropTypes.func,
    subContent: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ]),
};