
const attendanceMainItems = [
	{
		id: "modules@attendance-settings",
		type: "item",
		componentElm: "modules/attendance-mopers-main/SettingsAttendance",
	},
	{
		id: "modules@attendance",
		type: "item",
		icon: "attendance",
		componentElm: "modules/attendance-mopers-main/attendance/Attendance",
	},
	{
		id: "modules@mopers",
		type: "item",
		icon: 'moperssign',
		componentElm: "modules/attendance-mopers-main/moper/MOPERSByWorker",
	},
	{
		id: 'modules@mopers-signatures-general',
		type: 'item',
		icon: 'mopers',
		componentElm: 'modules/attendance-mopers-main/moper/MOPERSSignature',
	},
	{
		id: 'modules@signatures',
		type: 'item',
		icon: 'graphicsignatures',
		componentElm: 'modules/attendance-mopers-main/signature/GraphicSignatures',
	},
	{
		id: 'modules@human-resources-settings',
		type: 'item',
		componentElm: 'modules/culture-people-main/SettingsRH',
	},
];

export default attendanceMainItems;