import React, { useRef } from "react";
import PropTypes from "prop-types";
import { isFunction, isNumber, isString, trim } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import IconUse from "../../../../../../uiDesign/IconUse";
import CustomTooltip from "../../../../../../Templates/CustomTooltip";
import "./styles/index.scss";

function Button({
    onClick,
    variant,
    onSelectFiles,
    style,
    text,
    icon,
    type,
    buttonType,
    title,
    shape = "",
    loading = false,
    className = "",
    ...others
}) {
    const inputRef = useRef(null);
    const isFile = type === "file";
    const variantClassname = variants[variant] || '';
    const hasValidText = isString(text) || isNumber(text);

    const onCustomClick = (e) => {
        if (loading) return;
        if (isFunction(onClick)) {
            onClick(e);
        }
        if (isFile) {
            inputRef.current?.click();
        }
    }

    return (
        <>
            <CustomTooltip title={title}>
                <button
                    className={trim(`notification-btn ${variantClassname} ${className} ${shape}`)}
                    onClick={onCustomClick}
                    style={style}
                    type={buttonType}
                    {...others}
                >
                    {!!icon && <IconUse icon={icon} />}
                    {hasValidText && <span>{text}</span>}
                    {loading && <CircularProgress />}
                </button>
            </CustomTooltip>
            {isFile &&
                <input
                    ref={inputRef}
                    name="file"
                    type="file"
                    onChange={onSelectFiles}
                    hidden
                    multiple
                />}
        </>
    );
};

export default Button;

Button.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    shape: PropTypes.string,
    buttonType: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    style: PropTypes.object,
    icon: PropTypes.string,
    loading: PropTypes.bool,
    onSelectFiles: PropTypes.func,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

const variants = {
    white: "white-btn",
    black: "black-btn",
    contained: "contained-btn",
    text: "text-btn",
};