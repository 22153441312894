import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import LazySkeleton from "./LazySkeleton";

const Buttons = lazy(() => import("./Buttons"));
const MsgBody = lazy(() => import("./MsgBody"));
const Skeleton = lazy(() => import("./Skeleton"));

function DeleteSkeleton({
    confirm,
    close,
    ...others
}) {
    return (
        <Suspense fallback={<LazySkeleton />}>
            <Skeleton close={close} {...others}>
                <MsgBody {...others} />
                <Buttons
                    {...others}
                    confirm={confirm}
                    close={close}
                    hasConfirm={true}
                    isDelete={true}
                />
            </Skeleton>
        </Suspense>
    );
};

export default DeleteSkeleton;

DeleteSkeleton.propTypes = {
    close: PropTypes.func,
    confirm: PropTypes.func
};