

const abrhilIcons = {
	advancefilters: {
		allowedTag: "Advance-Filters",
		Icon: "./AdvanceFilters.svg",
	},
	annual: {
		allowedTag: "Annual",
		Icon: "./Annual.svg",
	},
	bimonthly: {
		allowedTag: "Bimonthly",
		Icon: "./Bimonthly.svg",
	},
	calculate: {
		allowedTag: "Calculate",
		Icon: "./Calculate.svg",
	},
	complement: {
		allowedTag: "Complement",
		Icon: "./Complement.svg",
	},
	delete: {
		allowedTag: "Delete",
		Icon: "./Delete.svg",
	},
	edit: {
		allowedTag: "Edit",
		Icon: "./Edit.svg",
	},
	file: {
		allowedTag: "File",
		Icon: "./File.svg",
	},
	money: {
		allowedTag: "Money",
		Icon: "./Money.svg",
	},
	fileedit: {
		allowedTag: "File-Edit",
		Icon: "./FileEdit.svg",
	},
	fileentry: {
		allowedTag: "File-Entry",
		Icon: "./FileEntry.svg",
	},
	fileprograming: {
		allowedTag: "File-Programing",
		Icon: "./FilePrograming.svg",
	},
	initialize: {
		allowedTag: "Initialize",
		Icon: "./Initialize.svg",
	},
	monthly: {
		allowedTag: "Monthly",
		Icon: "./Monthly.svg",
	},
	moreoptions: {
		allowedTag: "More-Options",
		Icon: "./MoreOptions.svg",
	},
	new: {
		allowedTag: "New",
		Icon: "./New.svg",
	},
	payrollbloq: {
		allowedTag: "Payroll-Bloq",
		Icon: "./PayrollBloq.svg",
	},
	print: {
		allowedTag: "Print",
		Icon: "./Print.svg",
	},
	save: {
		allowedTag: "Save",
		Icon: "./Save.svg",
	},
	comment: {
		allowedTag: "Comment",
		Icon: "./comment.svg",
	},
	refresh: {
		allowedTag: "Refresh",
		Icon: "./Refresh.svg",
	},
	switch: {
		allowedTag: "Switch",
		Icon: "./switch.svg",
	},
	import: {
		allowedTag: "Import",
		Icon: "./Import.svg",
	},
	warning: {
		allowedTag: "Warning",
		Icon: "./Warning.svg",
	},
	sort: {
		allowedTag: "Sort",
		Icon: "./Sort.svg",
	},
};

export default abrhilIcons;
