import PropTypes from "prop-types";
import React from 'react';
import Grid2 from '@mui/material/Grid2';
import { conditional, hasValue, valueOrOption } from '../general/GeneralUtilities';
import { each, isObject, omit, pick } from 'lodash';

/**
 * Only auxiliar until Grid upgrade finish
 * @param {object} params
 * @param {boolean} params.asV2 - Indica el grid que se va a usar para el renderizado
 * @param {*} params.children
 * @returns
 */
export const GridType = ({
	children,
	...others
}) => {
	const sizesValues = ["xs", "sm", "md", "lg", "xl"];
	const Container = Grid2;

	const getProps = () => {
		const sizes = valueOrOption(others?.size, pick(others, sizesValues));
		if (!hasValue(sizes)) {
			return { size: 12 };
		}

		if (!isObject(sizes)) {
			return { size: sizes };
		}

		let mapedKeys = {};
		const keys = Object.keys(sizes);
		each(keys, key => {
			mapedKeys[key] = conditional(sizes[key] === true, "grow", sizes[key]);
		});
		return { size: mapedKeys };


	};

	return (
		<Container {...omit(others, ["item", "asV2", ...sizesValues])} {...getProps()}>
			{children}
		</Container>
	);
};

GridType.propTypes = {
	asV2: PropTypes.bool,
	children: PropTypes.any
};
