import { useEffect, useRef, useState } from "react";
import useRequestLoad from "../../../../../../services/useRequestLoad";
import useNotificationsStore from "./useDialogsStore";
import { useDispatch, useSelector } from "react-redux";
import helpdeskAPIS, { AHD_MISSING_ENV_KEY, AHD_MISSING_ENV_MSG } from "../apis";
import useButtonsStore from "./useButtonsStore";
import { showNotificationError } from "../../../../../../../../store/actions";
import BaseButton from "../../../../../Buttons/BaseButton";
import { t } from "i18next";
import { showErrorNotification, showSuccessNotification } from "../../../../../Notifications";
import useTokenStore from "../apis/useTokenStore";
import { resolveError } from "../../../../../requests/resolve-error";
import { successExecute } from "../../../../../Notifications/notification-messages";

const USER_NOT_FOUND_KEY = "404";

const CUSTOM_ERRORS = ["AHD_MISSING"];

function useNotificationButtons() {
    const dispatch = useDispatch();

    const tenant = useSelector(state => state.tenant?.current?.id);
    const userEmail = useSelector(state => state.userConfigs?.user_info?.email);

    // NOTIFICATIONS OPEN STATE
    const openHD = useNotificationsStore(state => state.openHD);
    const openNoti = useNotificationsStore(state => state.openNoti);
    const setOpenHD = useNotificationsStore(state => state.setOpenHD);
    const setOpenNoti = useNotificationsStore(state => state.setOpenNoti);

    // NOTIFICATIONS COUNTERS STATE
    const counterHD = useButtonsStore(state => state.counterHD);
    const counterNoti = useButtonsStore(state => state.counterNoti);
    const setCounterHD = useButtonsStore(state => state.setCounterHD);
    // const setCounterNoti = useButtonsStore(state => state.setCounterNoti);

    // TOKEN STATE
    const setToken = useTokenStore(state => state.setToken);

    const [fetch, loadingHD] = useRequestLoad();
    const [error, setError] = useState(null);
    const tokenRestored = useRef(false);

    useEffect(() => {
        if (!userEmail) return;
        getToken();
    }, [tenant, userEmail])

    const getToken = async (showFeedback = false) => {
        try {
            const ahdToken = localStorage.getItem("ahd_token");
            let tokenValue = ahdToken;
            if (!tokenValue) {
                const resp = await helpdeskAPIS.getAccessToken();
                tokenValue = resp?.data;
                localStorage.setItem("ahd_token", tokenValue);
                if (showFeedback) {
                    showSuccessNotification(successExecute());
                }
            }
            setToken(tokenValue);
            getCounters();
            setError(null);
        } catch (error) {
            const detail = error.response?.data?.detail;
            const errorUse = detail || error;
            performErrorProtocol(errorUse, true);
            const bodyMessage = getErrorByCode(errorUse?.message, getTokensErrors);
            if (showFeedback) {
                showErrorNotification(bodyMessage);
            }
        }
    }

    const performErrorProtocol = (payload = null, deleteToken = false) => {
        setError(payload);
        if (deleteToken) {
            deleteAHDToken();
        }
    }

    const deleteAHDToken = () => localStorage.removeItem("ahd_token");

    const getCounters = () => {
        fetch({
            api: helpdeskAPIS.getCounter(),
            callback: resp => setCounterHD(resp?.ticketscontact_count ?? 0),
            onFailed: err => {
                if (err?.response?.status === 401 && localStorage.getItem("ahd_token") && !tokenRestored.current) {
                    deleteAHDToken();
                    getToken();
                    tokenRestored.current = true;
                    return;
                }
                if (CUSTOM_ERRORS.includes(err.code)) {
                    showErrorNotification(err);
                    performErrorProtocol(err);
                    return;
                }
                resolveError(err);
            }
        });
    }

    const isOpen = (key) => {
        if (!userEmail) return;
        const closed = { ntc: true, ahd: true };
        if (openNoti) {
            closed.ntc = false;
            setOpenNoti(false);
        }
        if (openHD) {
            closed.ahd = false;
            setOpenHD(false);
        }
        return closed[key];
    }

    const handleClickHD = () => {
        if (error) {
            const isNotFound = error.code === USER_NOT_FOUND_KEY;
            const bodyMessage = getErrorByCode(error.code);
            dispatch(showNotificationError({
                maxWidth: "sm",
                ...bodyMessage,
                CustomOptions: ({ closeNotification }) => {
                    return (
                        <>
                            {!isNotFound &&
                                <BaseButton
                                    label={t("Reintentar")}
                                    design="text"
                                    onClick={() => {
                                        getToken(true);
                                        closeNotification();
                                    }}
                                />}
                            <BaseButton
                                label={t("accept")}
                                design="contained"
                                onClick={closeNotification}
                            />
                        </>
                    );
                }
            }));
            return;
        }
        if (isOpen("ahd")) {
            setOpenHD(true);
        }
    }

    const handleClickNoti = () => {
        if (isOpen("ntc")) {
            setOpenNoti(true);
        }
    }

    return {
        loadingHD,
        counterHD,
        counterNoti,
        handleClickHD,
        handleClickNoti,
    };
};

export default useNotificationButtons;

const getErrorByCode = (code = "default", dict = null) => {

    const defErrors = {
        [USER_NOT_FOUND_KEY]: [
            "Su usuario no cuenta con acceso a Helpdesk",
            "Contacte a su administrador en caso de ser necesario",
        ],
        [AHD_MISSING_ENV_KEY]: [
            AHD_MISSING_ENV_MSG,
        ],
        default: [
            "Servicio temporalmente no disponible",
            "Lamentamos los inconvenientes que esto pueda causar",
        ],
    };

    const errors = dict ?? defErrors;

    const foundError = errors[code] ?? errors.default;

    return { message: foundError?.[0], description: foundError?.[1] };
};

const getTokensErrors = {
    [USER_NOT_FOUND_KEY]: [
        "Usuario sin acceso a Helpdesk",
    ],
    [AHD_MISSING_ENV_KEY]: [
        AHD_MISSING_ENV_MSG,
    ],
    default: [
        "Servicio no disponible",
        "Intente de nuevo mas tarde",
    ]
};