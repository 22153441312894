import PropTypes from "prop-types";
import React from 'react';

/* library components */
import Typography from '@mui/material/Typography';
import { hasValue } from '../general/GeneralUtilities';
import { GridType } from './GridType';

const SubTitle = ({
	title,
	children,
	blueTitle = true,
	className = "",
	startElement = null,
	separator = false,
	asDivider = false,
	titleStyle = {},
	asV2 = false,
	...others
}) => {

	const Grid = GridType;
	const baseSize = asV2 ? { size: 12 } : { item: true, xs: 12 };
	const autoSize = asV2 ? { size: "auto" } : { item: true, xs: "auto" };
	const coverSize = asV2 ? { size: "grow" } : { item: true, xs: true };

	const titleProps = {
		sx: titleStyle,
		className: !blueTitle ? 'black-title' : '',
		label: title,
	}

	if (asDivider) {
		return (
			<Grid asV2={asV2} {...baseSize} {...others} className={`${className} abrhil-subtitle ${separator ? "separator" : ""}`} >
				<Grid asV2={asV2} container spacing={1} alignItems='center'>
					<Grid asV2={asV2} {...autoSize}>
						<TitleLabel
							{...titleProps}
						/>
					</Grid>
					{hasValue(startElement) && (
						<Grid asV2={asV2} {...autoSize}>
							{startElement}
						</Grid>
					)}
					<Grid asV2={asV2} {...coverSize}>
						<div className='subtitle-middle-divider'>
						</div>
					</Grid>
					{hasValue(children) && (
						<Grid asV2={asV2} {...autoSize}>
							{children}
						</Grid>)
					}
				</Grid>
			</Grid>);
	}

	return (
		<Grid asV2={asV2} {...baseSize} {...others} className={`${className} abrhil-subtitle ${separator ? "separator" : ""}`} >
			<TitleLabel
				{...titleProps}
			/>
			{children}
		</Grid>
	);
};

const TitleLabel = ({
	label = '',
	...others
} = {}) => {

	return (
		<Typography
			variant="subtitle1"
			gutterBottom
			component="span"
			{...others}
		>
			{label}
		</Typography>
	);
}

SubTitle.propTypes = {
	asDivider: PropTypes.bool,
	blueTitle: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
	separator: PropTypes.bool,
	startElement: PropTypes.any,
	title: PropTypes.string,
	titleStyle: PropTypes.object,
	asV2: PropTypes.bool,
};

export default SubTitle;