import { ParseParams, ServerAPI, getUrl } from './ServerAPI';
import { config, API_PUBLIC_ADDRESS, API, configMedia } from './config';
import axios from 'axios';

const Branches = () => {

	const baseName = "branches";

	const baseApi = ServerAPI(baseName);

	const getCountries = () => {
		return axios.get(`${API_PUBLIC_ADDRESS}/country/`, config())
			.then(response => response.data);
	};

	const getAddress = (country, zipcode) => {
		return axios.get(`${API_PUBLIC_ADDRESS}/zipcode/?country=${country}&zipcode=${zipcode}`, config())
			.then(response => response.data);
	};

	const changeLogo = (id, data) => {
		const url = getUrl(`${baseName}/${id}/change-logo`, true);
		return axios.put(url, data, data?.logo ? configMedia() : config())
			.then((response) => response.data);
	};

	return {
		...baseApi,
		getCountries,
		getAddress,
		changeLogo,
	};
};

const JobPositionsApi = () => {

	const baseName = "job-positions";

	const baseApi = ServerAPI(baseName);

	const getKeysAvailable = (params) => {
		return axios.get(API + '/get-available-key-position/', config(params))
			.then(response => response.data);
	};

	return {
		...baseApi,
		getKeysAvailable
	};
};

const EmployerRegistrationServices = (include_tenant = true) => {

	const baseName = 'employer-registration';

	const baseApi = ServerAPI(baseName);

	const patchWithInclude = (id, params, extra) => {
		const url = getUrl(`${baseName}/${id}`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.patch(url, payload, config(extra)).then((response) => response.data);
	};

	const downloadLayout = async (url) => {
		return fetch(url,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json"
				}
			})
			.then(response => response.blob())
			.then(blob => blob);
	};

	return {
		...baseApi,
		downloadLayout,
		patchWithInclude
	};
};

export const enterpriseStructureSettingsMultiAPI = {
	multi: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structures-settings-config/multi?tenant=${params.tenant}`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	},
	multi_overtime: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structure-organization-overtime-settings/multi?tenant=${params.tenant}`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	},
	multiples_default: (params, include_tenant = true) => {
		const url = getUrl(`multiples-default/multi?tenant=${params.tenant}`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config()).then((response) => response.data);
	},
	multi_overtime_massive: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structure-organization-overtime-settings/update-overtime-config?tenant=${params.tenant}`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	},
	multi_overtime_massive_workers: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structure-organization-overtime-settings/workers-reconstruction`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	},
	massive_salary_update_generate: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structures-settings/massive-salary-update-generate`, include_tenant);
		const payload = ParseParams({ params, include_tenant, include_role: true });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	},
	massive_salary_update_apply: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structures-settings/massive-salary-update-apply`, include_tenant);
		const payload = ParseParams({ params, include_tenant, include_role: true });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	}
};

export const configSettlementAPI = {
	settlement: (params, extra, include_tenant = true) => {
		const url = getUrl(`settlement-report-config/1/?tenant=${params.tenant}`, false);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	},
	signatures: (params, extra, include_tenant = true) => {
		const url = getUrl(`report-signatures/multi/?tenant=${params.tenant}`, false);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	},
};

export const enterpriseStructureSettingsMultiUpdateAPI = {
	putConcatName: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structures-settings-config/update-multiples?tenant=${params.tenant}`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	}
};

export const enterpriseStructureUpdateMassiveAPI = {
	putConcatName: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structures-settings/multi-update`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	}
};

export const enterpriseStructuresMultiAPI = {
	multi: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structures-settings/multi`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	},
	multiUpdate: (params, extra, include_tenant = true) => {
		const url = getUrl(`enterprise-structures/multi`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.put(url, payload, config(extra)).then((response) => response.data);
	}
};

const EnterpriseStructureStaff = () => {
	const baseName = 'enterprise-structures-organization-staff';
	const baseApi = ServerAPI(baseName);

	const getRequititions = (id, params) => {
		const url = getUrl(`${baseName}/${id}/get-requisitions`);
		return axios.get(url, config(ParseParams({ params, include_role: true }))).then(res => res.data);
	};

	return {
		...baseApi,
		getRequititions,
	};
};

export const jobPositionsApi = JobPositionsApi();
export const branchesAPI = Branches();
export const enterpriseStructureAPI = ServerAPI('enterprise-structures');
export const enterpriseStructureOrganizationAPI = ServerAPI('enterprise-structures-organization');

const EnterpriseStructureOrganizationModalAPI = () => {
	const baseApi = ServerAPI('enterprise-structures-organization');
	const parseResponse = (response) => {
		if (Array.isArray(response.results)) {
			const clonedData = response.results.map(item => {
				item.enterprise_structure.id = item.id;
				item.enterprise_structure.is_complete = item.is_complete;
				item.enterprise_structure.id_organization = item.id;
				item.enterprise_structure.employer_registration = item.employer_registration;
				item.enterprise_structure.is_active = item.is_active;
				delete Object.assign(item.settings, { id_settings: item.settings.id })['id'];
				item.enterprise_structure = { ...item.enterprise_structure, ...item.settings };
		
				return item.enterprise_structure;
			});
			
			return {
				count: response.count,
				results: clonedData,
			};
		} else {
			return {
				count: 0,
				results: [],
			};
		}
	};
	const get = (url) => {
		return baseApi.get(url)
			.then((response) => {
				const parsedData = parseResponse(response);
				return parsedData;
			})
	};
	return {
		...baseApi,
		get,
	};
};
export const enterpriseStructureOrganizationModalAPI = EnterpriseStructureOrganizationModalAPI();
export const enterpriseStructureStaffAPI = EnterpriseStructureStaff();
export const structurePackagesAPI = ServerAPI('enterprise-structures-organization', false);
export const enterpriseStructureSettingsAPI = ServerAPI('enterprise-structures-settings');
export const enterpriseStructureSettingsMultiplesDefaultAPI = ServerAPI('multiples-default');
export const historicalAPI = ServerAPI('enterprise-structure-records');
export const areaAPI = ServerAPI('areas');
export const departmentAPI = ServerAPI('departments');
export const getJobPositionsTypeApi = ServerAPI("job-positions-type");
export const contractingRegimeAPI = ServerAPI('company-contracting-regime');
export const employerRegistrationAPI = EmployerRegistrationServices();
export const hierarchicalLevelAPI = ServerAPI('hierarchical-levels');
export const identifierPositionAPI = ServerAPI('job-position-identifiers');
export const subAreaApi = ServerAPI("sub-areas");
export const subBranchesApi = ServerAPI("sub-branches");
export const subDepartmentsApi = ServerAPI("sub-departments");
export const managerAPI = ServerAPI('manager-identifiers');
export const groupingPositionAPI = ServerAPI('job-groupings');
export const migratoryStatesApi = ServerAPI("migratory-states");
export const causeJobChangeAPI = ServerAPI('cause-job-change');
export const docsExpirationControlapi = ServerAPI('documents-expiration-control');
export const reasonLeavingApi = ServerAPI("reason-leaving");
export const reasonLeavingDescriptionChoicesApi = ServerAPI("reason-leaving/description-choices");
export const regimeTypeAPI = ServerAPI('regime-types');
export const resourceOriginAPI = ServerAPI('resource-origins');
export const socialReasonsAPI = ServerAPI('social-reason');
export const rfcCatalogAPI = ServerAPI('catalog-rfc');
export const rfcListAPI = ServerAPI('list-rfc');
export const branchEmpAPI = ServerAPI('branch-emp');
export const configTimbrasoftAPI = ServerAPI('config-timbrasoft');
export const kioskEnterpriseAssignAPI = ServerAPI('enterprise-structure-kiosco-configs');
export const mailConfigTest = ServerAPI('test-config');