
const staffingItems = [
    {
        id: "modules@staffing",
        type: "collapse",
        package_permissions: ["staffing"],
    },

    {
        id: "modules@staffing-module",
        type: "item",
        componentElm: "modules/additionals/Staffing",
        package_permissions: ["staffing"],
    },
    {
        id: "modules@scale-validity",
        type: "item",
        componentElm: "modules/additionals/Scale",
        package_permissions: ["staffing"],
    },
];

export default staffingItems;