import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "@progress/kendo-react-buttons";
import { useTranslation } from "react-i18next";
import { buttonProps } from "./Constants";
import { isFunction } from "lodash";

function Buttons({
    confirm,
    close,
    hasConfirm,
    isDelete,
    title,
    closeNotification,
    cancelButtonText,
    confirmButtonText,
    customButtonProps,
    customActions,
    CustomOptions,
    modern
}) {
    const { t } = useTranslation();

    let cancelText, confirmText;

    switch (title) {
        case t("available-format"):
            cancelText = t("no");
            confirmText = t("yes");
            break;
        case t("reactivate-infonavit"):
            cancelText = t("no-reactivate");
            confirmText = t("yes-reactivate");
            break;
        default:
            cancelText = cancelButtonText ?? t("cancel");
            confirmText = confirmButtonText ?? t(isDelete ? "remove" : "accept");
            break;
    }

    if (isFunction(CustomOptions)) {
        return (
            <div className="buttonContainer" style={modern ? { paddingBlock: "20px" } : {}}>
                <CustomOptions closeNotification={closeNotification ?? close} />
            </div>
        );
    }

    return (
        <div className="buttonContainer" style={modern ? { paddingBlock: "20px" } : {}}>
            {!hasConfirm && (
                <Button {...buttonProps} onClick={close} style={modern ? {
                    minWidth: "120px",
                    minHeight: "30px",
                    backgroundColor: "var(--blueBold)",
                    fontWeight: "300",
                    border: "none"
                } : {}}>
                    {t("accept")}
                </Button>
            )}
            {hasConfirm && (
                <Fragment>
                    <Button
                        {...buttonProps}
                        fillMode={"outline"}
                        onClick={close}
                        {...(!isDelete ? { themeColor: "error" } : {})}
                        {...(customButtonProps ?? {})}
                    >
                        {cancelText}
                    </Button>
                    {customActions?.map((el, i) =>
                        <Button
                            key={i}
                            {...buttonProps}
                            onClick={() => {
                                if (isFunction(el.onClick)) {
                                    el.onClick();
                                }
                                closeNotification();
                            }}
                            {...(customButtonProps ?? {})}
                        >
                            {el.label}
                        </Button>
                    )}
                    <Button
                        {...buttonProps}
                        onClick={confirm}
                        {...(isDelete ? { themeColor: "error" } : {})}
                        {...(customButtonProps ?? {})}
                    >
                        {confirmText}
                    </Button>
                </Fragment>
            )}
        </div>
    );
};

export default Buttons;

Buttons.propTypes = {
    CustomOptions: PropTypes.any,
    cancelButtonText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    close: PropTypes.any,
    closeNotification: PropTypes.func,
    confirm: PropTypes.any,
    confirmButtonText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    customActions: PropTypes.array,
    customButtonProps: PropTypes.object,
    hasConfirm: PropTypes.any,
    isDelete: PropTypes.any,
    modern: PropTypes.any,
    title: PropTypes.any
};
