import { combineReducers } from "redux";
// reducers
import { app } from './app';
import { locked } from './locked';
import { tenant } from "./tenant";
import { notification } from "./notification";
import { user } from "./user";
import { menu } from "./menu";
import { filter } from "./AdvanceFilters";
import navigate from "./navigate";
import exportData from "./Export";
import { configuration } from "./config";
import { menuStructure, userDashboard, iconsMenu } from "./navigation-menu";
import { userConfigs } from "./userConfigs";
import payroll from './payrolls';
import { group } from "./groups";
import { speech, search } from "./speech";
import { variable_food_limit } from "./variable_food_limit";
import attendance from "./attendance";
import { window_packages } from "./window_packages";
import { corporate } from './corporate';

export default combineReducers({
	app,
	locked,
	tenant,
	corporate,
	notification,
	user,
	menu,
	filter,
	navigate,
	exportData,
	configuration,
	menuStructure,
	userDashboard,
	userConfigs,
	payroll,
	group,
	speech,
	search,
	iconsMenu,
	variable_food_limit,
	attendance,
	window_packages
});