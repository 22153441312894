

const fileIcons = {

	"pdficon": {
		allowedTag: "pdficon",
		Icon: "./PDF.svg",
	},
	"xlsxicon": {
		allowedTag: "xlsxicon",
		Icon: "./XLSX.svg",
	},
};

export default fileIcons;
