import React from "react";
import Button from "./components/Button";
import useNotificationButtons from "./hooks/useNotificationButtons";

function NotificationButtons() {

    const {
        loadingHD,
        counterHD,
        counterNoti,
        handleClickHD,
        handleClickNoti,
    } = useNotificationButtons();

    return (
        <div className='abrl-notifications-center' >
            <Button
                onClick={handleClickHD}
                loading={loadingHD}
                icon='Headphones'
                variant='black'
                text={counterHD}
            />
            <Button
                onClick={handleClickNoti}
                // loading={loading}
                icon='Bell'
                variant='white'
                text={counterNoti}
            />
        </div>
    );
};

export default NotificationButtons;