const requisitionIcons = {
	budget: {
		allowedTag: "budget",
		Icon: "./Budget.svg",
	},
	hired: {
		allowedTag: "hired",
		Icon: "./Hired.svg",
	},
	process: {
		allowedTag: "process",
		Icon: "./Process.svg",
	},
	authorized: {
		allowedTag: "authorized",
		Icon: "./Authorized.svg",
	},
	availables: {
		allowedTag: "availables",
		Icon: "./Available.svg",
	},
	cancel: {
		allowedTag: "cancel",
		Icon: "./Cancel.svg",
	},
};

export default requisitionIcons;