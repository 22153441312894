

const clocksIcons = {
	audit: {
		allowedTag: "Audit",
		Icon: "./Bitacora.svg",
	},
	rolesandpermissions: {
		allowedTag: "Roles-and-permissions",
		Icon: "./RolesAndPermissions.svg",
	},
	key: {
		allowedTag: "Key",
		Icon: "./key.svg",
	},
	commandkey: {
		allowedTag: "Command-key",
		Icon: "./cmKey.svg",
	},
	expand: {
		allowedTag: "Expand",
		Icon: "./expand.svg",
	},
	userkey: {
		allowedTag: "Keys",
		Icon: "./userKey.svg",
	},
	darkmode: {
		allowedTag: "DarkMode",
		Icon: "./darkMode.svg",
	},
	userviewfinder: {
		allowedTag: "User",
		Icon: "./userViewfinder.svg",
	},
	logout: {
		allowedTag: "LogOut",
		Icon: "./LogOut.svg",
	},
};

export default clocksIcons;