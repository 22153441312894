import LANG from "i18next";
import FileSaver from "file-saver";
import { format } from "date-fns";
import { valueOrOption } from "../general/GeneralUtilities";
import { trim } from "../validations/validationFilter";
import { socketsApi } from "../../services/socket-report";
import { showErrorNotification, showSuccessNotification } from "../Notifications";
import { successCreated } from "../Notifications/notification-messages";
import { first, toLower } from "lodash";

const { t } = LANG;

export const getTheme = () => {
	const systemDark = window.matchMedia("(prefers-color-scheme: dark)");
	const currentTheme = localStorage.getItem('theme') || (systemDark.matches ? "dark" : "light");
	return currentTheme === "dark" ? "dark" : "light";
};

export const useRepoName = (socketName) => {

	const cleanName = () => {
		socketName = valueOrOption(socketName, "").toString();
		return socketName.replaceAll(/\s|_/g, "-");
	};

	const humanizeName = (name) => {
		name = valueOrOption(name, "")
			.replaceAll(/[-_]/g, ' ')
			.replace("notifications:names.", "")
			.replace("names.", "");
		return trim(name);
	};

	const getReportName = () => {
		const cleaned = cleanName();
		let name = t(`notifications:names.${cleaned}`);
		if (name === `notifications:names.${cleaned}`) {
			name = cleaned;
		}
		if (name === `names.${cleaned}`) {
			const translated = t(cleaned);
			name = cleaned === translated ? t(socketName) : translated;
		}
		return humanizeName(name);
	};

	return getReportName();
};

export const downloadFile = async (
	taskID,
	file_name = "report",
	apiName = null,
	isExport = false,
	useDate = true,
) => {
	const prefix = isExport ? 'export' : 'report';
	const currentTime = useDate ? format(new Date(), "yyyyMMdd_HHmmss") : '';
	const fileName = useRepoName(first(file_name?.split('.')));
	const filePrefix = useRepoName(prefix);
	const alreadyPrefix = toLower(fileName)?.includes(toLower(filePrefix));
	const name = `${!alreadyPrefix ? filePrefix : ""} ${trim(fileName?.slice(0, 30))} ${currentTime}`;
	const extension = isExport ? '.xlsx' : '';

	try {
		const response = await socketsApi.downloadReport(taskID, apiName);
		FileSaver.saveAs(
			response,
			trim(`${name}${extension}`)
		);
	} catch (error) {
		showErrorNotification({ message: t('download-error'), autoClose: false });
		return error;
	}
};

export const noPrefixDownload = async (taskID, file_name = "report") => {
	const name = useRepoName(file_name);
	try {
		const response = await socketsApi.downloadReport(taskID);
		FileSaver.saveAs(response, trim(name));
	} catch (error) {
		return error;
	}
};

export const formatTitleReport = (key) => {
	return `${t(key)}_${format(
		new Date(),
		"yyyy-MM-dd HH-mm-ss"
	)}`;
};

export const OnCreatedTenant = () => {
	showSuccessNotification(successCreated());
	setTimeout(() => {
		location.reload();
	}, 2000);
};