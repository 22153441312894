
const trainingItems = [
    // CAPACITACION //
    {
        id: "modules@courses",
        type: "item",
        package_permissions: ["training"],
        componentElm: 'modules/training/general/Courses',
    },
    {
        id: "modules@courses-by-worker",
        type: "item",
        package_permissions: ["training"],
        componentElm: 'modules/training/general/CoursesByWorker',
    },
    {
        id: "modules@training-places",
        type: "item",
        package_permissions: ["training"],
        componentElm: 'modules/training/general/TrainingPlaces',
    },
    {
        id: "modules@instructors",
        type: "item",
        package_permissions: ["training"],
        componentElm: 'modules/training/general/InstructorsTraining',
    },
    {
        id: "modules@reason-for-absence",
        type: "item",
        package_permissions: ["training"],
        componentElm: 'modules/training/general/ReasonAbsence',
    },
    {
        id: "modules@courses-catalog",
        type: "item",
        package_permissions: ["training"],
        componentElm: 'modules/training/general/CoursesCatalog',
    },
    {
        id: "abrhil@agents-types",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/AgentsType',
    },
    {
        id: 'modules@agents-types-mirror',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/AgentsType',
    },
    {
        id: "modules@disabilities",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/Disabilities',
    },
    {
        id: 'modules@education-mapping',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/Education',
    },
    {
        id: "modules@establishment-key",
        type: "item",
        package_permissions: ["training", "training.establishment_key"],
        componentElm: 'modules/training/stps-catalog/EstablishmentKey',
    },
    {
        id: "abrhil@intitution-types",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/InstitutionTypes',
    },
    {
        id: 'modules@intitution-types-mirror',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/InstitutionTypes',
    },
    {
        id: "modules@job-mapping",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/JobMapping',
    },
    {
        id: "abrhil@occupations-workers",
        type: "item",
        package_permissions: ["training", "training.national_catalogs_of_occupations"],
        componentElm: 'modules/training/stps-catalog/OccupationsWorkers',
    },
    {
        id: 'modules@occupations-workers-mirror',
        package_permissions: ["training", "training.national_catalogs_of_occupations"],
        type: 'item',
        componentElm: 'modules/training/stps-catalog/OccupationsWorkers',
    },
    {
        id: 'modules@training-objectives-mirror',
        type: 'item',
        package_permissions: ["training", "training.training_goals"],
        componentElm: 'modules/training/stps-catalog/TrainingObjectives',
    },
    {
        id: "abrhil@productive-area",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/ProductiveArea',
    },
    {
        id: "modules@productive-area-mirror",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/ProductiveArea',
    },
    {
        id: "modules@study-levels",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: 'modules/training/stps-catalog/StudyLevels',
    },
    {
        id: "abrhil@thematic-areas",
        type: "item",
        package_permissions: ["training", "training.thematic_area"],
        componentElm: 'modules/training/stps-catalog/ThematicAreas',
    },
    {
        id: 'modules@thematic-areas-mirror',
        type: 'item',
        package_permissions: ["training", "training.thematic_area"],
        componentElm: 'modules/training/stps-catalog/ThematicAreas',
    },
    {
        id: "abrhil@training-objectives",
        type: "item",
        package_permissions: ["training", "training.training_goals"],
        componentElm: 'modules/training/stps-catalog/TrainingObjectives',
    },
    {
        id: "abrhil@training-modalities",
        type: "item",
        package_permissions: ["training", "training.modality"],
        componentElm: 'modules/training/stps-catalog/TrainingModalities',
    },
    {
        id: 'modules@training-modalities-mirror',
        type: 'item',
        package_permissions: ["training", "training.modality"],
        componentElm: 'modules/training/stps-catalog/TrainingModalities',
    },
    {
        id: 'modules@training-settings',
        type: 'item',
        componentElm: 'modules/training/SettingsTraining',
    },
    // En construccion
    {
        id: "modules@training",
        type: "item",
        icon: "training",
        inConstruction: true,
        componentElm: true
    }
];

export default trainingItems;