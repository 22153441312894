
const cultureMainItems = [
	{
		id: 'modules@worker-add',
		type: 'item',
		icon: 'worker-add',
		componentElm: 'modules/culture-people-main/worker/Add',
	},
	{
		id: 'modules@kardex',
		type: 'item',
		icon: 'worker-kardex',
		componentElm: 'modules/culture-people-main/worker/Kardex',
	},
	{
		id: 'modules@validities',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.extra_validities"],
		componentElm: 'modules/culture-people-main/worker/Validity',
	},
	{
		id: "modules@contracts-and-formats",
		type: "item",
		icon: 'contractsandformats',
		componentElm: "modules/culture-people-main/worker/Contracts",
	},
	{
		id: 'user-and-roles@org-chart',
		type: 'item',
		icon: 'organization-chart',
		componentElm: 'modules/culture-people-main/organization/OrganizationChart'
	},
	{
		id: 'modules@structure-enterprise',
		type: 'item',
		componentElm: 'modules/culture-people-main/organization/StructureEnterprise',
	},
	{
		id: 'modules@employer-registration',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
		componentElm: 'modules/culture-people-main/organization/PatronalRegistration',
	},
	{
		id: 'modules@branch',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.branch"],
		componentElm: 'modules/culture-people-main/organization/Branch',
	},
	{
		id: 'modules@sub-branch',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.sub_branch"],
		componentElm: 'modules/culture-people-main/organization/SubBranch',
	},
	{
		id: 'modules@area',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.area"],
		componentElm: 'modules/culture-people-main/organization/Area',
	},
	{
		id: 'modules@sub-area',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.sub_area"],
		componentElm: 'modules/culture-people-main/organization/SubArea',
	},
	{
		id: 'modules@department',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.department"],
		componentElm: 'modules/culture-people-main/organization/Depto',
	},
	{
		id: 'modules@sub-department',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.sub_department"],
		componentElm: 'modules/culture-people-main/organization/SubDepto',
	},
	{
		id: 'modules@job-position',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.job_position"],
		componentElm: 'modules/culture-people-main/organization/JobPosition',
	},
	{
		id: 'modules@job-grouping',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.group"],
		componentElm: 'modules/culture-people-main/organization/GroupingPosition',
	},
	{
		id: 'modules@identifier-position',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.position_identifier"],
		componentElm: 'modules/culture-people-main/organization/IdentifierPosition',
	},
	{
		id: 'modules@jobs-management',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.management"],
		componentElm: 'modules/culture-people-main/organization/Management',
	},
	{
		id: 'modules@jobs-hierarchical-level',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.hierarchical_level"],
		componentElm: 'modules/culture-people-main/organization/HierarchicalLevel',
	},
	{
		id: 'modules@patronal-registration-settings',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
		componentElm: 'modules/culture-people-main/organization/PatronalRegistrationSettings',
	},
	//En construccion
	{
		id: 'modules@human-resources-catalogs',
		type: 'item',
		inConstruction: true,
		componentElm: true,
	},
];

export default cultureMainItems;