import React from "react";
import PropTypes from "prop-types";
import "../../styles/Login.scss";

import In from "../../../../../../assets/images/webp/in.webp";
import Fb from "../../../../../../assets/images/webp/facebook.webp";
import X from "../../../../../../assets/images/webp/x.webp";
import YT from "../../../../../../assets/images/webp/youtube.webp";

const networks = [
    { key: "linkedin", icon: In, link: null },
    { key: "facebook", icon: Fb, link: 'https://www.facebook.com/Abrhilsoft/' },
    { key: "x", icon: X, link: null },
    { key: "youtube", icon: YT, link: null },
];

export default function SocialNetwork({ width, height, showTitle = true }) {

    const handleClick = link => {
        if (!link) return;
        window.open(link, '_blank');
    }

    return (
        <div className="social-network-wrap">
            {showTitle && <span>{'Nuestras redes'}</span>}
            <div>
                {networks.map(net => (
                    <button key={`item_${net.key}`} onClick={() => handleClick(net.link)}>
                        <img alt={net.key} src={net.icon}  height={height} width={width} loading="lazy" />
                    </button>
                ))}
            </div>
        </div>
    );
};

SocialNetwork.propTypes = {
    showTitle: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string
};