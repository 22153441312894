import PropTypes from "prop-types";
import React, { memo } from 'react';
import CustomTooltip from "../../Templates/CustomTooltip";
import { GridColumnMenuWrapper } from '../../Templates/CustomFilterWrapper';
import { HeaderThElement } from '@progress/kendo-react-data-tools';
import { hasValue } from '../../general/GeneralUtilities';

const CustomHeader = memo(({
	title,
	columnMenuWrapperProps,
	filterable = true,
	thProps,
	onClick,
	field,
	children,
}) => {

	const isField = hasValue(field) && hasValue(title);
	const notAvoidedFields = !["expanded"].includes(field);
	const isNotFilterable = thProps?.className?.includes("noFilterable");

	if (field === "selected") {
		return (
			<HeaderThElement columnId={thProps?.columnId || ''} {...thProps}>
				{children}
			</HeaderThElement>
		);
	}

	return (
		<HeaderThElement columnId={thProps?.columnId || ''} {...thProps}>
			<span className='k-cell-inner' onClick={onClick}>
				<CustomTooltip title={title ?? ""} >
					<span className="k-link !k-cursor-default">
						<span className="k-column-title">
							{title}
						</span>
					</span>
				</CustomTooltip>
				{
					filterable && isField && notAvoidedFields && !isNotFilterable &&
					<GridColumnMenuWrapper {...columnMenuWrapperProps} />
				}
			</span>
		</HeaderThElement>
	);
});

CustomHeader.displayName = "CustomHeader";
CustomHeader.propTypes = {
	columnMenuWrapperProps: PropTypes.any,
	filterable: PropTypes.bool,
	onClick: PropTypes.any,
	thProps: PropTypes.shape({
		columnId: PropTypes.string
	}),
	title: PropTypes.string
};

export default CustomHeader;
