import React from "react";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "@mui/material";
import { gridSkeletonProps } from "../../../navigation/components/userSection/ChangePassword/constants";

const textProps = {
    variant: "text",
    animation: "wave"
};

function LazySkeleton() {

    return (
        <Grid
            container
            spacing={4}
            direction="column"
            style={{ padding: "1.5rem" }}
        >
            <Grid size={12} style={{ display: "flex", justifyContent: "center", position: "absolute", top: "-4rem", left: 0, right: 0 }}>
                <Skeleton variant="circular" style={{ width: "7rem", height: "7rem" }} />
            </Grid>
            <Grid size={12} container style={{ display: "flex", justifyContent: "center", paddingTop: "3rem" }}>
                <Grid size={8}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
            </Grid>
            <Grid container size={12} spacing={1}>
                <Grid size={12}>
                    <Skeleton {...textProps} style={{ fontSize: "1rem" }} />
                </Grid>
                <Grid size={12}>
                    <Skeleton {...textProps} style={{ fontSize: "1rem" }} />
                </Grid>
                <Grid size={12}>
                    <Skeleton {...textProps} style={{ fontSize: "1rem" }} />
                </Grid>
                <Grid size={12}>
                    <Skeleton {...textProps} style={{ fontSize: "1rem" }} />
                </Grid>
            </Grid>
            <Grid size={12} container spacin={1} style={{ display: "flex", justifyContent: "center" }}>
                <Grid size={3}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
                <Grid size={3}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LazySkeleton;