
import React, {
	Suspense,
	useMemo,
} from 'react';
import useAbrhilWindow from '../../contextsAndControllers/useWindow';
import PageSkeleton from '../PageSkeleton';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import DynamicImport from './DynamicImport';

const modules = import.meta.glob("../../../../../modules/**/index.jsx");

const Window = ({ id }) => {
	const { open, isFocused } = useAbrhilWindow();
	const focusClass = isFocused ? "isFocused" : "";

	return (
		<>
			{open &&
				<div
					id={id}
					key={id}
					className={`module-window ${focusClass}`}
				>
					<div className='module-window-container'>
						<WindowContent />
					</div>
				</div>}
		</>
	);
};

export default Window;

Window.propTypes = {
	id: PropTypes.string,
};

export const WindowContent = () => {

	const {
		open,
		item: { element, path, extraProps },
	} = useAbrhilWindow();

	const shouldImport = open && isString(element);
	const completePath = `../../../../../${element}/index.jsx`;

	const WindowPage = useMemo(() =>
		shouldImport ?
			DynamicImport(modules, completePath)
			: element,
		[]);

	if (!open || !element || !WindowPage) {
		return null;
	}

	return (
		<Suspense fallback={<PageSkeleton />}>
			<WindowPage {...extraProps} path={path} inWindow />
		</Suspense>
	);
};