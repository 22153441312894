

const toolsBarIcons = {
	abrhil: {
		allowedTag: "Abrhil",
		Icon: "./Abrhil.svg",
	},
	search: {
		allowedTag: "Search",
		Icon: "./Buscar.svg",
	},
	helpcenter: {
		allowedTag: "Help-Center",
		Icon: "./HelpCenter.svg",
	},
	help: {
		allowedTag: "Help",
		Icon: "./HelpCenter.svg",
	},
	home: {
		allowedTag: "Home",
		Icon: "./Inicio.svg",
	},
	kpis: {
		allowedTag: "Kpis",
		Icon: "./Kpis.svg",
	},
	reports: {
		allowedTag: "Reports",
		Icon: "./Reportes.svg",
	},
	timbrasoft: {
		allowedTag: "Timbrasoft",
		Icon: "./Timbrasoft.svg",
	},
};

export default toolsBarIcons;