import PropTypes from "prop-types";
import React, { forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import Button from "../../styled/Button";
import IconUse from '../uiDesign/IconUse';
import { permsGate } from "../navigation/utilities";

/**
 *
 * @param {object} params
 * @param {string} params.label - Label de texto que sera mostrado dentro del botón
 * @param {FontAwesomeIcon|"string"} params.icon - Icono badado en componente react de fontawesome
 * @param {function} params.onClick - Función que ae realizara al momento de dar click em el botón
 * @param {?string} params.className - Una clase custom si es que la necesitara
 * @param {?"start"|"end"} params.iconPlacement - Indica si el icono se va a poner al principio o al final del botón
 * @param {?"contained"|"text"|"flat"|"create"|"outlined"} params.design - Indica el estilo del botón que se va a usar, por default outlined
 * @param {?"error"|"success"|"info"} params.color - Indica que color se va a usar para el botón
 * @returns {BaseButtonComponent}
 */
const BaseButton = forwardRef(function ButtonFunction({
	label,
	icon,
	onClick,
	className,
	perms,
	loading,
	iconPlacement = "start",
	children,
	...others
}, ref) {
	const { t } = useTranslation();

	if (perms && !permsGate(perms)) return null;

	return (
		<Button {...others} innerRef={ref} className={`${className ?? ""} systemButton`} onClick={onClick} data-cy={others["data-cy"] ?? others.id ?? label} loading={loading}>
			{icon && iconPlacement !== "end" ? <IconUse icon={icon} /> : null}
			{children}
			<span className={`buttonLabel ${icon ? "" : "no-icon"} ${iconPlacement === "end" ? "is-end-icon" : ""} ${iconPlacement === "center" ? "is-center-icon" : ""} `}>
				{t(label)}
			</span>
			{icon && iconPlacement === "end" ? <IconUse icon={icon} /> : null}
		</Button>
	);
});

BaseButton.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	loading: PropTypes.bool,
	icon: PropTypes.any,
	iconPlacement: PropTypes.oneOf(["start", "center", "end"]),
	label: PropTypes.string,
	onClick: PropTypes.func,
	perms: PropTypes.any
};

export default BaseButton;
