

const notificationIcons = {
	error: {
		allowedTag: "Error",
		Icon: "./error.svg",
    },
    indeterminate: {
		allowedTag: "Indeterminate",
		Icon: "./indeterminate.svg",
    },
    info: {
		allowedTag: "Info",
		Icon: "./info.svg",
    },
    check: {
		allowedTag: "Check",
		Icon: "./check.svg",
    },
    question_mark: {
		allowedTag: "QuestionMark",
		Icon: "./question_mark.svg",
    },
    warning: {
		allowedTag: "Warning",
		Icon: "./warning.svg",
    },
    slimclose: {
		allowedTag: "SlimClose",
		Icon: "./close.svg",
	},
	bell: {
		allowedTag: "Bell",
		Icon: "./bell.svg",
	},
	headphones: {
		allowedTag: "Headphones",
		Icon: "./headphones.svg",
	}
};

export default notificationIcons;