import React from 'react';
import { size } from 'lodash';
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import Button from '@mui/material/Button';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import useAbrhilSideMenu from '../../../contextsAndControllers/useSideMenu';
import { motion } from 'framer-motion';
import { opacityAnimation } from '../../../../form/Permission/Utils';

const MenuTabs = () => {
	const { t } = useTranslation();
	const {
		subMenu,
		isSubmenu,
		forceHide,
		tab,
		setTab,
		groupsAtFirstLevel,
		elements,
	} = useMegaMenu();

	const { openWindowItem } = useAbrhilSideMenu();

	if (!groupsAtFirstLevel && (!isSubmenu || !size(subMenu?.children))) return null;

	const items = groupsAtFirstLevel ? elements : subMenu?.children;

	const onClick = (item) => {
		if (item?.key?.includes('report')) {
			openWindowItem(item);
			forceHide();
			return;
		}
		setTab(item.name);
	}

	return (
		<Grid
			size={"auto"}
			key={`megamenu-header-group`}
			component={motion.div}
			{...opacityAnimation}
			transition={{ duration: 0.5 }}
		>
			<Grid
				container
				alignItems={"center"}
				id="custom-menu-tabs"
			>
				<div
					className='header-tab-container'
				>
					{items.map(item => (
						<Button
							key={item.name}
							onClick={() => onClick(item)}
							className={`header-tab`}
						>
							{tab === item.name &&
								<div
									className='header-tab-indicator'
								/>}
							<span className='header-tab-text'>{t(item.name)}</span>
						</Button>
					))}
				</div>
			</Grid>
		</Grid>
	);
};

export default MenuTabs;