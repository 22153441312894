import React from "react";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "@mui/material";
import { gridSkeletonProps } from "../ChangePassword/constants";

function ContentSkeleton() {

    return (
        <Grid
            container
            spacing={3}
            style={{
                width: "23rem",
                padding: "0.5rem 1rem",
                position: "relative",
            }}
        >
            <Grid
                size={12}
                container
                spacing={1}
            >
                <Grid>
                    <Skeleton
                        variant="circular"
                        {...gridSkeletonProps}
                        style={{
                            ...gridSkeletonProps.style,
                            width: "6rem",
                            height: "6rem",
                        }}
                    />
                </Grid>
                <Grid
                    size="grow"
                    container
                    spacing={0}
                    style={{
                        alignSelf: "flex-end",
                        paddingBottom: "0.8rem"
                    }}
                >
                    <Grid size={12}>
                        <Skeleton variant="text" />
                    </Grid>
                    <Grid size={12}>
                        <Skeleton variant="text" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container size={12}>
                <Grid size={6}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
                <Grid size={6}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
            </Grid>
            <Grid container size={12} spacing={1}>
                <Grid size={12}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
                <Grid size={12}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
                <Grid size={12}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
            </Grid>
            <Grid size={6} style={{ marginLeft: "auto" }}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
        </Grid>
    );
};

export default ContentSkeleton;