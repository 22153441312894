
const attendanceItems = [
	{
		id: "modules@staff-requisition",
		type: "item",
		package_permissions: ["ATTENDANCE_LIST", "ATTENDANCE_LIST.staff_requisitions"],
		componentElm: 'modules/attendance-mopers/requisition/PersonalRequisition',
	},
	{
		id: 'modules@reasons-for-hiring',
		type: 'item',
		package_permissions: ["ATTENDANCE_LIST", "ATTENDANCE_LIST.staff_requisitions"],
		componentElm: 'modules/attendance-mopers/requisition/HiringReasons',
	},
	{
		id: "modules@type-of-job-requisition",
		type: "item",
		package_permissions: ["ATTENDANCE_LIST", "ATTENDANCE_LIST.staff_requisitions"],
		componentElm: 'modules/attendance-mopers/requisition/TypeJobRequisition',
	},
	{
		id: 'modules@schedules',
		type: 'item',
		componentElm: 'modules/attendance-mopers/catalog/Schedules',
	},
	{
		id: 'modules@working-days',
		type: 'item',
		componentElm: 'modules/attendance-mopers/catalog/Journeys',
	},
	{
		id: 'modules@comment-predefined',
		type: 'item',
		componentElm: 'modules/attendance-mopers/catalog/PredefinedComments',
	},
	{
		id: 'modules@txt-util',
		type: 'item',
		componentElm: 'modules/attendance-mopers/tool/OvertimeUtilities',
	},
	//En construccion
	{
		id: 'modules@attendance-catalogs',
		type: 'item',
		inConstruction: true,
		componentElm: true,
	},
];

export default attendanceItems;