
import React from 'react';
import { useSelector } from 'react-redux';
import { AbrhilWindowProvider } from '../../contextsAndControllers/useWindow';
import Window from './Window';
import TabsContainer from './TabsContainer';
import Index from "../../../../../modules/system/principal/Welcome"
// import Index from "./FieldsTester"
import IconsSet from './IconsSet';
import Notifications from './Notifications/Dialogs';
import isDevMode from '../../../general/environment';

const WINDOWS = 6;

const Windows = () => {

	const { stamp } = useSelector(state => state.app);

	const classNameback = stamp ? 'stamp-background' : '';

	return (
		<div key='abrl-main-windows-container' className={`windows-container ${classNameback}`}>
			<TabsContainer />
			<div id="windows-container">
				<IconsSet />
				<Index />
				{isDevMode && <Notifications />}
				{Array.from({ length: WINDOWS }).map((_, i) =>
					<AbrhilWindowProvider key={`abrl_window_${i + 1}`} windowNumber={i + 1}>
						<Window id={`window_${i + 1}`} />
					</AbrhilWindowProvider>
				)}
			</div>
		</div>
	);
};

export default Windows;