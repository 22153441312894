import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from '@mui/material/Dialog';
import StatusSkeleton from "../../core/general/@components/modal/dialog/DialogSkeleton/StatusSkeleton";
import { Transition } from "../../core/general/@components/modal/dialog/DialogSkeleton/Transition";
import { logout } from "../../core/general/@components/general/auth";

function ModalQuestionLogout({ onConfirm }) {

    const [count, setCount] = useState(60);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevCount) => prevCount - 1);
        }, 1000);

        return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
    }, []);

    useEffect(() => {
        if (count != 0) return;
        logout();
    }, [count]);

    return (
        <Dialog
            open={true}
            slots={{ transition: Transition }}
            className={"myDialog warningDialog"}
            maxWidth={"lg"}
        >
            <StatusSkeleton
                lottieIcon={"warning-icon"}
                message={"Se detecto inactividad, ¿Desea continuar con su sesión abierta?"}
                close={logout}
                confirm={onConfirm}
                hasConfirm={true}
                confirmButtonText="Continuar"
                cancelButtonText={`Cerrar sesión en ${count}`}
                title={"Cierre de sesión por inactividad"}
            />
        </Dialog>
    );
};

export default ModalQuestionLogout;

ModalQuestionLogout.propTypes = {
    onConfirm: PropTypes.func,
};