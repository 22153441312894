
const Courses = {
	catalog: "./catalog.svg",
    schedule: "./schedule.svg",
    constance: "./constance.svg",
    excel: "./Excel.svg",
    dc3icon: "./Dc3_Icon.svg",
    star: "./star.svg",
    verification: "./verification.svg",
    attendanceparticipants: "./attendance-list.svg",
    clear: "./clear.svg",
    dc3text: "./dc3_text.svg",
    cirse: "./cirse_text.svg",
    checktraining: "./Check.svg",
    grades: "./Grades.svg"
};

export default Courses;