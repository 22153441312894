import React, { useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import LottieUse from '../../../uiDesign/LottieUse';
import IconUse from "../../../uiDesign/IconUse";
import { uid } from "uid";
import { debounce, isFunction } from "lodash";

import "../ChangeItem.scss";

let lastFocus = null;

function Skeleton({
    title,
    close,
    confirm,
    children,
    icon = null,
    lottieIcon = "error-icon",
    modern = false,
}) {
    const autoID = uid();
    const notifRef = useRef();
    const dialogID = `notification_${autoID}`;

    let classStyle = "custom-player-class";
    if (title?.includes("ERROR")) {
        classStyle = "custom-player-class-error";
    };

    function handleEnter(e) {
        const keyCode = e.keyCode;
        if (keyCode !== 13) return;
        if (isFunction(confirm)) {
            confirm();
            return;
        }
        close();
    }

    useEffect(() => {
        document.addEventListener('keypress', handleEnter);
        return () => {
            document.removeEventListener('keypress', handleEnter);
        };
    }, []);

    useLayoutEffect(() => {
        saveFocus();
        return () => {
            restoreFocus();
        };
    }, []);

    const restoreFocus = useRef(debounce(() => {
        if (!lastFocus?.focus) return;
        lastFocus.focus();
    }, 200)).current;

    const saveFocus = () => {
        if (document.activeElement?.attributes?.tabindex?.value === "-1" || notifRef.current?.contains(document?.activeElement) || document?.activeElement instanceof HTMLBodyElement) return;
        lastFocus = document.activeElement;
    };

    return (
        <div ref={notifRef} id={dialogID}>
            {modern ?
                <div className="modern-icon-wrap">
                    <div className="modern-icon">
                        <IconUse icon={icon} />
                    </div>
                </div>
                :
                <div className="iconContainer" >
                    <LottieUse
                        className={classStyle}
                        icon={lottieIcon}
                    />
                </div>
            }
            <div className="dialogTitle" style={modern ? { paddingTop: "10px", paddingInline: "25px" } : {}}>{title}</div>
            {!modern && <Divider className={"dividerStyle"} />}
            {children}
        </div>
    );
};

export default Skeleton;

Skeleton.propTypes = {
    children: PropTypes.any,
    close: PropTypes.func,
    confirm: PropTypes.func,
    icon: PropTypes.any,
    lottieIcon: PropTypes.string,
    modern: PropTypes.bool,
    title: PropTypes.any
};