import React from "react";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "@mui/material";
import { gridSkeletonProps } from "../../../userSection/ChangePassword/constants";

function ContentSkeleton() {

    return (
        <Grid container spacing={2} direction="column" style={{ padding: "2rem" }}>
            <Grid container size={12} direction="row">
                <Grid>
                    <Skeleton
                        variant="circular"
                        {...gridSkeletonProps}
                        style={{
                            ...gridSkeletonProps.style,
                            width: "8rem",
                            height: "8rem"
                        }}
                    />
                </Grid>
                <Grid container size="grow" spacing={4} style={{ alignSelf: "center" }}>
                    <Grid size={6}>
                        <Skeleton {...gridSkeletonProps} />
                    </Grid>
                    <Grid container size={12}>
                        <Grid size={6}>
                            <Skeleton {...gridSkeletonProps} />
                        </Grid>
                        <Grid size={6}>
                            <Skeleton {...gridSkeletonProps} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid size={2} style={{ marginInline: "auto" }}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
        </Grid>
    );
};

export default ContentSkeleton;