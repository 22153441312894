import { store } from '../../../../store';
import { isAbrhil } from '../general/auth';

/**
 *
 * @returns {number | null}
 */
export function currentTenant() {
  return store.getState().tenant.current?.id;
}

export function currentCorporate() {
  return store.getState().corporate.current?.id;
}

export function hasErrorTetant() {
  const tenant = store.getState().tenant;
  return isAbrhil() && !tenant.current?.id;
}

export function tenantCountry() {
  return store.getState().country?.country ?? "MX";
}

export function dashboardPath() {
  return store.getState().userDashboard?.path;
}