import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/pro-light-svg-icons";
import Skeleton from "./Skeleton";
import MsgBody from "./MsgBody";
import Buttons from "./Buttons";
import ChangeValue from "./ChangeValue";

function ChangesSkeleton({
	changes,
	confirm,
	close,
	confirmChanges,
	title,
	message,
	...others
}) {

	const { t } = useTranslation();

	return (
		<Skeleton lottieIcon={"info-icon"} title={title || t("changes-general-title")} close={close}>
			{!confirmChanges && <MsgBody {...others} message={message} />}

			{confirmChanges && (
				<span className={`dialogMessage`}>
					{message || t("exists-changes-in-row")}
				</span>
			)}

			{confirmChanges && (
				<div className="ChangeItem controlledContainer">
					{changes.map((item, index) => (
						<Fragment key={index + 1}>
							<div className="row-change">
								<div className="text-desc">
									{t("change-on.update")} :{" "}
									<span
										className={"k-text-inverse k-font-weight-bold blueData"}
									>
										{t(item.field)}
									</span>
								</div>
								<div className="changes">
									<div className="change-text">{t("change-on.before")} :</div>
									<ChangeValue value={item.old} className={"before"} />
									<div className="change-icon">
										<FontAwesomeIcon icon={faRightLong} />
									</div>
									<div className="change-text">{t("change-on.after")} :</div>
									<ChangeValue value={item.new} className={"after"} />
								</div>
							</div>
						</Fragment>
					))}
				</div>
			)}

			<Buttons confirm={confirm} close={close} hasConfirm={true} />
		</Skeleton>
	);
};

export default ChangesSkeleton;

ChangesSkeleton.propTypes = {
	changes: PropTypes.any,
	close: PropTypes.func,
	confirm: PropTypes.func,
	confirmChanges: PropTypes.any,
	message: PropTypes.func,
	title: PropTypes.func
};