
import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types"
import ContentSkeleton from "./Skeleton";

const Content = lazy(() => import("./Content"));

const MenuOptions = ({
    email,
    user,
    photo,
    onClose,
    handleLogOut,
}) => {

    return (
        <Suspense fallback={<ContentSkeleton />}>
            <Content
                email={email}
                user={user}
                photo={photo}
                onClose={onClose}
                handleLogOut={handleLogOut}
            />
        </Suspense>
    );
};

MenuOptions.propTypes = {
    email: PropTypes.string,
    user: PropTypes.string,
    photo: PropTypes.string,
    onClose: PropTypes.func,
    handleLogOut: PropTypes.func,
}

export default MenuOptions;