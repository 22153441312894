import React from 'react';
import { isArray, omit, size } from 'lodash';
import MegaMenu from './MegaMenu';
import { ItemIcon } from './Search/NavItem';
import CustomTooltip from '../../../Templates/CustomTooltip';
import useAbrhilNavigation from '../../contextsAndControllers/useAbrhilNavigation';
import { MegaMenuProvider } from '../../contextsAndControllers/useMegaMenu';
import useAbrhilSideMenu from '../../contextsAndControllers/useSideMenu';
import { isSelected } from '../../utilities';

const validateChildrens = (childs) => {
	if (!isArray(childs)) {
		return null;
	}

	return childs.map(el => {
		const child = { ...el };
		if (size(child.children) > 0) {
			child.children = validateChildrens(child.children);
		}

		if (
			(child.type === "item" && child.showInMenu) ||
			size(child.children) > 0
		) {
			return child;
		}
		return null;
	}).filter(item => item !== null);
};

const invalidItem = ({ children, showInMenu, type, key, }) => {
	const finalChilds = validateChildrens(children);

	const isValid = (
		!showInMenu ||
		!["item", "collapse"].includes(type) ||
		(size(finalChilds) <= 0 && type === "collapse") ||
		['abrhilhelpdesk', 'welcome', 'help', 'timbrasoft'].includes(key)
	);

	return { isValid, children: finalChilds };
};

const Menu = ({ items = [] }) => (
	items.map((item, index) => {
		const { isValid, children } = invalidItem(item);

		if (isValid) {
			return null;
		}

		const Item = item?.type === "item" ? SimpleItem : MegaMenuItem;

		return <Item key={index} item={item} childItems={children} />;
	})
);

export default Menu;

const SimpleItem = ({ item }) => {

	const { title, bread, icon, } = item;
	const { breadcrumbs, setShowed } = useAbrhilNavigation();
	const { openWindowItem } = useAbrhilSideMenu();

	const openWindow = () => {
		setShowed(null);
		openWindowItem(item);
	}

	return (
		<CustomTooltip title={title} className="menu-item-tooltip" placement="left">
			<span className={`sidebar-item ${isSelected(bread, breadcrumbs)}`} >
				<span className='side-menu-item-icon' onClick={openWindow}>
					<ItemIcon icon={icon} />
				</span>
			</span>
		</CustomTooltip>
	);
};

const MegaMenuItem = ({ item, childItems }) => {
	const final = { ...item, children: childItems };
	return (
		<MegaMenuProvider
			menuKey={final.key}
			elements={childItems}
			groupsAtFirstLevel={["abrhil"].includes(final.key)}
			{...omit(final, ["key", "children"])}
		>
			<MegaMenu item={final} />
		</MegaMenuProvider>
	);
};