

const humanResourcesIcons = {
	'sickleaves': {
		allowedTag: "Sick-Leaves",
		Icon: "./Incapacidades.svg",
	},
	'organizationchart': {
		allowedTag: "Organization-Chart",
		Icon: "./Organigrama.svg",
	},
	'vigencycontrol': {
		allowedTag: "Vigency-Control",
		Icon: "./VigencyControl.svg",
	},
	'workeradd': {
		allowedTag: "Worker-Add",
		Icon: "./WorkerAdd.svg",
	},
	'workerleave': {
		allowedTag: "Worker-Leave",
		Icon: "./WorkerLeave.svg",
	},
	'workerkardex': {
		allowedTag: "Worker-Kardex",
		Icon: "./WorkerKardex.svg",
	},
	'workers': {
		allowedTag: "Workers",
		Icon: "./Workers.svg",
	},
	'workerreentry': {
		allowedTag: "Worker-Reentry",
		Icon: "./WorkerReentry.svg",
	},
	'salarychange': {
		allowedTag: "Salary-Change",
		Icon: "./SalaryChange.svg",
	},
	'structurechange': {
		allowedTag: "Structure-Change",
		Icon: "./StructureChange.svg",
	},
	'workgroup': {
		allowedTag: "Workgroup",
		Icon: "./Workgroup.svg",
	},
	'infonavit': {
		allowedTag: "Infonavit",
		Icon: "./Infonavit.svg",
	},
	'chip': {
		allowedTag: "chip",
		Icon: "./Chip.svg",
	},
	'contractplant': {
		allowedTag: "Contract-Plant",
		Icon: "./ContractPlant.svg",
	},
	'renovatecontract': {
		allowedTag: "Renovate-Contract",
		Icon: "./RenovateContract.svg",
	},
	'massivesalary': {
		allowedTag: "Massive-Salary",
		Icon: "./MassiveSalary.svg",
	},
};

export default humanResourcesIcons;