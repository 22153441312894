import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import { gridSkeletonProps } from "../../../../userSection/ChangePassword/constants";

const PARENTS = 2;
const CHILDREN = 4;

function ContentSkeleton({ style }) {


    return (
        <Grid
            container
            spacing={6}
            className="noti-content-skeleton"
            style={style}
        >
            {Array.from({ length: PARENTS }).map((_, i) =>
                <Grid
                    key={`ahd-content-skeleton-item-${i + 1}`}
                    container
                    spacing={2}
                    size={12}
                >
                    {Array.from({ length: CHILDREN }).map((_, j) =>
                        <Grid key={`ahd-content-skeleton-subitem-${j + 1}`} size={12}>
                            <Skeleton {...gridSkeletonProps} />
                        </Grid>)}
                </Grid>
            )}
        </Grid>
    );
};

export default ContentSkeleton;

ContentSkeleton.propTypes = {
    style: PropTypes.object,
};