import React, { lazy, Suspense } from "react";
import DialogComponent from "./components/Dialog";
import { NotificationsContext } from "./contexts";
import useNotificationDialogs from "./hooks/useNotificationDialogs";
import IconUse from "../../../../uiDesign/IconUse";
import ContentSkeleton from "./components/Contents/Skeleton";
import "./styles/_styles.scss";

const Helpdesk = lazy(() => import("./components/Contents/Helpdesk"));
const HelpdeskSub = lazy(() => import("./components/Contents/Helpdesk/components/Details"));
const Notifications = lazy(() => import("./components/Contents/Notifications"));

function NotificationDialogs() {

    const values = useNotificationDialogs();

    const {
        ticketId,
        openNoti,
        openHD,
        openSubHD,
        onCloseHD,
        onCloseNoti,
        onCloseSubHD,
    } = values;

    return (
        <NotificationsContext.Provider value={values}>
            <DialogComponent
                open={openHD}
                onClose={onCloseHD}
                openSub={openSubHD}
                onCloseSub={onCloseSubHD}
                subContentKey={ticketId}
                title={<IconUse icon='Helpdesk' className='helpdesk-icon' />}
                subContent={
                    <Suspense fallback={<ContentSkeleton style={{ padding: "15px" }} />}>
                        <HelpdeskSub />
                    </Suspense>
                }
            >
                <Suspense fallback={<ContentSkeleton />}>
                    <Helpdesk />
                </Suspense>
            </DialogComponent>
            <DialogComponent
                open={openNoti}
                onClose={onCloseNoti}
                title={'Centro de notificaciones'}
            >
                <Suspense fallback={<ContentSkeleton />}>
                    <Notifications />
                </Suspense>
            </DialogComponent>
        </NotificationsContext.Provider>
    );
};

export default NotificationDialogs;