import React, { lazy, Suspense } from 'react';
import { useTranslation } from "react-i18next";
import Dialog from '../../../../../../styled/Dialog';
import ContentSkeleton from './ContentSkeleton';

const ModalContent = lazy(() => import("./ModalContent"));

const Modal = ({
	open,
	setOpen
}) => {

	const { t } = useTranslation();
	const onClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={"xl"}
			title={t("enterprise-and-role")}
			design="modern"
			className={"company-and-role-modal"}
		>
			<Suspense fallback={<ContentSkeleton />}>
				<ModalContent onClose={onClose} />
			</Suspense>
		</Dialog>
	);
};
export default Modal;