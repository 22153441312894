import { subMonths } from 'date-fns';
import { months } from './constants';

export const previousDate = (type, custom) => {
    let today = new Date();
    let dayMilliseconds = 24 * 60 * 60 * 1000;
    let yesterday = new Date(today.getTime() - dayMilliseconds);
    if (custom) {
        const monthBefore = subMonths(new Date(), 1);
        return { year: monthBefore.getFullYear().toString(), month: (monthBefore.getMonth() + 1).toString() };
    }

    if (type === "year") {
        return yesterday.getFullYear();
    } else {
        return yesterday.getMonth() + 1;
    }
};

export function getMonthsBimonthly(month) {
    if (month % 2 === 0) {
        const value = months.find((f) =>
            f.value
                .split(',')
                .map((m) => parseInt(m))
                .find((f) => f === parseInt(month))
        )
        return value
    } else {
        return null
    }
}
