import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import { each, isFunction } from 'lodash';
import { useWatch } from "react-hook-form";
import { getStructureApi } from '../../StructureEnterprise/Utils/StructureUtilities';
import FormInput from '../Field';
import { hasValue, valueOrOption } from '../../general/GeneralUtilities';

export function DropdownInput({
	field,
	control,
	setValue,
	extraFilters = {},
	sequencialItems = ["none"],
	onChange,
	customChange,
	...others
}) {

	const prevFieldsValues = useWatch({ control, name: sequencialItems });
	const changed = prevFieldsValues.join("-");

	useEffect(() => {
		setValue(field, null);
		//eslint-disable-next-line
	}, [changed]);

	const getExtras = () => {
		let extras = valueOrOption(extraFilters, {});
		each(prevFieldsValues, (fieldValue, index) => {
			if (hasValue(fieldValue)) {
				extras[sequencialItems[index]] = fieldValue;
			}
		});
		extras["select"] = true;
		return extras;
	};

	const handleChange = (e) => {
		if (isFunction(onChange)) {
			onChange(e);
		} else if (isFunction(customChange)) {
			customChange(e, field);
		}
		return e;
	}

	return (
		<FormInput
			{...others}
			onChange={handleChange}
			control={control}
			fieldInput={"ServerDropdown"}
			serverApi={getStructureApi(field)}
			valueField={"id"}
			textField={"name"}
			searchBy={"search"}
			filterEfields={"key|name"}
			filterSplit={"-"}
			format={"key|rfc|name"}
			fields={"id,key,name,rfc"}
			extraFilters={getExtras()} />
	);
}

DropdownInput.propTypes = {
	control: PropTypes.any,
	customChange: PropTypes.func,
	extraFilters: PropTypes.object,
	field: PropTypes.string,
	onChange: PropTypes.func,
	sequencialItems: PropTypes.array,
	setValue: PropTypes.func
}
