import React, { Fragment } from "react";
import PropTypes from "prop-types";

function MsgBody({
    message,
    description,
    isCustomChild,
    customChildren,
}) {
    if (isCustomChild) {
        return <Fragment>{customChildren}</Fragment>;
    }

    return (
        <div className="textContainer">
            <div className={`dialogDescription`}>
                <span className={`dialogMessage`}>{message}</span>
                {description}
            </div>
        </div>
    );
};

export default MsgBody;

MsgBody.propTypes = {
    customChildren: PropTypes.any,
    description: PropTypes.any,
    isCustomChild: PropTypes.any,
    message: PropTypes.any
};