import React from "react";
import PropTypes from "prop-types";
import { AvatarAndTooltip } from "../../Inputs/photoUpload/photo/CustomAvatar";
import { workerFullName } from "../../employees/constants";
import { size } from "lodash";
import "./_styles.scss";

function Avatar({
    photo,
    worker,
    width,
    fontSize,
    border = true,
    validateStatus = true,
    withTooltip = true,
    className = "",
}) {

    const fullName = size(worker) ? workerFullName(worker) : '';
    const cPhoto = photo ?? worker?.photo?.medium_square_crop ?? worker?.photo?.full_size ?? worker?.photo;
    const status = worker?.current_status?.active;
    const isActive = (status || !validateStatus) ? 'active' : 'inactive';

    return (
        <div
            className={`sbw-custom-avatar ${size(worker) ? isActive : ''} ${border ? 'with-border' : ''} ${className}`}
        >
            <AvatarAndTooltip
                photo={cPhoto}
                string={fullName.trim()}
                fontSize={fontSize ?? '40px'}
                withTooltip={withTooltip}
                sx={{
                    width: width ?? '65px',
                    height: width ?? '65px'
                }}
            />
        </div>
    );
};

export default Avatar;

Avatar.propTypes = {
    photo: PropTypes.any,
    worker: PropTypes.object,
    width: PropTypes.string,
    border: PropTypes.bool,
    validateStatus: PropTypes.bool,
    fontSize: PropTypes.string,
    className: PropTypes.string,
    withTooltip: PropTypes.bool,
};