import React, { useEffect, useRef } from 'react';
import {
	isArray,
	isObject,
	includes,
	size,
	dropRight
} from 'lodash';
import i18n from "i18next";
import {
	DateRangeFilterMenu,
	KeyNameFilterMenu,
	SimpleFilterMenu
} from '../../filter/FilterMenu';
import BaseCell from '../CustomCell/BaseCell';
import { getFilterName, isDropdown } from '../../filter/utilities';
import MultiCheckMenu from '../../filter/MultiCheckMenu';
import { conditional, hasValue, valueOrOption } from '../../general/GeneralUtilities';
import { isEnabledElement } from '../../../permissions/PermissionsGate';
import CustomHeader from '../CustomCell/CustomHeader';

export const initialPage = { skip: 0, take: 15 };
export const paginationInitials = (pageSizes) => ({ skip: 0, take: valueOrOption(pageSizes?.[0], 15) });
export const initialFilter = (definedFilters = {}) => { return { filters: { ...definedFilters } }; };

const inUse = (filters, field, prefix) => {
	const filterName = getFilterName(field, prefix);
	return hasValue(filters?.[filterName]);
};

export const isColumnActive = (field, filter, filterType, fieldPrefix) => {
	const used = filter?.filters ?? filter;
	const type = valueOrOption(filterType, "").toLowerCase();
	const colAux = valueOrOption(fieldPrefix, field);
	const filter_func = {
		"checkbox": () => (size(used?.[getFilterName(field, fieldPrefix)]) > 0),
		"keyname": () => (inUse(used, "key", fieldPrefix) || inUse(used, "name", fieldPrefix)),
		"keynamerfc": () => (inUse(used, "_key", colAux) || inUse(used, "_name", colAux) || inUse(used, "_rfc", colAux)),
		"daterange": () => (inUse(used, "_start", field) || inUse(used, "_end", field) || inUse(used, "_switch", field)),
	}[type] ?? (() => inUse(used, field, fieldPrefix));

	return filter_func();
};

/**
 * Este hook nos sirve para generar y manejar las propiedades de las columnas de las tablas del sistema con filtros custumizados
 *
 * @param {string} field - La columna a la que hace referencia el filtro
 * @param {object} filter - La propiedad donde se almacenan los filtros en el módulo
 * @param {string || array} filterType - El tipo de filtro que se quiere usar
 * @param {[lista,valor,label]?} dataProps - Si el filtro es un dorpdown se le deben pasar datos extras
 * * Importante se debe pasar en el formato [lista,valor,label]
 * * * Lista -> Es el conjunto de datos de que apareceran en el dropdown o los checks
 * * * valor -> Indica cual de los valores del objeto de la lista sera el valor de la etiqueta que se maneje en el filtro
 * * * label -> indica cual de los valores del objeto de la lista sera la que se vea en el elemento
 * @return {ColumnProps}
*/

export const columnProps = (field, filter, filterType, dataProps) => {

	if (filter === "none") {
		return {
			field: field,
			cells: baseCells(false),
			headerClassName: "noFilterable"
		};
	}

	let fieldPrefix = "";

	if (isArray(filterType)) {
		fieldPrefix = `${filterType[1]}|`;
		filterType = filterType[0];
	}
	const isArFilter = isDropdown(filterType) || filterType === "checkbox";
	const validity = dataProps ? dataProps[3] : {};

	const extraProps = {
		filterType: filterType,
		dataProps: isArFilter ? dataProps : [],
		inputProps: isArFilter ? validity : dataProps,
		fieldPrefix: fieldPrefix
	};

	return {
		field: field,
		cells: baseCells(),
		columnMenu: props => {
			const fType = valueOrOption(filterType, "").toLowerCase();
			switch (fType) {
				case "keyname":
				case "keynamerfc":
					return <KeyNameFilterMenu {...props} {...extraProps} rfcField={fType === "keynamerfc"} />;
				case "daterange":
					return <DateRangeFilterMenu {...props} {...extraProps} />;
				case "checkbox":
					return <MultiCheckMenu {...props} {...extraProps} />;
				default:
					return <SimpleFilterMenu {...props} {...extraProps} />;
			}
		},
		headerClassName: isColumnActive(field, filter, filterType, fieldPrefix) ? "activeFilter" : "customFilter",
	};
};

export const baseCells = (filterable = true, dataExtas = {}) => ({
	data: props => <BaseCell {...props} {...dataExtas} />,
	headerCell: props => <CustomHeader {...props} filterable={filterable} />,
});

/**
 *
 * @param {number} total - Es el total de registros de la tabla
 * @returns {pageableProps}
 */
export const pageable = (total, buttonCount, customFirstCount, customPageSizes, allPageSize = false) => {
	const firstCount = customFirstCount ?? 15;

	if (total <= 0) {
		return undefined;
	}

	if (total <= firstCount) {
		return { pageSizes: undefined };
	}

	let pagesList = valueOrOption(customPageSizes, [firstCount, 20, 25, 50]);

	if (total > 100 && !hasValue(customPageSizes)) {
		pagesList.push(100);
	}

	if (allPageSize && !hasValue(customPageSizes)) {
			pagesList.push(i18n.t("_all"));
	}

	return {
		buttonCount,
		pageSizes: pagesList
	};
};

export const selectedItem = (data, selected, matchBy) => {
	return (data ?? []).map((item, index) => ({
		...item,
		rowIndex: index,
		selected: isMatch(item, selected, valueOrOption(matchBy, "id"))
	}));
};

export const isMatch = (item, selected, field) => {
	const value = item?.[field];

	if (isArray(selected)) {
		return includes(selected, value) || includes(selected, value.toString());
	}

	if (isObject(selected)) {
		return selected[field] === item[field];
	}

	return getValue(value) === getValue(selected);
};

const getValue = (value) => {
	if (hasValue(value)) {
		return value.toString();
	}

	return "";
};

export const doubleClickForm = (dataItem, handleEdit, hasPerm = true) => {

	if (!isEnabledElement(['write']) || !hasPerm) {
		return;
	}
	handleEdit(dataItem);
};


export const useCellScroll = (data) => {

	const cellRef = useRef();

	useEffect(() => {
		if (!cellRef.current || data?.length < 2) return;
		scrollController(cellRef.current);
	}, [data]);

	function scrollController(element) {
		let pos = { top: 0, left: 0, x: 0, y: 0 };

		const mouseDownHandler = function (e) {
			element.style.cursor = 'grabbing';
			element.style.userSelect = 'none';

			pos = {
				left: element.scrollLeft,
				top: element.scrollTop,
				x: e.clientX,
				y: e.clientY,
			};

			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = (e) => {
			const dx = e.clientX - pos.x;
			const dy = e.clientY - pos.y;

			element.scrollTop = pos.top - dy;
			element.scrollLeft = pos.left - dx;
		};

		const mouseUpHandler = () => {
			element.style.cursor = 'grab';
			element.style.removeProperty('user-select');

			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};

		element.addEventListener('mousedown', mouseDownHandler);
	}

	const tooltipMessage = <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
		{data?.map(el => <span key={el}>{el}</span>)}
	</div>;

	return {
		cellRef,
		tooltipMessage,
	};
};