import React from "react";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "@mui/material";
import { gridSkeletonProps } from "../../../navigation/components/userSection/ChangePassword/constants";

function InputsRenderSkeleton() {

    return (
        <Grid container spacing={8}>
            <Section />
            <Section />
            <Section />
        </Grid>
    );
};

export default InputsRenderSkeleton;

const Section = () => {

    return (
        <Grid container spacing={2} size={12}>
            <Grid container size={12}>
                <Grid size={6}>
                    <Skeleton {...gridSkeletonProps} />
                </Grid>
            </Grid>
            <Grid size={6}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid size={6}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid size={6}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid size={6}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid size={6}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
            <Grid size={6}>
                <Skeleton {...gridSkeletonProps} />
            </Grid>
        </Grid>
    );
};